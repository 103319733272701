import { ReactNode, useCallback, useState } from 'react';
import { ModalCore } from './ModalCore';

export const useModalProps = (): ModalProps => {
  const [opened, setOpened] = useState(false);

  const open = useCallback(() => setOpened(true), []);

  const close = useCallback(() => setOpened(false), []);

  return { opened, open, close };
};

export type ModalContentProps = {
  onClose: () => void;
};

export type ModalContent = (props: ModalContentProps) => ReactNode;

export type ModalTriggerProps = {
  onClick: () => void;
};

export type ModalTrigger = (props: ModalTriggerProps) => ReactNode;

export type ModalProps = {
  opened: boolean;
  open: () => void;
  close: () => void;
};

type Props = {
  content: ModalContent;
  trigger: ModalTrigger;
  className?: string;
  onExiting?: () => void;
};

export const Modal = ({ content, trigger, className, onExiting }: Props) => {
  const { opened, open, close } = useModalProps();

  return (
    <>
      <ModalCore className={className} opened={opened} onClose={close} onExiting={onExiting}>
        {content({ onClose: close })}
      </ModalCore>
      {trigger({ onClick: open })}
    </>
  );
};
