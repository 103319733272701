import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { axios } from '~/root';
import { FeedbackType, nullableFeedbackSchema } from '../domain';
import { ordersQueryKey } from './useOrder';

export const useFeedback = ({ enabled = false }) => {
  const id = useIdParam();

  return useQuery({
    queryKey: [ordersQueryKey, id, 'feedback'],
    queryFn: async ({ signal }) => {
      return axios
        .get(`/v1/orders/${id}/feedback`, { signal })
        .then((res) => nullableFeedbackSchema.parse(res.data.feedback));
    },
    enabled,
  });
};

export const usePostFeedback = () => {
  const id = useIdParam();
  const client = useQueryClient();

  return useMutation({
    mutationFn: (feedback: Partial<Omit<FeedbackType, 'timestamp'>>) => {
      return axios.post(`/v1/orders/${id}/feedback`, feedback).then((res) => {
        const response = nullableFeedbackSchema.parse(res.data.feedback);
        client.setQueryData<FeedbackType | null>([ordersQueryKey, id, 'feedback'], response);
        return response;
      });
    },
  });
};
