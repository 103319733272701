import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { Toast } from '~/common/components';
import { decodeOrNull, FlashMessage } from '~/common/utils';
import { axios, qk } from '~/root';
import { echo } from '~/root/services';
import { CustomerUser, LoginResponse, OauthData, StaffUser, userSchema } from '../domain';
import { useSetUser } from './init';

const oauthWindow = {
  width: 450,
  height: 600,
};

type OauthLoginProvider = 'microsoft' | 'google';

export const oauthLoginProviderSchema = z.object({
  channel: z.string(),
  redirectUrl: z.string(),
});

const openOauthWindow = (url: string) => {
  if (!window.top) {
    throw new Error('No window.top');
  }

  const posX = window.top.outerWidth / 2 + window.top.screenX - oauthWindow.width / 2;
  const posY = window.top.outerHeight / 2 + window.top.screenY - oauthWindow.height / 2;

  return window.open(
    url,
    'provider',
    `width=${oauthWindow.width}, height=${oauthWindow.height}, left=${posX}, top=${posY}`,
  );
};

type OauthResponse = LoginResponse | (LoginResponse & { user: CustomerUser | StaffUser });

export const oauthLogin = ({
  provider,
  oauthData,
}: {
  provider: OauthLoginProvider;
  oauthData?: OauthData | null;
}) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<OauthResponse>(async (resolve, reject) => {
    const response: OauthData =
      oauthData ??
      (await axios
        .get(`/v1/oauth/login/${provider}`)
        .then((res) => oauthLoginProviderSchema.parse(res.data)));

    const stopListening = () => {
      echo.leaveChannel(response.channel);
    };

    echo.channel(response.channel).listen('.authenticated', (data: unknown) => {
      stopListening();
      const loginResponse = LoginResponse.safeParse(data);
      const user = z
        .object({ user: userSchema })
        .transform(({ user }) => user)
        .safeParse(data);
      if (!user.success) {
        captureException(user.error);
      }
      if (loginResponse.success && user.success) {
        resolve({ ...loginResponse.data, user: user.data });
      }
      if (loginResponse.success) {
        resolve(loginResponse.data);
      }
    });

    echo.channel(response.channel).listen('.notification', (data: unknown) => {
      stopListening();
      const flash = decodeOrNull(data, FlashMessage);
      if (flash) {
        Toast[flash.type]({ title: flash.message });
      }
      reject(data);
    });

    const oauthWindow = openOauthWindow(response.redirectUrl);

    const check = setInterval(() => {
      if (oauthWindow?.closed) {
        clearInterval(check);
        // it's possible that auth ws message could arrive after oauth window
        // was closed, so let's wait for that case
        setTimeout(() => {
          stopListening();
          reject(new Error('oauth window was closed before auth attempt'));
        }, 5_000);
      }
    }, 500);
  });
};

export const useOauthLogin = () => {
  const client = useQueryClient();
  const setUser = useSetUser();

  return useMutation({
    mutationFn: ({
      provider,
      oauthData,
    }: {
      provider: OauthLoginProvider;
      oauthData?: OauthData | null;
    }) => {
      return oauthLogin({ provider, oauthData })
        .then((response) => {
          if (new URL(response.redirectUrl).origin !== window.location.origin) {
            window.location.href = response.redirectUrl;
            return response;
          }

          if ('user' in response && !response.user.isStaff) {
            setUser(response.user);
          } else {
            client.invalidateQueries(qk.init);
          }

          return response;
        })
        .catch((error) => {
          console.error(error);
          captureException(error);
          return null;
        });
    },
  });
};
