import { useMutation } from '@tanstack/react-query';
import { Email } from '~/common/utils';
import { axios, redirectUserOnAxiosResponse } from '~/root';

export const useRequestResetLink = () => {
  return useMutation({
    mutationFn: (email: Email) => {
      return axios.get(`/v1/users/reset-password/${email}`).then((response) => {
        if (response?.data.redirectUrl) {
          redirectUserOnAxiosResponse(response.data.redirectUrl);
        }

        return response;
      });
    },
  });
};
