/* eslint-disable no-console */
import { ENV } from '~/env';

export const facebook = {
  ready: ENV.PRODUCTION && window.fbq,

  track(...props) {
    if (this.ready) {
      window.fbq('track', ...props);
    }

    if (ENV.DEVELOPMENT) {
      console.log('track', ...props);
    }
  },

  trackCustom(...props) {
    if (this.ready) {
      window.fbq('trackCustom', ...props);
    }

    if (ENV.DEVELOPMENT) {
      console.log('trackCustom', ...props);
    }
  },
};
