import { useMutation } from '@tanstack/react-query';
import { axios } from '~/root';
import { CustomerSettings, customerSettingsSchema, useSetUser } from '~/root/Auth';

export const useUpdateSettings = () => {
  const setUser = useSetUser();

  return useMutation({
    mutationFn: (data: CustomerSettings) =>
      axios.put('/v1/customers/settings', data).then(({ data }) => {
        setUser(customerSettingsSchema.parse(data));
      }),
  });
};
