import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FieldProps } from 'formoid';
import { ComponentProps } from 'react';
import { formatDayFullMonthYear, Overwrite } from '~/common/utils';
import { IconBox } from '../IconContainers';
import { Errors, Input } from '../Input';
import { Popover } from '../Popover';
import { Datepicker } from './Datepicker';

type DatepickerFieldProps = Overwrite<
  ComponentProps<typeof Input> & ComponentProps<typeof Datepicker>,
  Overwrite<
    FieldProps<Date | null>,
    {
      touched?: boolean;
      disabled?: boolean;
      errors?: null;
      onBlur?: () => void;
    }
  > & {
    value: Date | null;
    nullable?: boolean;
    wrapperClassName?: string;
    maxDateBlockReason?: string;
    matchTriggerWidth?: boolean;
  }
>;

export const DatepickerField = ({
  errors,
  value,
  placeholder = 'Choose a date',
  // so you can set it as undefined above atleast :shrug:
  minDate = new Date(),
  maxDate,
  onBlur,
  onChange,
  nullable,
  wrapperClassName,
  blockedDays,
  maxDateBlockReason,
  matchTriggerWidth,
  ...inputProps
}: DatepickerFieldProps) => {
  return (
    <Popover
      className="!h-[340px]"
      onClose={onBlur}
      matchTriggerWidth={matchTriggerWidth}
      trigger={(props) => (
        <div className={wrapperClassName}>
          <Input
            {...props}
            {...inputProps}
            placeholder={placeholder}
            icon={faCalendarAlt}
            value={value ? formatDayFullMonthYear(value) : ''}
            readOnly
            error={!!errors}
          >
            {nullable && value !== null && (
              <IconBox
                className="cursor-pointer"
                size="s"
                icon={faTimes}
                onClick={(e) => {
                  e.preventDefault();
                  onChange(null);
                  onBlur?.();
                }}
              />
            )}
          </Input>
          {errors && <Errors errors={errors} />}
        </div>
      )}
      content={(props) => (
        <Datepicker
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={(date) => {
            onChange(date);
            props.onClose();
          }}
          blockedDays={blockedDays}
          maxDateBlockReason={maxDateBlockReason}
        />
      )}
    />
  );
};
