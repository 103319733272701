import { ReactNode } from 'react';
import { Menu, useModalContext } from '~/common/components';
import { cx } from '~/common/utils';
import { InvoicePaymentMethod as InvoicePaymentMethodType } from '~/root/domain';
import { BillingInformationInfo } from '../BillingInformation/BillingInformationView';
import { Company } from '../domain';
import { useBillingData, useSetDefaultPaymentMethodSubmit } from '../hooks';
import { DeletePaymentMethodModal } from './DeletePaymentMethodModal';
import { InvoicePaymentMethodModal } from './InvoicePaymentMethodModal';

type InvoicePaymentMethodProps = InvoicePaymentMethodType &
  Company & {
    className?: string;
    actions?: ReactNode;
  };

export const InvoicePaymentMethod = ({
  className,
  actions,
  default: isDefault,
  ...props
}: InvoicePaymentMethodProps) => (
  <div
    className={cx(
      className,
      'flex w-full gap-1 p-2 border border-other-500 border-solid rounded bg-other-50',
    )}
  >
    <BillingInformationInfo {...props}>
      {isDefault && (
        <span className="absolute top-0 right-0 md:static font-brand-sm h-3 px-1 ml-1 inline-flex items-center bg-other-200 rounded-sm">
          Default
        </span>
      )}
    </BillingInformationInfo>
    {actions}
  </div>
);

export const InvoicePaymentMethodActions = (props: InvoicePaymentMethodType) => {
  const { modalOpener } = useModalContext();
  const { paymentMethods } = useBillingData();

  const setDefaultPaymentMethod = useSetDefaultPaymentMethodSubmit();

  const deleteOptionVisible = paymentMethods.length > 1;

  return (
    <Menu>
      <Menu.Item onClick={modalOpener(InvoicePaymentMethodModal, { edit: true })}>Edit</Menu.Item>
      {!props.default && (
        <Menu.Item onClick={() => setDefaultPaymentMethod(props.id)}>Set as default</Menu.Item>
      )}
      {deleteOptionVisible && (
        <Menu.Item
          className="text-error-300"
          onClick={modalOpener(DeletePaymentMethodModal, props)}
        >
          Delete
        </Menu.Item>
      )}
    </Menu>
  );
};
