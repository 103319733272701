import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { Any } from '~/common/utils';

import { PUSHER_APP_KEY, SERVER_URL } from '~/env';

(Pusher.Runtime.createXHR as Any) = function () {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

const serverUrl = new URL(SERVER_URL);

export const echo = new Echo({
  broadcaster: 'pusher',
  key: PUSHER_APP_KEY,
  wsHost: serverUrl.hostname,
  wsPort: serverUrl.port || undefined,
  forceTLS: false,
  authEndpoint: serverUrl.protocol + '//' + serverUrl.host + '/broadcasting/auth',
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
});
