import { ReactNode } from 'react';
import { invoice } from '~/assets/images/card-brands';
import { cx } from '~/common/utils';
import { Country } from '~/root/domain';
import { Company } from '../domain';

const formatInfoRow = (...arg: (string | number | null | undefined)[]) => {
  return arg.filter(Boolean).join(', ');
};

type BillingInformationProps = Company & {
  country: Country | null;
  state?: string | null;
  city?: string | null;
  street?: string | null;
  postcode?: string | null;
};

type BillingInformationViewProps = BillingInformationProps & {
  className?: string;
  actions?: ReactNode;
};

export const BillingInformationView = ({
  className,
  actions,
  ...props
}: BillingInformationViewProps) => (
  <div
    className={cx(
      className,
      'flex w-full gap-1 p-2 border border-other-500 border-solid rounded bg-other-50',
    )}
  >
    <BillingInformationInfo {...props} />
    {actions}
  </div>
);

export type BillingInformationInfoProps = BillingInformationProps & {
  children?: ReactNode;
};

export const BillingInformationInfo = ({
  country,
  state,
  city,
  street,
  postcode,
  vatNumber,
  name,
  billingContact,
  children,
}: BillingInformationInfoProps) => (
  <div className="flex relative w-full justify-between">
    <div className="flex flex-col md:flex-row gap-2">
      <img src={invoice} alt="Invoice svg" className="w-5 self-start" />
      <div className="flex flex-col md:flex-row gap-3">
        <BillingInformationInfoSection title="Company details" info={[name, billingContact]} />
        <BillingInformationInfoSection
          title="Billing address"
          info={[formatInfoRow(country?.name, city), street, formatInfoRow(state, postcode)]}
        />
        <BillingInformationInfoSection title="Vat number" info={[vatNumber]} />
      </div>
    </div>

    {children}
  </div>
);

const BillingInformationInfoSection = ({
  title,
  info,
}: {
  title: string;
  info?: (string | number | null | undefined)[];
}) => {
  const filteredItems = info?.filter(Boolean);

  return (
    <div className="inline">
      <h5 className="font-brand-c3 uppercase whitespace-nowrap text-text-300 mb-1">{title}</h5>
      {filteredItems?.length ? (
        filteredItems.map((infoItem, i) =>
          infoItem !== null || infoItem !== undefined ? (
            <p className="font-brand-b3 text-text-500" key={i}>
              {infoItem}
            </p>
          ) : null,
        )
      ) : (
        <span className="font-brand-b3 text-text-500">—</span>
      )}
    </div>
  );
};
