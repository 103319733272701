import { cardBrandToImg } from '~/assets/images/card-brands';
import Icon from '~/components/icons';
import { CardBrand } from '~/root/domain';
import { colors } from '~/utils/palette';

type Props = {
  type?: 'method' | 'package';
  // Method
  method?: 'card';
  isDefault?: boolean;
  last4?: string;
  brand?: CardBrand | null;
  // Package
  amount?: number | null;
  name?: 'Bronze' | 'Silver' | 'Gold' | 'Platinum';
  color?: 'bronze' | 'silver' | 'gold' | 'platinum';
  credits?: number;
  discount?: {
    amount: number;
  };
  look?: number;
};

const defaultProps = {
  type: 'method',
  // Method
  method: 'card',
  isDefault: false,
  last4: '',
  brand: null,
  // Package
  amount: null,
  name: 'Bronze',
  color: 'bronze',
  credits: null,
  discount: {},
  look: 0,
};

const SelectItem = ({
  type,
  isDefault,
  last4,
  brand,
  // Package
  amount,
  color = 'platinum',
  credits,
  name,
  discount,
}: Props) => {
  if (type === 'package') {
    return (
      <div className="card-wrapper f-between-center full-width">
        <div className="f-align-center">
          <span className={`f-align-center card-package card-package--${color}`}>
            <sup>$</sup> {credits}
          </span>
          <span className="fw-400">
            {name} ${credits} credits
          </span>
        </div>
        <div className="card-discount f-align-center">
          <span className="amount semibold">${amount}</span>
          <span className="label label-solid--red f-align-center uppercase mobile-hide relative">
            Save ${discount?.amount}
          </span>
          <span className="uppercase mobile save">Save ${discount?.amount}</span>
        </div>
      </div>
    );
  }

  const renderMethodIcon = () => {
    if (!brand) {
      return <span className="card-icon" />;
    }

    return <img className="card-icon" src={cardBrandToImg(brand)} alt={brand} />;
  };

  const renderMethodType = () => {
    return <span>···· ···· ···· {last4}</span>;
  };

  // Payment method by default
  return (
    <div className="card-wrapper f-align-center">
      {renderMethodIcon()}
      {renderMethodType()}
      {isDefault && (
        <span className="card-default f-between-center">
          <Icon icon="check" fill={colors.mint} width={14} height={11} />
          Default
        </span>
      )}
    </div>
  );
};

SelectItem.defaultProps = defaultProps;
export default SelectItem;
