import { faCircleXmark, faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { cx } from '~/common/utils';
import { IconBox } from '../IconContainers';
import { Button } from '../Interactives';
import { Size } from './types';

type ItemProps<T extends { id: number | string; name: string }> = {
  item: T;
  isDraggedOver: boolean;
  isDragging: boolean;
  removeItem: () => void;
  disabled?: boolean;
  isSnapshot: boolean;
  /** Used for defaultItemRenderer*/
  size?: Size;
  index: number;
};

export const DefaultItem = <T extends { id: number | string; name: string }>({
  item,
  isDraggedOver,
  isSnapshot,
  isDragging,
  removeItem,
  disabled = false,
  size = 'm',
}: ItemProps<T>) => {
  return (
    <div
      className={cx(
        size === 's' ? 'min-h-4 py-[4px]' : 'min-h-5 py-1',
        'flex items-center gap-[4px] px-[4px] outline -outline-offset-1 outline-1 outline-greyscale-300 bg-greyscale-0 rounded-sm relative',
        isDraggedOver && '[&_*]:invisible outline-greyscale-100 bg-greyscale-100',
        isSnapshot && 'outline-secondary-300',
        !disabled && !isDragging && 'hover:outline-greyscale-400',
      )}
    >
      <IconBox
        icon={faGripDotsVertical}
        size="m"
        className={cx(
          'group-focus-visible:text-greyscale-500',
          disabled && 'text-greyscale-300',
          isSnapshot && 'text-greyscale-500',
        )}
      />
      <label
        className={cx(
          'min-w-[192px] text-greyscale-600',
          size === 's' ? 'font-brand-b2r' : 'font-brand-b1',
        )}
      >
        {item.name}
      </label>
      <Button.Icon
        tabIndex={0}
        icon={faCircleXmark}
        className="text-greyscale-300 hover:text-greyscale-400 ml-auto"
        onClick={removeItem}
      />
    </div>
  );
};
