import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FilterUpdater,
  GenericItem,
  OnFilterChange,
  SearchParamsMap,
  SetQueryParams,
} from '../types';
import { encodeQueryString, parseQuery } from '../utils';

export const useParsedQuery = <K extends string, T extends GenericItem = GenericItem>() => {
  const { search } = useLocation();
  const history = useHistory();

  const queryParams = useMemo(() => parseQuery(search) as SearchParamsMap<T, K>, [search]);

  const setQueryParams = (callback: SetQueryParams<T, K>) => {
    const updatedQuery = callback(queryParams);
    history.push({ search: encodeQueryString(updatedQuery) });
  };

  const onFilterChange: OnFilterChange<K> = (name, value) => {
    setQueryParams((params) => ({
      ...params,
      page: 1,
      filter: { ...params.filter, [name]: value },
    }));
  };

  const updateFilters = (filterUpdater: FilterUpdater<K>) => {
    setQueryParams((params) => ({
      ...params,
      page: 1,
      filter: filterUpdater(params.filter),
    }));
  };

  return { queryParams, setQueryParams, onFilterChange, updateFilters };
};
