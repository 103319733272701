import { ReactNode } from 'react';
import { cx } from '~/common/utils';
import { useNavigation } from './context';

type Props = {
  children: ReactNode;
  className?: string;
};

export const NavigationSection = ({ children }: Props) => {
  const { isMenuCollapsed, isFromHeader } = useNavigation();
  return (
    <div
      className={cx('flex flex-col bg-text-100 rounded', {
        'p-1': !isMenuCollapsed,
        'lg:border lg:border-solid lg:border-other-300 lg:rounded': !isFromHeader,
      })}
    >
      {children}
    </div>
  );
};
