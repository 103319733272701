import cx from 'classnames';
import MethodContent from '../confirmation/MethodContent';
import { ConfirmationDetails, PaymentDetailsTotal } from '../domain';
import { useConfirmationContext } from '../utils';
import CreditPackage from './CreditPackage';
import { PaymentMethod } from './PaymentMethod';

export const PAY_WITH_METHODS = ['card', 'credits'] as const;

type Props = {
  enoughCredits: boolean;
  packageDiscount: number | null | undefined;
  creditPackages: ConfirmationDetails['billing']['creditPackages'];
  total: PaymentDetailsTotal;
  billing: ConfirmationDetails['billing'];
};

const PayWithContainer = ({
  enoughCredits,
  packageDiscount,
  creditPackages,
  total,
  billing: { creditBalance, paymentMethods },
}: Props) => {
  const { isToppingUp, creditPackageId, payWithMethod, setPayWithMethod, setTopUpBind } =
    useConfirmationContext();
  const payWithCredits = payWithMethod === 'credits';
  const showCreditPackages: boolean = payWithCredits && !!creditPackages.length;

  const makeDiscountLabel = (method: 'card' | 'credits') => {
    const defaultPackageDiscount = creditPackages.slice(-1)[0]?.discount;
    const defaultDiscount = defaultPackageDiscount && defaultPackageDiscount.percentage;
    const discount = !enoughCredits && (packageDiscount || defaultDiscount);

    if (method === 'credits' && discount) {
      return <span className="label label-solid--red f-center">-{discount}%</span>;
    }

    return null;
  };

  return (
    <>
      <div className="cp__toggler flex">
        {PAY_WITH_METHODS.map((method) => (
          <button
            key={method}
            type="button"
            className={cx('cp__toggle', {
              active: method === payWithMethod,
            })}
            onClick={setPayWithMethod(method)}
          >
            Pay with {method} {makeDiscountLabel(method)}
          </button>
        ))}
      </div>
      <div className="cp__content f-1">
        <MethodContent
          enoughCredits={enoughCredits}
          isToppingUp={isToppingUp}
          showCreditPackages={showCreditPackages}
          method={payWithMethod}
          creditBalance={creditBalance}
          total={total}
          setTopUpBind={setTopUpBind}
        />

        <div
          className={cx({
            none: !showCreditPackages || !isToppingUp,
          })}
        >
          <CreditPackage packages={creditPackages} />
        </div>
        <div
          className={cx({
            none: enoughCredits && payWithCredits && !creditPackageId,
          })}
        >
          <PaymentMethod methods={paymentMethods} />
        </div>
      </div>
    </>
  );
};

export default PayWithContainer;
