import { faFile, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Dropzone from 'react-dropzone';

import { IconBox } from '~/common/components';
import { mimeTypesFile, MAX_SIZE_FILE } from '~/pages/Order-now/constants';
import uploadIcon from './images/upload-icon.svg';

import './upload-file-box.scss';

const propTypes = {
  disabled: PropTypes.bool,
  files: PropTypes.shape({}).isRequired,
  uploadFile: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  styles: PropTypes.shape({}),
  hasError: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  styles: {},
  hasError: false,
};

const errorMessages = {
  sizeError: 'File size exceeds allowed',
  typeError: 'Only image and document are acceptable',
};

/**
 * @deprecated, use useFileDropzone hook or any components from "UploadFields"
 */
class UploadFileBox extends PureComponent {
  state = {
    rejectedFiles: [],
  };

  handleAcceptedDrop = (files) => {
    files.forEach(this.props.uploadFile);
    this.setState(() => ({ rejectedFiles: [] }));
  };

  handleRejectedDrop = (files) => {
    const rejectedFiles = files.map(({ file }) => ({
      name: file.name,
      error: file.size > MAX_SIZE_FILE ? errorMessages.sizeError : errorMessages.typeError,
    }));
    this.setState((state) => ({ rejectedFiles: [...state.rejectedFiles, ...rejectedFiles] }));
  };

  handleRemoveFile = (key, uuid) => () => {
    this.props.removeFile(key, uuid);
  };

  handleRemoveRejectedFile = (index) => () => {
    this.setState((state) => {
      const rejectedFiles = state.rejectedFiles.slice();
      rejectedFiles.splice(index, 1);
      return { rejectedFiles };
    });
  };

  mapFiles = (fileKey) => {
    const { hasError, errorMessage, name, progress, uuid } = this.props.files[fileKey];
    const isUploading = this.props.files[fileKey].isUploading;
    return (
      <div
        key={fileKey}
        className={cx('uploaded-files__row', { 'uploaded-files__row--rejected': hasError })}
      >
        <div className={cx('upload-file-name', isUploading && 'w-1/2')}>
          <IconBox icon={faFile} className="text-text-300" size="s" />
          <span className="truncate">{name}</span>
        </div>
        {isUploading ? (
          <LinearProgress className="upload-progress" variant="determinate" value={progress || 0} />
        ) : (
          <span className={cx('upload-status', { 'upload-status--rejected': hasError })}>
            {errorMessage || 'Done!'}
          </span>
        )}
        <IconBox
          icon={faTrashAlt}
          className="text-text-300 cursor-pointer"
          size="s"
          onClick={this.handleRemoveFile(fileKey, uuid)}
        />
      </div>
    );
  };

  mapRejectedFiles = (file, index) => (
    <div className="uploaded-files__row--rejected" key={file.name}>
      <div className="upload-file-name">
        <IconBox icon={faFile} className="text-text-300" size="s" />
        <span>{file.name}</span>
      </div>
      <div className="upload-status upload-status--rejected">{file.error || 'Rejected'}</div>
      <IconBox
        icon={faTrashAlt}
        className="text-text-300 cursor-pointer"
        size="s"
        onClick={this.handleRemoveRejectedFile(index)}
      />
    </div>
  );

  render() {
    const { disabled, hasError, styles } = this.props;
    const mappedFiles = Object.keys(this.props.files).map(this.mapFiles);
    const mappedRejectedFiles = this.state.rejectedFiles.map(this.mapRejectedFiles);

    return (
      <>
        <div className="drop-zone">
          <Dropzone
            accept={mimeTypesFile}
            maxSize={MAX_SIZE_FILE}
            onDropAccepted={this.handleAcceptedDrop}
            onDropRejected={this.handleRejectedDrop}
            multiple
          >
            {({ getRootProps, getInputProps }) => (
              <div
                style={styles}
                className={cx(
                  'upload-box',
                  { 'upload-box--disabled': disabled },
                  { 'upload-box--error': hasError },
                )}
                {...getRootProps({
                  onDragOver: (event) => event.target.classList.add('upload-box--active'),
                  onDragLeave: (event) => event.target.classList.remove('upload-box--active'),
                })}
              >
                <div
                  className={cx(
                    'upload-button',
                    { 'upload-button--disabled': disabled },
                    { 'upload-button--error': hasError },
                  )}
                >
                  <img className="upload-button__icon" src={uploadIcon} alt="upload icon" />
                </div>
                <div className="upload-description">Choose a files or drag your files here</div>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>

          {(Object.keys(this.props.files).length > 0 || this.state.rejectedFiles.length > 0) && (
            <div className="uploaded-files">
              {mappedFiles}
              {mappedRejectedFiles}
            </div>
          )}
        </div>
      </>
    );
  }
}

UploadFileBox.propTypes = propTypes;
UploadFileBox.defaultProps = defaultProps;

export default UploadFileBox;
