import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent } from '@material-ui/core';
import dayjs from 'dayjs';
import { Children, cloneElement, ReactNode, useCallback, useMemo } from 'react';
import { Any, cx } from '~/common/utils';
import { ModalContent } from './Content/ModalContent';
import { useModal } from './useModal';

const getModalClassNames = (className?: string) => {
  const classes = {
    dialog: 'modal__dialog',
    modal: 'modal__modal',
    button: 'modal__close-button',
  };

  if (className) {
    classes.dialog += ` ${className}__dialog`;
    classes.modal += ` ${className}__modal`;
    classes.button += ` ${className}__close-button`;
  }

  return classes;
};

type Props = {
  show?: boolean;
  disabled?: boolean;
  closeIcon?: boolean;
  ignoreHash?: boolean;
  hashManually?: boolean;
  scroll?: 'body' | 'paper';
  hash?: string | null;
  className?: string;
  closeIconColor?: string;
  modal: string;
  transitionDuration?:
    | {
        enter: number;
        exit: number;
      }
    | number;
  children?: ReactNode;
  onOpen?: () => void;
  onAccept?: () => void;
  onClose?: () => void;
  // TODO: refactor this. It's for situations like passing brief to orderBriefModal
  [x: string]: Any;
};

/**
 * `Modal` consists own open/close logic
 *
 * `modal` is required. Check `ModalContent` prop-types for more details
 */
// todo: describe `onAccept`, `onClose` workflow
// todo: describe Children workflow
export const Modal = ({
  show = false,
  disabled = false,
  closeIcon = true,
  ignoreHash = false,
  hashManually = false,
  scroll = 'paper',
  hash = null,
  className = '',
  // closeIconColor: colors.graysuit,
  closeIconColor = '#CECECE',
  // todo: replace by palette color
  modal,
  transitionDuration = {
    enter: 225,
    exit: 195,
  },
  children = null,
  onOpen = () => {},
  onAccept = () => {},
  onClose = () => {},
  ...props
}: Props) => {
  // Modal can't be shown if `disabled` equals `true`
  const initialShowStatus = !disabled && show;
  const [{ open }, { setModalClose, setModalOpen }] = useModal(initialShowStatus);
  const classes = getModalClassNames(className);
  const handleShow = useCallback(() => {
    setModalOpen();
    onOpen();
  }, [onOpen, setModalOpen]);
  const handleClose = useCallback(
    (...args) => {
      onClose(...args);
      setModalClose();

      if (!hashManually && hash) {
        localStorage.setItem(hash, 'hash');
      }
    },
    [hashManually, hash, onClose, setModalClose],
  );
  const handleAccept = useCallback(
    (...args) => {
      onAccept(...args);
      setModalClose();
    },
    [onAccept, setModalClose],
  );
  const modalTogglerProps = useMemo(
    () => ({
      ...(!disabled && {
        onClick: handleShow,
      }),
    }),
    [disabled, handleShow],
  );

  // todo: describe `getHashCondition` workflow
  const getHashCondition = (): boolean => {
    /**
     * `modalHash` can be `timestamp`
     */
    if (!hash) {
      return false;
    }

    const modalHash = localStorage.getItem(hash);

    if (!modalHash) {
      return false;
    }

    try {
      const dateHash = JSON.parse(modalHash);
      // todo: add description of dayjs timestamp
      return dayjs().isBefore(dayjs(dateHash));
    } catch (error) {
      return true;
    }
  };

  const CloseIcon = closeIcon && (
    <button type="button" className={cx('bb size-24', classes.button)} onClick={handleClose}>
      <FontAwesomeIcon icon={faTimes} color={closeIconColor} />
    </button>
  );

  /**
   * Prevent modal displaying if `hash` exists in local storage
   * Prevent modal displaying if `ignoreHash` is `false`. It `false` by default
   */
  const preventModalDisplaying = !ignoreHash && getHashCondition();

  if (preventModalDisplaying) {
    return null;
  }

  return (
    <>
      <Dialog
        className={cx('modal', className)}
        open={open}
        scroll={scroll}
        classes={{
          paper: classes.dialog,
        }}
        transitionDuration={transitionDuration}
        onClose={handleClose}
        disableEnforceFocus
      >
        <DialogContent
          classes={{
            root: classes.modal,
          }}
        >
          {CloseIcon}

          <ModalContent
            {...props}
            modal={modal}
            hash={hash}
            onClose={handleClose}
            onAccept={handleAccept}
          />
        </DialogContent>
      </Dialog>
      {Children.map(children, (child) => cloneElement(child, modalTogglerProps))}
    </>
  );
};
