import { ReactNode } from 'react';

import './text-link.scss';

/**
 * @deprecated
 */
export const TextLink = ({
  dark = true,
  href,
  children,
  onClick,
  className,
}: {
  dark?: boolean;
  href?: string;
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <a
      className={`text-link-24slides--${dark ? 'dark' : 'light'} ${className}`}
      href={href}
      onClick={onClick}
    >
      {children}
    </a>
  );
};
