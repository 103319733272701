import { colors } from '~/utils/palette';
import type { IconType } from './types';
const defaultProps = {
  fill: colors.graysuit,
  fills: [],
  width: 23,
  height: 25,
};

const IconCursorPointer = ({
  fill,
  fills = [],
  // to resolve Flow.js and `defaultProps` conflict
  width,
  height,
}: IconType) => (
  <svg width={width} height={height} viewBox="0 0 23 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.572 2.143c-.474 0-.878.167-1.213.502a1.652 1.652 0 00-.502 1.212v12l-2.022-2.705c-.366-.482-.844-.723-1.433-.723-.464 0-.862.17-1.192.508a1.67 1.67 0 00-.496 1.206c0 .384.116.727.349 1.031l5.142 6.857c.34.456.795.683 1.367.683h9.616c.196 0 .372-.06.529-.18a.805.805 0 00.3-.463l1.233-4.928c.214-.857.322-1.723.322-2.598v-2.907c0-.366-.125-.683-.375-.95a1.2 1.2 0 00-.911-.402c-.357 0-.66.125-.91.375a1.24 1.24 0 00-.376.91h-.428v-.816c0-.43-.143-.793-.43-1.092a1.42 1.42 0 00-1.07-.449c-.411 0-.764.148-1.058.442a1.443 1.443 0 00-.442 1.058v.857h-.43v-1.205c0-.491-.164-.913-.495-1.266a1.605 1.605 0 00-1.218-.529c-.474 0-.878.168-1.212.503a1.652 1.652 0 00-.503 1.212v1.285h-.428V3.938c0-.492-.165-.913-.496-1.266a1.604 1.604 0 00-1.218-.53z"
      fill={fills[0] || fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.571 2.143c-.473 0-.877.167-1.212.502a1.652 1.652 0 00-.502 1.212v12l-2.022-2.705c-.366-.482-.844-.723-1.433-.723-.465 0-.862.17-1.192.508a1.67 1.67 0 00-.496 1.206c0 .384.116.727.349 1.031l5.142 6.857c.34.456.795.683 1.366.683h9.617c.196 0 .372-.06.529-.18a.805.805 0 00.3-.463l1.233-4.928c.214-.857.321-1.723.321-2.598v-2.907c0-.366-.125-.683-.375-.95a1.2 1.2 0 00-.91-.402c-.357 0-.661.125-.911.375a1.24 1.24 0 00-.375.91h-.429v-.816c0-.43-.142-.793-.428-1.092a1.42 1.42 0 00-1.072-.449c-.41 0-.763.148-1.058.442a1.443 1.443 0 00-.442 1.058v.857h-.428v-1.205c0-.491-.165-.913-.496-1.266a1.604 1.604 0 00-1.218-.529c-.474 0-.878.168-1.213.503a1.652 1.652 0 00-.502 1.212v1.285h-.428V3.938c0-.492-.165-.913-.496-1.266a1.604 1.604 0 00-1.219-.53zm0-1.714c.956 0 1.766.346 2.431 1.038.665.691.998 1.515.998 2.47v2.947c.196-.018.34-.027.429-.027.883 0 1.656.308 2.316.924.42-.187.862-.281 1.326-.281 1.01 0 1.83.388 2.465 1.165.24-.062.49-.094.75-.094.839 0 1.549.302 2.13.904.58.603.87 1.324.87 2.163v2.907c0 1.035-.125 2.04-.375 3.013l-1.232 4.929a2.494 2.494 0 01-.911 1.4 2.504 2.504 0 01-1.58.542H8.57a3.36 3.36 0 01-1.533-.369 3.478 3.478 0 01-1.212-.997L.683 16.204A3.35 3.35 0 010 14.143c0-.938.333-1.743.998-2.418.665-.674 1.466-1.01 2.404-1.01a3.35 3.35 0 011.74.468V3.857c0-.946.336-1.754 1.005-2.424A3.303 3.303 0 018.571.429zm1.286 18.857v-5.143h.429v5.143h-.429zm3.429 0v-5.143h.428v5.143h-.428zm3.428 0v-5.143h.429v5.143h-.429z"
      fill={fills[1] || '#000'}
    />
  </svg>
);

IconCursorPointer.defaultProps = defaultProps;
export default IconCursorPointer;
