import { NonEmptyArray } from 'formoid/lib/Array';
import { cx } from '~/common/utils';
import css from './Input.module.scss';

type Props = {
  errors: NonEmptyArray<string>;
  className?: string;
};

export const Errors = ({ errors, className }: Props) => {
  if (errors.length === 1) {
    return (
      <span className={cx(css.errors, className)} aria-label="form-error">
        {errors[0]}
      </span>
    );
  }

  return (
    <div className={cx(css.multipleErrors, className)} aria-label="form-error">
      <ul className={cx(css.errors)}>
        {errors.map((error, key) => (
          <li key={key}>{error}</li>
        ))}
      </ul>
    </div>
  );
};
