import { faSortCircle, faSortCircleDown, faSortCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode, useCallback, useEffect } from 'react';
import { IconBox, Link, Pagination } from '~/common/components';
import { cx } from '~/common/utils';
import { AnyColumn, Column, GenericItem, TableView } from '../types';
import css from './Table.module.scss';

type CellProps = {
  className?: string;
  children: ReactNode;
};

export const Cell = ({ className, children }: CellProps) => (
  <div className="group-hover:bg-other-100 group/cell">
    <div className={cx(className, css.cell)}>
      {typeof children === 'string' ? (
        <span className="truncate">{children ?? '—'}</span>
      ) : (
        children
      )}
    </div>
  </div>
);

type TableProps<T extends GenericItem> = TableView<T> & {
  className?: string;
  containerClassName?: string;
  paginationClassName?: string;
  noDataPlaceholder?: ReactNode;
  onRowClick?: (rowData: T) => void;
  linkGetter?: (rowData: T) => string;
};

export const Table = <T extends GenericItem>({
  tableConfig,
  items,
  totalPages,
  total,
  results,
  page,
  onPageChange,
  sort,
  onSort,
  className,
  containerClassName,
  paginationClassName,
  onRowClick,
  isFetching,
  noDataPlaceholder,
  linkGetter,
}: TableProps<T>) => {
  const mapTableHead = ({ key, name, headerClassName }: Column<T, keyof T>): JSX.Element => {
    const sorted = key === sort?.option;
    const sortable = tableConfig.sortable.includes(key);
    const isSortedAsc = sorted && sort.order === 'asc';
    const handleSort = () => sortable && onSort(key);

    return (
      <div key={key as string} className={cx(headerClassName, css.th, 'first:pl-3 first:md:pl-4')}>
        <div
          className={cx(css.thTextContainer, sortable && css.sortable, sorted && css.sorted)}
          onClick={handleSort}
        >
          <span>{name}</span>
          {sortable && (
            <IconBox
              className={css.icon}
              icon={sorted ? (isSortedAsc ? faSortCircleUp : faSortCircleDown) : faSortCircle}
            />
          )}
        </div>
      </div>
    );
  };

  const TableHead = tableConfig.columns.map(mapTableHead);

  const makeBodyRow = (rowData: T) => {
    const makeColumnCell = (column: AnyColumn<T>, hasLink = false) => {
      const cellData = rowData[column.key];
      const params = { cellData, rowData };
      return (
        <Cell
          key={rowData.id + (column.key as string)}
          className={cx(
            column.getClassName?.(params),
            tableConfig.getCellClassName?.(rowData),
            // otherwise link overlay takes first-of-type, ruining left padding
            // for the first column
            hasLink
              ? 'group-[:nth-of-type(2)]/cell:pl-3 group-[:nth-of-type(2)]/cell:md:pl-4'
              : 'group-first-of-type/cell:pl-3 group-first-of-type/cell:md:pl-4',
          )}
        >
          {column.cellRenderer ? column.cellRenderer(params) : (cellData as ReactNode) ?? '—'}
        </Cell>
      );
    };

    if (linkGetter) {
      return (
        <div key={rowData.id} className="group contents cursor-pointer">
          <div className="h-0 col-span-full relative">
            <Link.Base
              key={rowData.id}
              className={cx(
                'absolute left-0 right-0 top-0 z-[1] min-h-[48px]',
                tableConfig.getCellClassName?.(rowData),
              )}
              to={linkGetter(rowData)}
            />
          </div>
          {tableConfig.columns.map((column) => makeColumnCell(column, true))}
        </div>
      );
    }

    return (
      <div
        key={rowData.id}
        className={cx('group contents', onRowClick && 'cursor-pointer')}
        onClick={() => onRowClick?.(rowData)}
      >
        {tableConfig.columns.map((column) => makeColumnCell(column))}
      </div>
    );
  };

  const TableBody = items.map(makeBodyRow);

  const scrollToTop = useCallback(() => {
    if (!tableConfig.disableScrollOnPageSwitch) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [tableConfig.disableScrollOnPageSwitch]);

  useEffect(() => {
    if (isFetching === false) {
      scrollToTop();
    }
  }, [isFetching, scrollToTop]);

  const handlePageChange = (page: number) => {
    if (isFetching === undefined) {
      scrollToTop();
    }
    onPageChange(page);
  };

  if (!items.length && noDataPlaceholder) {
    return <>{noDataPlaceholder}</>;
  }

  const gridTemplateColumns = tableConfig.columns
    .map((item) => item.width || 'minmax(0, 1fr)')
    .join(' ');

  return (
    <div className={cx('overflow-auto relative max-h-full', containerClassName)}>
      <div className={cx(css.table, className)} style={{ gridTemplateColumns }}>
        {TableHead}
        {TableBody}
      </div>
      {totalPages > 1 && (
        <Pagination
          className={cx('pt-3 px-3 md:px-4 sticky bottom-0 left-0 bg-white', paginationClassName)}
          page={page}
          totalPages={totalPages}
          results={results}
          total={total}
          onClick={handlePageChange}
        />
      )}
    </div>
  );
};
