import cx from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Avatar, getColorsFromString } from '~/common/components';
import { UploadedFile } from '~/root/domain';
import Attachment from '../Attachment';
import { BookCall } from '../BookCall';

import styles from './User.module.scss';

interface UserProps {
  type?: 'manager' | 'user';
  avatar?: string | null;
  // todo: update `avatar` as required but nullable prop
  name: string;
  comment?: string;
  // null if `manager`
  role?: string;
  initials: string;
  timestamp?: number;
  files?: Array<UploadedFile> | null;
  online?: boolean;
  calendly?: string | null;
}

export const User = ({
  type = 'user',
  avatar,
  name,
  comment,
  role,
  initials,
  timestamp,
  files,
  online = false,
  calendly,
}: UserProps) => {
  const avatarColorSet = useMemo(() => getColorsFromString(initials), [initials]);

  if (type === 'manager') {
    return (
      <div className="iop__manager paddings">
        <div className="iou__container flex-align-center no-margin">
          <div className="relative mr-1 shrink-0">
            <Avatar url={avatar} colorSet={avatarColorSet}>
              {initials}
            </Avatar>
            <div className={cx(styles.status, online ? 'bg-secondary-300' : 'bg-other-500')} />
          </div>
          <div className="flex-justify-between full-width flex-wrap flex-align-center">
            <div
              className={cx('f-column iop__manager-label-container', {
                limit: calendly,
              })}
            >
              <span
                className={cx('iou__name text-overflow', {
                  graysuit: !online,
                })}
                title={name}
              >
                {name}
              </span>
              {role && (
                <span
                  className={cx('iou__role', {
                    graysuit: !online,
                  })}
                >
                  {role}
                </span>
              )}
            </div>
            {calendly && <BookCall url={calendly} />}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="inner-order-user iou__container flex f-shrink-0">
      <Avatar url={avatar} colorSet={avatarColorSet} className="mr-[10px]">
        {initials}
      </Avatar>
      <div className="flex-justify-between full-width flex-column min-width-0">
        <div className="iou__header flex-between-center">
          <span className="iou__name text-overflow">{name}</span>
          {timestamp && (
            <span className="iou__time flex-shrink-0">{dayjs.unix(timestamp).fromNow()}</span>
          )}
        </div>
        <p>{comment}</p>
        {files && <Attachment files={files} />}
      </div>
    </div>
  );
};
