import { validator } from 'formoid';
import { Validator } from 'formoid/lib/validator';
import {
  CONTAINS_DIGIT_REGEXP,
  CONTAINS_LOWERCASE_LETTER_REGEXP,
  CONTAINS_SYMBOL_REGEXP,
  CONTAINS_UPPERCASE_LETTER_REGEXP,
  Email,
  LETTERS_ONLY_LATIN_REGEXP,
  ONLY_LETTERS_REGEXP,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  isEmail,
} from '~/common/utils';
import { maxLengthMessage, minLengthMessage } from './messages';

export const required = <A>(message?: string): Validator<A, NonNullable<A>> => {
  return validator.defined<A>(message || 'This field is required');
};

export const positiveInteger = (message?: string) => {
  return validator.fromPredicate(
    (n: number) => n > 0 && Number.isInteger(n),
    message || 'Please enter a number bigger than 0',
  );
};

export const onlyLatinLetters = (message?: string) => {
  return validator.match(ONLY_LETTERS_REGEXP, message || 'Please use latin letters');
};

export const password = (messages?: {
  minMessage?: string;
  maxMessage?: string;
  wrongCharactersMessage?: string;
}) => {
  return validator.sequence(
    validator.minLength(
      PASSWORD_MIN_LENGTH,
      messages?.minMessage || minLengthMessage('Password', PASSWORD_MIN_LENGTH),
    ),
    validator.maxLength(
      PASSWORD_MAX_LENGTH,
      messages?.maxMessage || maxLengthMessage('Password', PASSWORD_MAX_LENGTH),
    ),
    validator.match(CONTAINS_DIGIT_REGEXP, 'Should contain at least one digit'),
    validator.match(CONTAINS_SYMBOL_REGEXP, 'Should contain at least one symbol'),
    validator.match(LETTERS_ONLY_LATIN_REGEXP, 'Only latin letters are allowed'),
    validator.match(
      CONTAINS_UPPERCASE_LETTER_REGEXP,
      'Should contain at least one uppercase letter',
    ),
    validator.match(
      CONTAINS_LOWERCASE_LETTER_REGEXP,
      'Should contain at least one lowercase letter',
    ),
  );
};

export const email = (message = 'Please enter valid email'): Validator<string, Email> => {
  return validator.fromPredicate(isEmail, message);
};

export const phoneNumber = () =>
  validator.sequence(
    validator.minLength(4, 'Phone number is too short'),
    validator.maxLength(15, 'Phone number is too long'),
  );
