import { z } from 'zod';

const dateRegex = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{6}Z$');
const timelessDateRegex = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');

export const DateFromIsoString = z
  .string()
  .refine((value) => dateRegex.test(value) || timelessDateRegex.test(value), {
    message: 'Invalid date string format',
  })
  .transform((value, ctx) => {
    // Convert the string to a Date object
    const date = new Date(value);
    if (!Number.isNaN(date.getTime())) {
      return date;
    }
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Invalid date',
    });
    return z.NEVER;
  });
