import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconBox, Link, Tooltip } from '~/common/components';
import { routes } from '~/constants';

type Props = {
  className: string;
  vat: number;
  vatPercent: string | number;
};

const VatHint = ({ className, vat, vatPercent }: Props) => {
  /**
   * User has no VAT additional payment
   * if he added it to billing data
   */
  if (!vatPercent) {
    return null;
  }

  return (
    <li role="button" className={className}>
      <span className="f-align-center">
        VAT
        <div className="vat-hint f-align-center">
          <Tooltip
            className="w-[230px]"
            content={
              <>
                <div className="mb-1">
                  If you are ordering on behalf of a company in the EU, add your VAT number.
                </div>
                <Link to={routes.profile.billing.overview}>Add your VAT number</Link>
              </>
            }
            clickable
          >
            <IconBox icon={faQuestionCircle} />
          </Tooltip>
        </div>
      </span>
      <span>
        ${vat} ({vatPercent}%)
      </span>
    </li>
  );
};

export default VatHint;
