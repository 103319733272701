import { browserTracingIntegration, init, setContext, setTag } from '@sentry/react';
import { ZodError } from 'zod';
import { formatZodError, getTimezone } from '~/common/utils';
import { ENV, SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_RELEASE } from '~/env';

if (!ENV.DEVELOPMENT) {
  init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,

    // This enables automatic instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    integrations: [browserTracingIntegration()],

    // Attach release info
    release: SENTRY_RELEASE,

    // To set a uniform sample rate
    tracesSampleRate: 0.2,

    // To not truncate useful validation error payload
    maxValueLength: 3000,

    // Route through our tunnel to avoid cors issue
    tunnel: 'https://24slides.com/sentry',

    beforeSend: (event, hint) => {
      const error = hint.originalException;
      if (error instanceof ZodError && event.exception) {
        event.exception.values = event.exception.values?.map((value) => {
          return value.type === 'ZodError' ? { ...value, value: formatZodError(error) } : value;
        });
      }
      return event;
    },
  });

  setTag('app', 'react');

  setContext('Timezone', { timezone: getTimezone() });
}
