import {
  faFolders,
  faQuestionCircle,
  faPalette,
  faSliders,
  faSquarePollVertical,
  faUsers,
  faWallet,
  faCog,
  faShieldCheck,
  faUserAlt,
} from '@fortawesome/pro-light-svg-icons';

import { HELP, routes } from '~/constants';
import { NavigationConfig } from '../types';

export const config = {
  links: {
    myOrders: {
      title: 'My orders',
      to: routes.profile.orders,
      icon: faFolders,
    },
    myTeam: {
      title: 'My team',
      to: routes.profile.team,
      icon: faUsers,
    },
    billing: {
      title: 'Billing',
      to: routes.profile.billing.index,
      icon: faWallet,
      canShow: (user) => Boolean(user?.manageCredits),
    },
    needHelp: {
      title: 'Need help?',
      href: HELP,
      newTab: true,
      icon: faQuestionCircle,
    },
    customFields: {
      title: 'Custom fields',
      to: routes.profile.fields,
      icon: faSliders,
      canShow: (user) => Boolean(user?.supportsCustomFields),
    },

    userDetails: {
      title: 'User details',
      to: routes.profile.user_details,
      icon: faUserAlt,
    },
    security: {
      title: 'Security',
      to: routes.profile.security,
      icon: faShieldCheck,
    },
    capacityOverview: {
      title: 'Insights Dashboard',
      to: routes.profile.capacity_overview,
      icon: faSquarePollVertical,
      canShow: (user) => Boolean(user?.hasDailyCapacity && user.customerRole !== 'manager'),
    },
    styleTemplates: {
      title: 'Style Templates',
      to: routes.profile.style_templates,
      icon: faPalette,
      canShow: (user) => user?.customerRole === 'owner',
    },
  },
  subMenus: {
    settings: {
      icon: faCog,
      title: 'Settings',
    },
  },
} satisfies NavigationConfig;
