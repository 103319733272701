export const addQueryParams = (link: string, addedParams: Record<string, unknown>) => {
  const originalLink = link;
  // just so it parses relative paths without origin, e.g. /v1/staff/something?one=two
  if (!originalLink.startsWith('http')) {
    link = window.location.origin + link;
  }

  const { origin, pathname, searchParams } = new URL(link);
  Object.entries(addedParams).forEach(([k, v]) => {
    // filter falsy values from query string
    if (v) {
      searchParams.set(k, String(v));
    }
  });
  let patchedLink = `${origin}${pathname}?${searchParams}`;

  // then reverting added origin back in case it wasn't there
  if (!originalLink.startsWith('http')) {
    patchedLink = patchedLink.replace(window.location.origin, '');
  }

  return patchedLink;
};

/** Checks whether we should use client routing when getting a redirect link from BE */
export const getLinkInfo = (url: string) => {
  const { host, pathname } = new URL(url);
  const client = host.startsWith('app.24slides');
  return { client, url: client ? pathname : url };
};
