import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useInit } from '../Auth';

const referrerKey = '_referrer';

export const useReferrer = () => {
  const location = useLocation<{ referrer?: string }>();
  const init = useInit();

  useEffect(() => {
    if (location.state?.referrer) {
      sessionStorage.setItem(referrerKey, location.state.referrer);
    }
  }, [location.state?.referrer]);

  useEffect(() => {
    if (init.data?.user) {
      sessionStorage.removeItem(referrerKey);
    }
  }, [init.data?.user]);

  return location.state?.referrer || sessionStorage.getItem(referrerKey);
};
