type DotsKey = 'left' | 'right';

export type PageItem = {
  key: number | DotsKey;
  value: number | '...';
};

const AMOUNT_OF_PAGES_MAP = {
  least: [1, 2],
  more: [3, 8],
  most: [4, 16],
} as const;

const DOTS = '...';

export const getPages = (
  currentPage: number,
  pagesAmount: number,
  visiblePagesAmount: keyof typeof AMOUNT_OF_PAGES_MAP = 'least',
) => {
  const [normalDelta, compensation] = AMOUNT_OF_PAGES_MAP[visiblePagesAmount];
  const compensatedDelta = compensation - Math.min(currentPage - 1, pagesAmount - currentPage);
  const delta = Math.max(normalDelta, compensatedDelta);
  const pages: PageItem[] = [];
  let key: DotsKey = 'left';

  for (let page = 1; page <= pagesAmount; page++) {
    const isFirstOrLast = page === 1 || page === pagesAmount;
    const isInDeltaRange = page - delta <= currentPage && page + delta >= currentPage;

    if (isFirstOrLast || isInDeltaRange) {
      pages.push({ key: page, value: page });
      continue;
    }

    if (pages[pages.length - 1].value !== DOTS) {
      pages.push({ key, value: DOTS });
      key = 'right';
    }
  }

  return pages;
};
