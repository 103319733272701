import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconBox, Link, ModalCloseButton, ModalContentProps } from '~/common/components';
import { ProBannerImage } from '~/images/banners';

const Bullet = ({ label, description }: { label: string; description: string }) => {
  return (
    <div className="flex gap-1">
      <IconBox icon={faCheck} size="m" className="text-secondary-300" />
      <div>
        <p className="font-brand-h6 text-text-500 mb-1">{label}</p>
        <p className="font-brand-b3 text-text-400">{description}</p>
      </div>
    </div>
  );
};

export const ProModal = ({ onClose }: ModalContentProps) => {
  return (
    <>
      <div className="md:w-[900px] overflow-hidden relative rounded-md">
        <ModalCloseButton onClose={onClose} className="!text-text-100" />
        {/* This div is for retaining vertical height on mobile for image */}
        <div className="hidden smo:block h-[260px]" />
        <img
          src={ProBannerImage}
          className="absolute right-0 top-0 smo:left-0 smo:w-full object-cover md:h-full bg-text-600 smo:h-[260px]"
        />
        <div className="md:w-[400px] z-10 bg-text-100 relative p-4 smo:px-3 box-content">
          <p className="font-brand-h5 text-text-500 mb-3">Introducing our new Pro plan</p>
          <div className="space-y-2 mb-4">
            <Bullet
              label="10-Hour Turnaround"
              description="Enjoy expedited service with a 10-hour turnaround available."
            />
            <Bullet
              label="Priority Project Management"
              description="Gain priority access to our project team, ensuring higher quality and a dedicated go-to person for all your needs."
            />
            <Bullet
              label="Full Flexibility"
              description="Benefit from a flexible subscription model where you pay for the slides you have done, giving you complete control over your costs."
            />
          </div>
          <Link.Button href={'https://24slides.com/plans/pro'} size="m" onClick={onClose} newTab>
            Learn More
          </Link.Button>
        </div>
      </div>
    </>
  );
};
