import {
  CLICK_ON_STAR,
  TOGGLE_PUBLISH_FEEDBACK,
  FEEDBACK_INIT_REQUEST,
  FEEDBACK_INIT_SUCCESS,
  FEEDBACK_INIT_FAILURE,
  TRY_TO_SUBMIT_WITHOUT_FEEDBACK,
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
} from './feedback.types';

const STATUS_SUCCESS = 'success';

const feedbackData = {
  scores: {
    design: 0,
    understandingOfRequirement: 0,
    adherenceToDeadline: 0,
    attentionToDetail: 0,
    average: 0,
  },
  feedbacks: {
    publishable: false,
    internal: '',
    good: '',
    bad: '',
  },
  hasOldFeedback: false,
  isLoading: false,
  isSucceed: false,
  error: false,
};

const submitData = {
  isTriedToSubmitWithoutFeedback: false,
  isLoading: false,
  isSucceed: false,
  error: false,
};

const initialState = {
  feedbackData,
  submit: submitData,
};

export default function feedbackReducers(state = initialState, action) {
  switch (action.type) {
    case CLICK_ON_STAR: {
      const { name, score } = action.payload;

      return {
        ...state,
        feedbackData: {
          ...state.feedbackData,
          scores: {
            ...state.feedbackData.scores,
            [name]: score,
          },
        },
      };
    }

    case TOGGLE_PUBLISH_FEEDBACK: {
      const { publishable } = state.feedbackData.feedbacks;

      return {
        ...state,
        feedbackData: {
          ...state.feedbackData,
          feedbacks: {
            ...state.feedbackData.feedbacks,
            publishable: !publishable,
          },
        },
      };
    }

    case FEEDBACK_INIT_REQUEST: {
      return {
        ...state,
        feedbackData: {
          ...state.feedbackData,
          isLoading: true,
          error: false,
        },
      };
    }

    case FEEDBACK_INIT_SUCCESS: {
      const initData = action.isThereOldFeedback ? action.payload.data.feedback : {};
      let scores = {};
      let feedbacks = {};

      if (initData.scores) {
        scores = {
          design: +initData.scores.design ? +initData.scores.design : 0,
          understandingOfRequirement: +initData.scores.understandingOfRequirement
            ? +initData.scores.understandingOfRequirement
            : 0,
          adherenceToDeadline: +initData.scores.adherenceToDeadline
            ? +initData.scores.adherenceToDeadline
            : 0,
          attentionToDetail: +initData.scores.attentionToDetail
            ? +initData.scores.attentionToDetail
            : 0,
          average: +initData.scores.average ? +initData.scores.average : 0,
        };
      }

      if (initData.feedbacks) {
        feedbacks = {
          publishable: !!+initData.feedbacks.publishable, // receive "1" for example
          internal: initData.feedbacks.internal ? initData.feedbacks.internal : '',
          good: initData.feedbacks.good ? initData.feedbacks.good : '',
          bad: initData.feedbacks.bad ? initData.feedbacks.bad : '',
        };
      }

      return {
        ...state,
        feedbackData: {
          ...state.feedbackData,
          isLoading: false,
          error: false,
          isSucceed: true,
          hasOldFeedback: action.isThereOldFeedback,
          scores: {
            ...state.feedbackData.scores,
            ...scores,
          },
          feedbacks: {
            ...state.feedbackData.feedbacks,
            ...feedbacks,
          },
        },
      };
    }

    case FEEDBACK_INIT_FAILURE: {
      return {
        ...state,
        feedbackData: {
          ...state.feedbackData,
          isLoading: false,
          isSucceed: false,
          hasOldFeedback: false,
          error: action.error,
        },
      };
    }

    case TRY_TO_SUBMIT_WITHOUT_FEEDBACK: {
      return {
        ...state,
        submit: {
          ...state.submit,
          isTriedToSubmitWithoutFeedback: true,
        },
      };
    }

    case SUBMIT_FEEDBACK_REQUEST: {
      return {
        ...state,
        submit: {
          ...state.submit,
          isLoading: true,
          error: false,
        },
      };
    }

    case SUBMIT_FEEDBACK_SUCCESS: {
      const isSucceed = action.payload.data && action.payload.data.status === STATUS_SUCCESS;

      return {
        ...state,
        submit: {
          ...state.submit,
          isSucceed,
          isLoading: false,
          error: false,
        },
        feedbackData: {
          ...state.feedbackData,
          hasOldFeedback: isSucceed,
        },
      };
    }

    case SUBMIT_FEEDBACK_FAILURE: {
      return {
        ...state,
        submit: {
          ...state.submit,
          isLoading: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}
