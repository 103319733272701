import { UseQueryResult } from '@tanstack/react-query';
import { NonEmptyArray } from 'formoid/lib/Array';
import { ReactElement, ReactNode } from 'react';
import { Any } from './app';

type PrefetcherProps<T> = {
  reactQueryHooks: NonEmptyArray<() => UseQueryResult<T>>;
  children: ReactNode;
};

const Prefetcher = <T,>({ reactQueryHooks, children }: PrefetcherProps<T>) => {
  const hooks = reactQueryHooks.map((hook) => hook());
  for (const hook of hooks) {
    if (hook.data === undefined) {
      return null;
    }
  }
  return <>{children}</>;
};

/**
 * Use this to prevent assertions breaking in useSomethingData react-query hooks
 *
 * It's kinda hard to account for all places to prefetch for now, and it's
 * annoying to use react-query hooks as is for all modals and other small stuff
 * where it's really convenient to use useSomethingData where data is T instead
 * of useSomething where .data is T | undefined
 */
export const createPrefetchWrapper = <T,>(
  ...reactQueryHooks: NonEmptyArray<() => UseQueryResult<T>>
) => {
  return <P,>(Component: (props: P & JSX.IntrinsicAttributes) => ReactElement<Any, Any> | null) => {
    return (props: P & JSX.IntrinsicAttributes) => {
      return (
        <Prefetcher reactQueryHooks={reactQueryHooks}>
          <Component {...props} />
        </Prefetcher>
      );
    };
  };
};
