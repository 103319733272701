import { z } from 'zod';
import { oldCountrySchema } from '~/root/domain';
import { customerSchema, staffToCustomerSchemaDiff } from './user';

export interface LoginRequest {
  email: string;
  password: string;
  remember: boolean;
}

const loginCustomerSchema = customerSchema.omit({ token: true, country: true }).extend({
  country: oldCountrySchema.nullable(),
});
const loginStaffSchema = loginCustomerSchema.extend(staffToCustomerSchemaDiff);

const loginUserSchema = loginCustomerSchema.or(loginStaffSchema);

export const loginSchema = z
  .object({
    user: loginUserSchema,
    token: z.string(),
  })
  .transform(({ user, token }) => ({ ...user, token }));

export interface SignupRequest {
  fullName: string;
  email: string;
  password: string;
  receiveOffers: boolean;
}

export const signupSchema = z.object({
  token: z.string().optional(),
  redirectUrl: z.string().optional(),
});
