import { ReactNode } from 'react';
import { z } from 'zod';

export type Value = string | number;
export type MultipleValue = Value[];

export type FilterMap<T extends string> = Record<T, Value | undefined>;

export type FilterUpdater<T extends string> = (filterMap: FilterMap<T>) => FilterMap<T>;

export type OnFilterChange<T extends string> = (
  name: T,
  value: Value | MultipleValue | undefined,
) => void;

export type TableFilterOption = {
  name: string;
  value: string | number;
};

export type TableFilters<K extends string> = Record<K, Record<number, string>>;

export type TransformedTableFilters<T extends string> = Record<T, TableFilterOption[]>;

export const metadataSchema = z.object({
  hasMore: z.boolean(),
  next: z.number().nullable(),
  pages: z.number(),
  prev: z.number().nullable(),
  results: z.number(),
  total: z.number(),
});

export type Metadata = z.infer<typeof metadataSchema>;

export type TableData<T> = {
  items: T[];
  metadata: Metadata;
};

export const tableDataSchema = <T extends z.ZodRawShape>(tableItemSchema: z.ZodObject<T>) =>
  z.object({
    items: z.array(tableItemSchema),
    metadata: metadataSchema,
  });

export const dateRangeFilterSchema = z.object({
  'filter[from]': z.string().optional().catch(undefined),
  'filter[to]': z.string().optional().catch(undefined),
});

export type SortOrder = 'asc' | 'desc';

export type Sort<K> = {
  option: K;
  order: SortOrder;
};

export type SetQueryParams<T extends GenericItem, K extends string> = (
  queryParams: SearchParamsMap<T, K>,
) => SearchParamsMap<T, K>;

export type SearchParamsMap<T extends GenericItem = GenericItem, K extends string = string> = {
  limit?: number;
  page?: number;
  sort?: Sort<keyof T>;
  filter: FilterMap<K>;
};

export type FlatSearchParams = Record<string, string>;

export type GenericItem = {
  id: number | string;
};

export type CellRendererParams<T, K extends keyof T> = {
  rowData: T;
  cellData: T[K];
};

export type Column<T extends GenericItem, K extends keyof T> = {
  key: K;
  name: string;
  cellRenderer?: (params: CellRendererParams<T, K>) => ReactNode;
  getClassName?: (params: CellRendererParams<T, K>) => string;
  getCellClassName?: (params: CellRendererParams<T, K>) => string;
  getCellValue?: (cellData: T[K]) => Value | null;
  headerClassName?: string;
  width?: `minmax(${number}px, ${number}fr)` | `minmax(min-content, ${number}fr)` | `${number}px`;
};

export type AnyColumn<T extends GenericItem> = {
  [P in keyof T]: Column<T, P>;
}[keyof T];

export type TableConfig<T extends GenericItem> = {
  columns: AnyColumn<T>[];
  getCellClassName?: (rowData: T) => string;
  sortable: (keyof T)[];
  disableScrollOnPageSwitch?: boolean;
};

export type FrontendTableConfig<T extends GenericItem> = TableConfig<T> & {
  searchable: (keyof T)[];
};

export type TableView<T extends GenericItem> = {
  tableConfig: TableConfig<T>;
  items: T[];
  totalPages: number;
  /**
   * Total number of results
   */
  total: number;
  results: number;
  page: number;
  onPageChange: (page: number) => void;
  sort: Sort<keyof T> | null;
  onSort: (key: keyof T) => void;
  isFetching?: boolean;
};
