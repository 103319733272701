import { ConfirmModalShell, ModalContentProps, Toast } from '~/common/components';
import { CustomField } from './domain';
import { useDeleteCustomField } from './hooks';

type DeletePaymentMethodProps = ModalContentProps & {
  field: CustomField;
};

export const DeleteCustomFieldModal = ({ onClose, field }: DeletePaymentMethodProps) => {
  const deleteCustomField = useDeleteCustomField();

  const submit = () => {
    deleteCustomField
      .mutateAsync(field.id)
      .then(() => Toast.success({ message: 'Custom field was successfully deleted' }))
      .then(onClose);
  };

  return (
    <ConfirmModalShell
      title="Remove this custom field?"
      description="The custom field will be removed from all spaces. If you choose to delete it, all the associated information will also be erased."
      submitText="Delete"
      loading={deleteCustomField.isLoading}
      onConfirm={submit}
      onClose={onClose}
    />
  );
};
