import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import { ENV, GTM_TRACKING_ID, GA_TRACKING_ID, GA_STAGING_TRACKING_ID } from '~/env';

export const services = {
  init: () => {
    if (ENV.PRODUCTION) {
      GTM_TRACKING_ID && TagManager.initialize({ gtmId: GTM_TRACKING_ID });
      GA_TRACKING_ID && ReactGA.initialize(GA_TRACKING_ID);
    } else {
      process.env.NODE_ENV !== 'development' &&
        GA_STAGING_TRACKING_ID &&
        ReactGA.initialize(GA_STAGING_TRACKING_ID);
    }

    /**
     * React Google Optimize gtag function fix
     * https://github.com/hudovisk/react-optimize/issues/2
     */
    if (typeof window.gtag === 'undefined') {
      window.gtag = function () {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer?.push(arguments);
      };
    }
  },
};
