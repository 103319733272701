import { FormErrors, SetErrors } from 'formoid';
import { UnknownRecord } from 'formoid/lib/Record';
import { decodeBackendErrors } from '~/common/utils';

export * from './messages';
export * as customValidator from './validator';

export const propagateBackendValidationErrors = <T extends UnknownRecord>({
  error,
  formErrors,
  setErrors,
}: {
  error: unknown;
  formErrors: FormErrors<T>;
  setErrors: SetErrors<T>;
}): void => {
  const parsedErrors = decodeBackendErrors(error);

  if (!parsedErrors) {
    throw error;
  }

  for (const key in formErrors) {
    setErrors(key as keyof T, parsedErrors[key] || null);
  }
};

// https://gist.github.com/wojtekmaj/fe811af47fad12a7265b6f7df1017c83
const findScrollContainer = <T extends Element>(element: T) => {
  let parent = element.parentElement;
  while (parent) {
    const { overflow } = window.getComputedStyle(parent);
    if (overflow.split(' ').every((o) => o === 'auto' || o === 'scroll')) {
      return parent;
    }
    parent = parent.parentElement;
  }

  return document.documentElement;
};

export const scrollToError = (scrollTop = 64) => {
  setTimeout(() => {
    const error = document.querySelector('[aria-label=form-error]');
    if (!error) {
      return;
    }

    const { top, left } = error.getBoundingClientRect();
    const scrollContainer = findScrollContainer(error);
    scrollContainer.scrollTo({
      top: top + scrollContainer.scrollTop - scrollTop,
      left,
      behavior: 'smooth',
    });
  });
};
