import cn from 'classnames';
import tfCuttyLogo from '~/images/tf-logo-cutty.svg';

interface ThemeLoaderProps {
  modal?: boolean;
  animated?: boolean;
  className?: string;
  modalClassName?: string;
}

export const ThemeLoader = ({
  modal = false,
  animated = true,
  className,
  modalClassName,
}: ThemeLoaderProps) => {
  const Loader = (
    <div
      className={cn('theme-loader theme-loader__container f-center', className, {
        animated,
      })}
    >
      <img src={tfCuttyLogo} alt="" />
    </div>
  );

  if (modal) {
    return <div className={cn('theme-loader-modal f-center', modalClassName)}>{Loader}</div>;
  }

  return Loader;
};
