import { ENV } from '~/env';

/**
 * For more details:
 * https://help.hotjar.com/hc/en-us/articles/115011867948-How-to-Use-JavaScript-to-Trigger-Heatmaps
 *
 * Usage: hotjar('trigger', '[event name]')
 */
export const hotjar = (...args: string[]) => {
  if (ENV.PRODUCTION && typeof window.hj === 'function') {
    window.hj(...args);
  }
};
