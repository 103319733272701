import { createSelector } from 'reselect';

export const uploadedFilesSelector = (state) => state.fileUpload.orderFiles;

export const uploadedStyleFileSelector = (state) => state.fileUpload.styleFile;

export const uploadedQuoteFileSelector = (state) => state.fileUpload.quoteFile;

export const uploadedStylePreferencesFileSelector = (state) =>
  state.fileUpload.stylePreferencesFile;

export const ownFilesCheckboxSelector = (state) => state.fileUpload.ownFiles;

export const isUploadFilesSectionPassed = createSelector(
  uploadedFilesSelector,
  ownFilesCheckboxSelector,
  (uploadedFiles, checkbox) => {
    if (checkbox) {
      return true;
    }
    let uploadedFilesNumber = 0;
    Object.keys(uploadedFiles).forEach((key) => {
      if (uploadedFiles[key].savedName !== '') {
        uploadedFilesNumber += 1;
      }
    });
    return uploadedFilesNumber > 0;
  },
);

export const validFilesSelector = createSelector(
  uploadedFilesSelector,
  uploadedStyleFileSelector,
  (files, styleFile) => {
    const fileNames = Object.keys(files)
      .map((key) => {
        const file = files[key];

        if (file.savedName) {
          return {
            name: file.savedName,
            uuid: file.uuid,
            type: file.type,
          };
        }
        return null;
      })
      .filter((file) => file !== null);

    if (styleFile.savedName) {
      fileNames.push({
        name: styleFile.savedName,
        uuid: styleFile.uuid,
        type: styleFile.type,
      });
    }

    return fileNames;
  },
);

export const quoteFilesHaveErrors = createSelector(
  uploadedQuoteFileSelector,
  (files) => !!Object.keys(files).find((file) => files[file].hasError),
);

export const stylePreferencesFilesHaveErrors = createSelector(
  uploadedStylePreferencesFileSelector,
  (files) => !!Object.keys(files).find((file) => files[file].hasError),
);
