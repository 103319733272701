type Props = {
  color?: string;
  width?: number;
  height?: number;
};
const defaultProps = {
  color: '#fff',
  width: 17,
  height: 16,
};

const SendIcon = ({ color, width, height }: Props) => (
  <svg width={width} height={height} viewBox="0 0 17 16">
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h20v20H-1z" />
      <path
        fill={color}
        d="M1.833 15l14.542-6.233a.834.834 0 000-1.534L1.833 1a.827.827 0 00-1.158.758L.667 5.6c0 .417.308.775.725.825L13.167 8 1.392 9.567a.842.842 0 00-.725.833l.008 3.842c0 .591.608 1 1.158.758z"
      />
    </g>
  </svg>
);

SendIcon.defaultProps = defaultProps;
export default SendIcon;
