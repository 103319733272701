import cx from 'classnames';
import { MouseEventHandler, useEffect, useRef } from 'react';
import Icon from '~/components/icons';
import { slidesNewness } from '../utils';

type Props = {
  id: number;
  index: number;
  resolved: boolean | null | undefined;
  slideId: number;
  position: {
    top: string;
    left: string;
  };
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const SlideAnnotation = ({ id, index, resolved, slideId, position, onClick }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  // pending annotation has negative id, we click on it here to show popover
  // after it's been created
  useEffect(() => {
    if (id < 0) {
      buttonRef.current?.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commented = slidesNewness.annotations[slideId]?.[id];

  const renderIcon = () => {
    if (commented) {
      return <Icon icon="comment-alt-edit" fill="#fff" width={14} height={14} />;
    }

    if (resolved) {
      return <Icon icon="check" fill="#fff" />;
    }

    return index;
  };

  return (
    <div className="annotation annotation__container relative" style={position}>
      <button
        ref={buttonRef}
        type="button"
        className={cx('annotation__circle bb-32', {
          resolved,
          commented,
        })}
        onClick={onClick}
      >
        {renderIcon()}
      </button>
    </div>
  );
};

export default SlideAnnotation;
