type ClassNamesRecord = Record<string, string>;

export const getCSSTransitionClassNames = (styles: ClassNamesRecord) => {
  const validClassNames: ClassNamesRecord = {};
  const CSSTransitionClassNames = [
    'appear',
    'appearActive',
    'appearDone',
    'enter',
    'enterActive',
    'enterDone',
    'exit',
    'exitActive',
    'exitDone',
  ];
  for (const key in styles) {
    if (CSSTransitionClassNames.includes(key)) {
      validClassNames[key] = styles[key];
    }
  }
  return validClassNames;
};
