import { cx } from '~/common/utils';
import { useCurrentUser } from '../Auth';
import { AuthenticatedNavigation } from './AuthenticatedNavigation';
import { withNavigation } from './context';

import { NavigationProps } from './types';
import { UnauthenticatedNavigation } from './UnauthenticatedNavigation';

export const Navigation = withNavigation(({ className }: NavigationProps) => {
  const user = useCurrentUser();

  return (
    <div className={cx('flex flex-col gap-1 lg:gap-2', className)}>
      {user ? <AuthenticatedNavigation /> : <UnauthenticatedNavigation />}
    </div>
  );
});
