import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FieldProps } from 'formoid';
import { ChangeEventHandler, ComponentProps, forwardRef, useState } from 'react';
import { Overwrite } from '~/common/utils';
import { IconButton } from '../IconContainers';
import { Input } from './Input';

type DefaultProps = Omit<ComponentProps<typeof Input>, 'onChange' | 'value'>;
type Props = DefaultProps & Overwrite<FieldProps<string>, { value: string | null }>;

export const PasswordField = forwardRef<HTMLDivElement, Props>(
  ({ errors, onChange, touched, value, ...props }, ref) => {
    const [type, setType] = useState('password');

    const toggleType = () => setType((type) => (type === 'password' ? 'text' : 'password'));

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      return onChange(event.currentTarget.value);
    };

    return (
      <Input
        {...props}
        ref={ref}
        onChange={handleChange}
        value={value ?? ''}
        error={!!errors}
        errors={errors}
        type={type}
      >
        <IconButton
          className="text-text-300"
          icon={type === 'password' ? faEyeSlash : faEye}
          tabIndex={-1}
          onClick={toggleType}
        />
      </Input>
    );
  },
);
