import { ReactNode } from 'react';

interface ItemProps {
  icon: ReactNode;
  label: ReactNode;
  description: string;
}

export const Item = ({ icon, label, description }: ItemProps) => {
  return (
    <div className="flex gap-1 py-1">
      <div>{icon}</div>
      <div>
        <div className="mb-1 text-text-500 font-brand-l3">{label}</div>
        <p className="text-text-400 font-brand-b3">{description}</p>
      </div>
    </div>
  );
};
