import { z } from 'zod';

// TODO: Rename to "creditsPackageSchema"

export const creditPackageSchema = z.object({
  id: z.number(),
  name: z.enum(['Bronze', 'Silver', 'Gold']),
  amount: z.number(),
  credits: z.number(),
  discount: z.object({ amount: z.number(), percentage: z.number() }),
  color: z.enum(['silver', 'gold', 'bronze']),
  pros: z.array(z.string()),
});

export const orderCreditPackageSchema = creditPackageSchema
  .omit({ color: true, pros: true })
  .extend({ discount: z.number() });

// TODO: Rename to "CreditsPackage"

export type CreditPackage = z.infer<typeof creditPackageSchema>;

export type OrderCreditPackage = z.infer<typeof orderCreditPackageSchema>;
