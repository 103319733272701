import { cx } from '~/common/utils';
import { FileUploadPlaceholder, UploadFile } from './FilesUpload';
import { FileUpload, FileUploadProps } from './FileUpload';

export const FileUploadField = (
  props: Omit<FileUploadProps, 'itemRenderer' | 'placeholderRenderer'>,
) => {
  return (
    <FileUpload
      {...props}
      itemRenderer={(props) => (
        <UploadFile className="h-7 rounded-md" {...props} name={props.file.name} />
      )}
      placeholderRenderer={({ draggedOver, error }) => (
        <FileUploadPlaceholder
          text="Choose a file"
          className={cx({
            'border-secondary-300': draggedOver,
            'bg-error-100 border-error-300': error,
          })}
        />
      )}
    />
  );
};
