import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { otherExplain } from '~/images/OtherExplain';

const otherExplainedContent = [
  {
    title: 'Media Posts',
    given: 'You need a graphic or illustration to support your post, article or social media ads:',
    elect: [
      'The graphic can be for a single post or a carousel (a set of pictures, for example, to showcase a product list)',
      'We will create graphics to match your branding or a style of your choice.',
    ],
    image: otherExplain.otherExplain1,
  },
  {
    title: 'Infographics',
    given:
      'You have a data or a storyline that you want to be presented in a creative, visual way:',
    elect: [
      'Infographic can be used in newsletters, reports, or anywhere on the web.',
      'We will create the infographic to match your style of choice and to complement the storyline.',
    ],
    image: otherExplain.otherExplain2,
  },
  {
    title: 'Icons',
    given:
      'if you need a set of custom icons that match your CVI or content, to be used in presentations or other projects.',
    image: otherExplain.otherExplain3,
  },
  {
    title: 'Logos',
    elect: [
      'You want a special version of an existing logo. For example, for a special event like a company anniversary.',
      'You want to redesign an existing logo of your organization.',
      'You need a new logo for your organization.',
    ],
    image: otherExplain.otherExplain4,
    note: 'We can only work on the design of the logo. We do not currently provide support in developing your brand identity',
  },
];

export const OtherExplainedModal = () => (
  <div className="oem__container">
    <h2 className="oem__title text-center">Our Custom Design Services Explained</h2>
    {otherExplainedContent.map(({ title, given, image, elect = [], note }) => (
      <li className="oem__block flex-center" key={title}>
        <div className="oem__content flex-grow-1">
          <h3 className="oem__heading">{title}</h3>
          <p className="oem__bait">This treatment is perfect for you if…</p>
          {given && <p className="oem__given">{given}</p>}
          {Boolean(elect.length) && (
            <ul className="oem__list">
              {elect.map((text) => (
                <li key={text} className="oem__item relative">
                  <FontAwesomeIcon className="oem__icon" icon={faCheckCircle} />
                  {text}
                </li>
              ))}
            </ul>
          )}
          {note && <p className="oem__note">{note}</p>}
        </div>
        <img className="oem__image" src={image} alt={title} />
      </li>
    ))}
  </div>
);
