import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from '~/redux/store';
import { generalTheme } from '~/utils/theme';
import { startTime } from '../debug';
import { queryClient } from '../queryClient';
import { posthogInit } from '../services/posthog';
import { Root } from './Root';

// when editing tailwind, it's css will be regenerated, so by separating css
// files we won't need to re-compile all css
import '~/styles/tailwind-pre.scss';
import '~/styles/index.scss';
import '~/styles/tailwind-post.scss';

// For testing purposes only
startTime;

posthogInit();

export const App = () => (
  <ErrorBoundary fallback={() => <h2>Fallback</h2>}>
    <StylesProvider injectFirst>
      <ThemeProvider theme={generalTheme}>
        <Provider store={store}>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools />
              <Root />
            </QueryClientProvider>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  </ErrorBoundary>
);
