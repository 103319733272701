import amex from './amex.svg';
import invoice from './invoice.svg';
import mastercard from './mastercard.svg';
import unionpay from './unionpay.svg';
import visa from './visa.svg';

const cardBrandToImg = (brand: string) => {
  switch (brand) {
    case 'american express':
      return amex;
    case 'mastercard':
      return mastercard;
    case 'visa':
      return visa;
    case 'unionpay':
      return unionpay;
    default:
      return '';
  }
};

export { amex, cardBrandToImg, invoice, mastercard, unionpay, visa };
