import { Button } from '~/common/components';
import { routes } from '~/constants';
import images from '~/images/OnlineSubscriptionsPopup';
import checkmarkIcon from '~/images/yes-checkmark.svg';

export const OnlineSubscriptionsPopup = () => {
  const handleClick = () => {
    // set hash to prevent show this popup multiple times
    localStorage.setItem('onlineSubscriptionsPopup', 'hash');
    window.location.href = routes.public.dedicated_team;
  };

  return (
    <div className="online-subscriptions__container os__container">
      <div className="os__content">
        <h2 className="os__title">
          Experience the magic of <span className="gradient">a Dedicated Design Team</span>
        </h2>

        <h3 className="os__subtitle">
          You can now sign up online and get your very own design team. Get started today for just
          $899 per month
        </h3>

        <div className="os__benefits">
          <ul>
            {onlineSubscriptionsPopup.map((listItem) => (
              <li key={listItem}>
                <img src={checkmarkIcon} alt="checkmark" />
                <p>{listItem}</p>
              </li>
            ))}
          </ul>
          <img src={images.teamImage} alt="team" />
        </div>

        <div className="os__partners">
          {Object.entries(images.logos).map(([logoName, logoValue]) => (
            <img key={logoName} src={logoValue} alt={logoName} />
          ))}
        </div>

        <Button className="os__button bold" size="m" color="secondary" onClick={handleClick}>
          Find out more
        </Button>
      </div>
    </div>
  );
};

export const onlineSubscriptionsPopup = [
  'Your subscription includes approx 50 slides per month (a saving of 32%)',
  'You’ll always work with the same project manager and design team',
  'Join many of the world’s leading companies and accelerate your workflow',
];
