import { z } from 'zod';
import { UUID } from '~/common/utils';
import { DateFromIsoString } from './date';

export const UploadedFileId = z.number().int().positive().or(UUID);

export type UploadedFileId = z.infer<typeof UploadedFileId>;

export const uploadedFileSchema = z
  .object({
    id: UploadedFileId,
    name: z.string(),
    link: z.string().url(),
    date: DateFromIsoString,
    timestamp: DateFromIsoString.optional(),
  })
  .transform(({ date, timestamp, ...file }) => ({
    ...file,
    date: date || timestamp,
  }));

export type UploadedFile = z.infer<typeof uploadedFileSchema>;
