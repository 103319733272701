import CircularProgress from '@material-ui/core/CircularProgress';
import './_spinner.scss';

type SpinnerProps = {
  className?: string;
  size?: number;
};

export const Spinner = ({ className, size = 40 }: SpinnerProps) => (
  <CircularProgress className={className} color="primary" size={size} />
);
