import ModalParser from '~/components/Modal/ModalParser';
import { contentWithLineBreaker } from '~/pages/Order-now/utils';
import { uniqueId } from '~/utils/helpers';

import HolidayPopupImage from './images/holidayPopup.jpg';
import './_popup.scss';

export const HolidayPopup = ({ title, content }: { title?: string; content?: string }) => (
  <div className="holiday__container">
    {title && (
      <h2 className="holiday__title">
        <ModalParser source={title} />
      </h2>
    )}
    <img src={HolidayPopupImage} alt="Holiday Popup" className="holiday__image" />
    <section className="holiday__content">
      {contentWithLineBreaker(content).map((text) => (
        <p key={uniqueId()}>
          <ModalParser source={text} />
        </p>
      ))}
    </section>
  </div>
);
