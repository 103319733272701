import { z } from 'zod';
import { paginationSchema } from '~/common/hooks';
import { dateRangeFilterSchema, tableDataSchema } from '~/common/kits/table';
import {
  CardPaymentMethod,
  InvoicePaymentMethod,
  dateFromUnixEpochSchema,
  paymentMethodSchema,
  teamRoleSchema,
} from '~/root/domain';
import { billingAddressSchema } from './billingAddress';
import { RetainerProduct, TechpackProduct, subscriptionProductSchema } from './subscriptionPlan';

export const subscriptionStatusSchema = z.enum([
  'Active',
  'Trialing',
  'Incomplete',
  'Imcomplete & Expired',
  'Past due',
  'Unpaid',
  'Cancelled',
  'Scheduled',
]);

export type SubscriptionStatus = z.infer<typeof subscriptionStatusSchema>;

export const unpaidOrderSchema = z.object({
  id: z.number(),
  price: z.number(),
  user: z.string().nullable(),
  email: z.string(),
  role: teamRoleSchema,
});

export type UnpaidOrder = z.infer<typeof unpaidOrderSchema>;

export const subscriptionSchema = z
  .object({
    id: z.number(),
    type: z.enum(['regular', 'retainer', 'corporate', 'common']),
    renewalPeriod: z.number(),
    products: z.array(subscriptionProductSchema),
    startsAt: z.string().datetime().nullable(),
    renewsAt: z.string().datetime().nullable(),
    endsAt: z.string().datetime().nullable(),
    topUp: z.boolean().nullable(),
    unpaidOrders: z.array(unpaidOrderSchema).nullable(),
    status: subscriptionStatusSchema.nullable(),
    creditBalance: z.number(),
    /** Specifying wheather customer can cancel his current subscription the platform
     */
    isCancellable: z.boolean(),
  })
  .transform(({ products, ...subscription }) => {
    const productsMap = {
      retainer: products.find((p): p is RetainerProduct => p.plan.type === 'retainer'),
      techpack: products.find((p): p is TechpackProduct => p.plan.type === 'techpack'),
      all: products,
    };

    productsMap.all = products;
    return { ...subscription, products: productsMap };
  });

export type Subscription = z.infer<typeof subscriptionSchema>;

export const companySchema = z.object({
  name: z.string().optional(),
  vatNumber: z.string().optional(),
  billingContact: z.string().optional(),
  logoUrl: z.string().optional(),
});

export type Company = z.infer<typeof companySchema>;

export const topUpSchema = z.object({
  id: z.number(),
  name: z.string(),
  method: z.number().nullable(),
  topUpCreditBelow: z.number().nullable(),
});

export type TopUp = z.infer<typeof topUpSchema>;

export const paymentsHistoryItemSchema = z.object({
  id: z.number(),
  description: z.string().nullable(),
  timestamp: dateFromUnixEpochSchema.nullable(),
  amount: z.string().nullable(), // with sign
  currency: z.string().nullable(),
  status: z.enum(['success', 'pending', 'error']),
  paymentMethod: z.string().nullable(), // ****-.. or Invoice
  invoiceUrl: z.string().nullable(),
});

export type PaymentsHistoryItem = z.infer<typeof paymentsHistoryItemSchema>;

export const paymentsHistorySchema = tableDataSchema(paymentsHistoryItemSchema);

export type PaymentsHistory = z.infer<typeof paymentsHistorySchema>;

export const paymentsHistoryParamSchema = paginationSchema(8).merge(dateRangeFilterSchema);

export type PaymentsHistoryParams = z.infer<typeof paymentsHistoryParamSchema>;

const statsItemSchema = z
  .object({
    thisMonth: z.number(),
    lastMonth: z.number(),
  })
  .transform((stats) => ({ ...stats, diff: stats.thisMonth - stats.lastMonth }));

export type BillingStatsItem = z.infer<typeof statsItemSchema>;

export const billingSchema = z
  .object({
    company: companySchema.nullable(),
    billingAddress: billingAddressSchema,
    suggestVat: z.boolean(),
    vatPercent: z.number(),
    creditBalance: z.number(),
    subscription: subscriptionSchema.nullable(),
    paymentMethods: z.array(paymentMethodSchema),
    stats: z.object({
      orders: z.object({
        created: statsItemSchema,
        completed: statsItemSchema,
      }),
    }),
    topUp: topUpSchema.nullable(),
    invoiceable: z.boolean(), // Defines can user create invoice payment method or not
    trusted: z.boolean(),
  })
  .transform((billing) => ({
    ...billing,
    invoicePaymentMethod: billing.paymentMethods.find(
      (paymentMethod): paymentMethod is InvoicePaymentMethod => paymentMethod.type === 'invoice',
    ),
    cardPaymentMethods: billing.paymentMethods.filter(
      (paymentMethod): paymentMethod is CardPaymentMethod => paymentMethod.type === 'card',
    ),
    defaultPaymentMethod: billing.paymentMethods.find((method) => method.default),
  }));

export type Billing = z.infer<typeof billingSchema>;

export type TopupSubscriptionPayload = {
  topUp: boolean;
};

export type RenewTopupPayload = TopupSubscriptionPayload & {
  period: number;
};
