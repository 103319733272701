import { useForm } from 'formoid';
import { FormEvent } from 'react';
import { InputField, SmallPagePaper, Button, Link } from '~/common/components';
import { customValidator, propagateBackendValidationErrors } from '~/common/utils';
import { routes } from '~/constants';
import { useRequestResetLink } from './hooks';

const initialValues = {
  email: '',
};

export const RequestResetLink = () => {
  const { mutateAsync } = useRequestResetLink();

  const { fieldProps, handleSubmit, setErrors, isSubmitting, errors } = useForm({
    initialValues,
    validationStrategy: 'onBlur',
    validators: () => ({
      email: customValidator.email(),
    }),
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit((values) => {
      return mutateAsync(values.email).catch((error) => {
        propagateBackendValidationErrors({ error, setErrors, formErrors: errors });
      });
    });
  };

  return (
    <SmallPagePaper>
      <h2 className="font-brand-h7b mb-1">Reset password</h2>
      <p className="text-text-500 mb-3">
        Enter your email address and we'll send you a link to reset password.
      </p>

      <form className="space-y-3" onSubmit={submit}>
        <InputField {...fieldProps('email')} type="text" placeholder="Email" />

        <Button className="w-full" size="m" color="secondary" loading={isSubmitting} type="submit">
          Continue
        </Button>

        <Link to={routes.login}>Back to Log in</Link>
      </form>
    </SmallPagePaper>
  );
};
