function typeIs(val) {
  if (val === undefined) return 'undefined';
  if (val === null) return 'null';

  const type = typeof val;
  if (type === 'boolean') return 'boolean';
  if (type === 'string') return 'string';
  if (type === 'number') return 'number';
  if (type === 'function') return 'function';

  if (isArray(val)) return 'array';
  if (isArguments(val)) return 'arguments';
  if (isDate(val)) return 'date';
  if (isError(val)) return 'error';
  if (isRegexp(val)) return 'regexp';
  if (isObject(val)) return 'object';

  // other
  return type.slice(8, -1).toLowerCase().replace(/\s/g, '');
}

function isArray(val) {
  if (Array.isArray) return Array.isArray(val);
  return val instanceof Array;
}

function isString(str) {
  if (str && typeof str.valueOf() === 'string') {
    return true;
  }
  return false;
}

function isError(val) {
  return (
    val instanceof Error ||
    (typeof val.message === 'string' &&
      val.constructor &&
      typeof val.constructor.stackTraceLimit === 'number')
  );
}

function isDate(val) {
  if (val instanceof Date) return true;
  return (
    typeof val.toDateString === 'function' &&
    typeof val.getDate === 'function' &&
    typeof val.setDate === 'function'
  );
}

function isRegexp(val) {
  if (val instanceof RegExp) return true;
  return (
    typeof val.flags === 'string' &&
    typeof val.ignoreCase === 'boolean' &&
    typeof val.multiline === 'boolean' &&
    typeof val.global === 'boolean'
  );
}

function isArguments(val) {
  try {
    if (typeof val.length === 'number' && typeof val.callee === 'function') {
      return true;
    }
  } catch (err) {
    if (err.message.indexOf('callee') !== -1) {
      return true;
    }
  }
  return false;
}

function isObject(val) {
  return val != null && typeof val === 'object' && Array.isArray(val) === false;
}

function isEmpty(obj) {
  return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
}

const hasOwn = Object.prototype.hasOwnProperty;

function forIn(obj, fn, thisArg) {
  for (const key in obj) {
    if (fn.call(thisArg, obj[key], key, obj) === false) {
      break;
    }
  }
}

function forOwn(obj, fn, thisArg) {
  forIn(obj, (val, key) => {
    if (hasOwn.call(obj, key)) {
      return fn.call(thisArg, obj[key], key, obj);
    }
  });
}

function arrMap(arr, fn, thisArg) {
  if (arr == null) return [];
  fn = makeIterator(fn, thisArg);

  const len = arr.length;
  const res = new Array(len);

  for (let i = 0; i < len; i += 1) {
    res[i] = fn(arr[i], i, arr);
  }
  return res;
}

function makeIterator(target, ctx) {
  switch (typeIs(target)) {
    case 'undefined':
    case 'null':
      return (val) => val;
    case 'function':
      return ctx ? (...args) => target.call(ctx, ...args) : target;
    case 'regexp':
      return (val) => target.test(val);
    case 'object':
      return (val) => deepMatches(val, target);
    case 'boolean':
      return (val) => target === val;
    case 'string':
    case 'number':
    default: {
      return (obj) => obj[target];
    }
  }
}

function has(array, value, fn) {
  return array.some((ele) => !fn(ele, value));
}

function containsMatch(array, value) {
  return has(array, value, deepMatches);
}

function matchArray(array, value) {
  return has(array, value, containsMatch);
}

function matchObject(obj, value) {
  return has(Object.keys(value), obj, (key) => deepMatches(obj[key], value[key]));
}

/**
 * Recursively compare objects
 */

function deepMatches(val, target) {
  if (Array.isArray(val) && Array.isArray(target)) {
    return matchArray(val, target);
  }
  if (typeIs(target) === 'object') {
    return matchObject(val, target);
  }
  return val === target;
}

function map(collection, fn, thisArg) {
  if (!Array.isArray(collection)) {
    const iterator = makeIterator(fn, thisArg);
    const result = [];

    forOwn(collection, (value, key) => {
      result.push(iterator(value, key, collection));
    });
    return result;
  }
  return arrMap(collection, fn, thisArg);
}

function clamp(min, value, max) {
  return Math.min(max, Math.max(min, value));
}

const uniqueId = (function () {
  let index = 0;
  const initDate = new Date();

  return (prefix) => {
    const createDate = new Date() - initDate;
    const randomId = Math.floor(Math.random() * 1000);
    const str = `${index}-${createDate}-${randomId}`;
    const resultId = (typeof prefix === 'string' ? `${prefix}-` : '') + str;
    index += 1;

    return resultId;
  };
})();

export {
  clamp,
  isEmpty,
  map,
  uniqueId,
  typeIs,
  isArray,
  isString,
  isError,
  isDate,
  isRegexp,
  isObject,
  isArguments,
};
