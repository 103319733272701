import { z } from 'zod';

const positionSchema = z.object({
  x: z.number(),
  y: z.number(),
});

export const annotationCommentSchema = z.object({
  id: z.number(),
  name: z.string(),
  initials: z.string(),
  comment: z.string(),
  timestamp: z.number(),
});

export const annotationSchema = z.object({
  id: z.number(),
  index: z.number(),
  pos: positionSchema,
  comments: z.array(annotationCommentSchema),
  resolved: z.boolean(),
  timestamp: z.number(),
});

export const slideWithAnnotationsSchema = z.object({
  id: z.number(),
  index: z.number(),
  annotations: z.array(annotationSchema),
  timestamp: z.number(),
});

const slideVersionSchema = z.object({
  id: z.number(),
  preview: z.string(),
  timestamp: z.number(),
  version: z.number(),
});

export const slideLatestVersionSchema = slideVersionSchema.extend({
  index: z.number(),
  versions: z.array(slideVersionSchema),
  updatedAt: z.number(),
});

export const slideAnnotationsSchema = z
  .object({
    slides: z.array(slideWithAnnotationsSchema),
    maxAnnotationIndex: z.number(),
  })
  .transform(({ slides: slideAnnotations, maxAnnotationIndex }) => ({
    slideAnnotations,
    maxAnnotationIndex,
  }));

export type SlideWithAnnotations = z.infer<
  typeof slideAnnotationsSchema
>['slideAnnotations'][number];

export type Annotation = z.infer<
  typeof slideAnnotationsSchema
>['slideAnnotations'][number]['annotations'][number];

export const slidesSchema = z
  .object({
    slides: z.array(slideLatestVersionSchema),
  })
  .transform(({ slides }) =>
    slides.map((slide) => ({
      ...slide,
      versions: slide.versions.map((version) => ({
        ...version,
        index: slide.index,
      })),
    })),
  );

export type Slides = z.infer<typeof slidesSchema>;

export type Slide = Slides[number];
