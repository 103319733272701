type Options = Record<string, boolean> & {
  expires?: Date | number | string;
};

export function setCookie(name: string, value: string, options: Options = {}) {
  const expires = options.expires;

  if (expires) {
    if (typeof expires === 'number') {
      const d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      options.expires = d;
    } else if (typeof expires !== 'string') {
      options.expires = expires.toUTCString();
    }
  }

  value = encodeURIComponent(value);

  let updatedCookie = name + '=' + value;

  for (const propName in options) {
    updatedCookie += '; ' + propName;
    const propValue = options[propName as keyof Options];
    if (propValue !== true) {
      updatedCookie += '=' + propValue;
    }
  }

  document.cookie = updatedCookie;
}
