export const CLICK_ON_STAR = 'CLICK_ON_STAR';
export const TOGGLE_PUBLISH_FEEDBACK = 'TOGGLE_PUBLISH_FEEDBACK';

export const FEEDBACK_INIT_REQUEST = 'FEEDBACK_INIT_REQUEST';
export const FEEDBACK_INIT_SUCCESS = 'FEEDBACK_INIT_SUCCESS';
export const FEEDBACK_INIT_FAILURE = 'FEEDBACK_INIT_FAILURE';

export const TRY_TO_SUBMIT_WITHOUT_FEEDBACK = 'TRY_TO_SUBMIT_WITHOUT_FEEDBACK';

export const SUBMIT_FEEDBACK_REQUEST = 'SUBMIT_FEEDBACK_REQUEST';
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS';
export const SUBMIT_FEEDBACK_FAILURE = 'SUBMIT_FEEDBACK_FAILURE';
