import { createStore } from 'zustand';

type User = {
  id: string;
  name: string;
  email: string | null;
  company: string | null;
  customerAccountId: number | null;
};

/**
 *
 * The purpose of this store is to reactively connect some global-scope
 * optional dependencies like sentry or openreplay to the logged in user,
 * which sits in react-land, without polluting react-land with optional deps */
export const userStore = createStore<User | null>()(() => null);

/**
 *
 * The purpose of this store is to reactively connect some global-scope
 * optional dependencies like posthog to location, because for some reason
 * there's no native API for that */
export const locationStore = createStore<Location>()(() => window.location);
