import { ComponentProps } from 'react';
import AnimateHeight from 'react-animate-height';

type Props = Omit<ComponentProps<typeof AnimateHeight>, 'height'> & {
  in: boolean;
};

export const Collapse = ({ in: show, ...props }: Props) => (
  <AnimateHeight {...props} duration={300} height={show ? 'auto' : 0} />
);
