import { faCalendarAlt, faClose } from '@fortawesome/pro-regular-svg-icons';
import { useMemo } from 'react';
import { DateRange, DateRangePicker, IconBox, Input, Popover } from '~/common/components';

import { createQueryState } from '~/common/hooks';
import { cx, formatYearMonthDay, isNonNullable } from '~/common/utils';
import { dateRangeFilterSchema } from '../types';

export const useDateRangeQueryState = createQueryState(dateRangeFilterSchema);

interface DateRangeFilterProps {
  className?: string;
}

export const DateRangeFilter = ({ className }: DateRangeFilterProps) => {
  const [params, setParams] = useDateRangeQueryState();

  const onDateRangeChange =
    (onClose: () => void) =>
    ({ start, end }: DateRange) => {
      setParams((prev) => ({
        ...prev,
        'filter[from]': formatYearMonthDay(start),
        'filter[to]': formatYearMonthDay(end),
      }));
      onClose();
    };

  const resetFilters: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();

    setParams((prev) => ({
      ...prev,
      'filter[from]': undefined,
      'filter[to]': undefined,
    }));
  };

  const dateRangeInputValue = useMemo(() => {
    const dateRangeInputDates = [params['filter[from]'], params['filter[to]']].filter(
      isNonNullable,
    );

    return dateRangeInputDates.length
      ? dateRangeInputDates
          .map((date) => (date as string).split('-').reverse().join('.'))
          .join(' - ')
      : '';
  }, [params]);

  return (
    <Popover
      placement="bottom-start"
      trigger={(props) => (
        <Input
          {...props}
          className={cx('w-[200px]', className)}
          value={dateRangeInputValue}
          size="s"
          readOnly
          placeholder="Choose period"
        >
          {dateRangeInputValue ? (
            <IconBox
              className="cursor-pointer hover:scale-110 text-other-600"
              icon={faClose}
              onClick={resetFilters}
            />
          ) : (
            <IconBox className="text-other-600" size="s" icon={faCalendarAlt} />
          )}
        </Input>
      )}
      content={({ onClose }) => (
        <DateRangePicker
          value={{
            start: params['filter[from]'] ? new Date(params['filter[from]'] as string) : new Date(),
            end: params['filter[to]'] ? new Date(params['filter[to]'] as string) : new Date(),
          }}
          onChange={onDateRangeChange(onClose)}
        />
      )}
    />
  );
};
