import { colors } from '~/utils/palette';
import type { IconType } from './types';
const defaultProps = {
  fill: colors.graysuit,
  width: 18,
  height: 14,
};

const IconCheck = ({ fill, width, height = width }: IconType) => (
  <svg width={width} height={height} viewBox="0 0 18 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.2L1.8 7 .4 8.4 6 14 18 2 16.6.6 6 11.2z"
      fill={fill}
    />
  </svg>
);

IconCheck.defaultProps = defaultProps;
export default IconCheck;
