import { Any } from '~/common/utils';
import { CommentFilesPopup } from '~/pages/My-profile/components/InnerOrderPage/components/CommentFilesPopup';
import { CommentModeModal } from '~/pages/My-profile/components/InnerOrderPage/components/CommentModeModal';
import { InnerOrderConfirmationPopup } from '~/pages/My-profile/components/InnerOrderPage/components/InnerOrderConfirmationPopup';
import { CouldSavePopup } from '~/pages/My-profile/components/OrdersPage/components/CouldSavePopup';
import { EnterpriseOfferModal } from '~/pages/My-profile/components/OrdersPage/components/EnterpriseOfferModal';
import { OnlineSubscriptionsPopup } from '~/pages/My-profile/components/OrdersPage/components/OnlineSubscriptionPopup';
import { HolidayPopup } from '~/pages/Order-now/components/InitPopup/HolidayPopup';
import { ReferralPopup } from '~/pages/Order-now/components/Referral-popup';
import { ModalTreatmentsType } from '~/pages/Order-now/components/Treatments/old/ModalTreatmentsType';
import {
  SecurityModal,
  ShareGooglePresentationModal,
} from '~/pages/Order-now/components/UploadFiles/old';
import { UpsellCreditPackages } from '~/pages/Order-now/components/Upsell-credit';
import { UpsellPopup } from '~/pages/Order-now/components/Upsell-popup';
import { OtherExplainedModal } from './OtherExplainedModal';
import { SubscriptionPlansVideo } from './SubscriptionPlansVideo';

const modalMap = {
  showTurnaroundUpsellPopup: UpsellPopup,
  securityModal: SecurityModal,
  shareGoogleSlidesPreesentation: ShareGooglePresentationModal,
  showCreditPackUpsellPopup: UpsellCreditPackages,
  showReferralPopup: ReferralPopup,
  showHolidayPopup: HolidayPopup,
  orderCommentFiles: CommentFilesPopup,
  commentModeModal: CommentModeModal,
  innerOrderConfirmationPopup: InnerOrderConfirmationPopup,
  onlineSubscriptionsPopup: OnlineSubscriptionsPopup,
  couldSavePopup: CouldSavePopup,
  modalTreatmentsType: ModalTreatmentsType,
  subscriptionPlansModal: SubscriptionPlansVideo,
  otherExplainedModal: OtherExplainedModal,
  enterpriseOfferModal: EnterpriseOfferModal,
} as const;

export const ModalContent = ({
  modal,
  ...props
}: { modal: keyof typeof modalMap } & Record<string, unknown>) => {
  const ModalContentComponent = modalMap[modal] as Any;

  if (ModalContentComponent) {
    return <ModalContentComponent {...props} />;
  }

  // eslint-disable-next-line no-console
  console.warn(`There is no mapping for "${modal}" name`);

  return null;
};
