import { v4 as uuidv4 } from 'uuid';
import { SERVER_URL } from '~/env';
import { axios } from '~/root';
import { tusUpload, percentage } from '~/utils/tusUpload';
import { setStyleFile, resetStyleFile } from '../order-now/data/data.actions';
import {
  ORDER_FILE_UPLOAD_REQUEST,
  ORDER_FILE_UPLOAD_SUCCESS,
  ORDER_FILE_UPLOAD_FAILURE,
  ORDER_FILE_UPLOAD_REMOVE,
  RESTORE_ORDER_FILES,
  ORDER_FILE_PROGRESS,
  RESET_ORDER_FILES,
  RESET_FILE_UPLOAD_STATE,
  STYLE_FILE_UPLOAD_REQUEST,
  STYLE_FILE_UPLOAD_SUCCESS,
  STYLE_FILE_UPLOAD_FAILURE,
  STYLE_FILE_UPLOAD_REMOVE,
  RESTORE_STYLE_FILE,
  STYLE_FILE_PROGRESS,
  STYLE_PREFERENCES_FILE_UPLOAD_REQUEST,
  STYLE_PREFERENCES_FILE_UPLOAD_SUCCESS,
  STYLE_PREFERENCES_FILE_UPLOAD_FAILURE,
  STYLE_PREFERENCES_FILE_UPLOAD_REMOVE,
  STYLE_PREFERENCES_FILE_UPLOAD_REMOVE_FAILED,
  STYLE_PREFERENCES_FILE_UPLOAD_DROP,
  STORE_ABORT_CONTROLLER,
  REMOVE_ABORT_CONTROLLER,
  STOP_FILE_UPLOAD,
} from './file-upload.types';

// ORDER FILE
const orderFileUploadRequest = (payload) => ({ type: ORDER_FILE_UPLOAD_REQUEST, payload });
const orderFileUploadSuccess = (payload) => ({ type: ORDER_FILE_UPLOAD_SUCCESS, payload });
const orderFileUploadFailure = (error) => ({ type: ORDER_FILE_UPLOAD_FAILURE, error });
const orderFileProgress = (payload) => ({ type: ORDER_FILE_PROGRESS, payload });

export const orderFileUploadRemove = (payload) => ({
  type: ORDER_FILE_UPLOAD_REMOVE,
  payload,
});

export const resetOrderFiles = () => ({ type: RESET_ORDER_FILES });

export const resetFileUploadState = () => ({ type: RESET_FILE_UPLOAD_STATE });

export const uploadCommentFile = (file) => (dispatch) => {
  const id = uuidv4();
  dispatch(orderFileUploadRequest({ file, id }));

  return tusUpload({
    file,
    tag: 'order_comment',
    onProgress: percentage((progress) => dispatch(orderFileProgress({ progress, id }))),
  })
    .then((uploadedFile) => {
      const file = {
        uuid: uploadedFile.id,
        name: uploadedFile.name,
      };
      dispatch(orderFileUploadSuccess({ file, id }));
      return file;
    })
    .catch((error) => {
      dispatch(orderFileUploadFailure({ error, id }));
      return Promise.reject(error);
    });
};

export const orderFileUpload = (file) => (dispatch) => {
  const id = uuidv4();
  dispatch(orderFileUploadRequest({ file, id }));

  return tusUpload({
    file,
    tag: 'customer_file',
    onProgress: percentage((progress) => dispatch(orderFileProgress({ progress, id }))),
  })
    .then((uploadedFile) => {
      const file = {
        uuid: uploadedFile.id,
        name: uploadedFile.name,
      };
      dispatch(orderFileUploadSuccess({ file, id }));
      return file;
    })
    .catch((error) => {
      dispatch(orderFileUploadFailure({ error, id }));
      return Promise.reject(error);
    });
};

// STYLE FILE
const styleFileUploadRequest = (payload) => ({ type: STYLE_FILE_UPLOAD_REQUEST, payload });
const styleFileUploadSuccess = (payload) => ({ type: STYLE_FILE_UPLOAD_SUCCESS, payload });
const styleFileUploadFailure = (error) => ({ type: STYLE_FILE_UPLOAD_FAILURE, error });
const styleFileProgress = (payload) => ({ type: STYLE_FILE_PROGRESS, payload });

export const styleFileUploadRemove = () => (dispatch) => {
  dispatch(resetStyleFile());
  dispatch({ type: STYLE_FILE_UPLOAD_REMOVE });
};

const storeAbortController = (abortController) => ({
  type: STORE_ABORT_CONTROLLER,
  payload: abortController,
});

const removeAbortController = () => ({ type: REMOVE_ABORT_CONTROLLER });

export const stopFileUpload = () => ({ type: STOP_FILE_UPLOAD });

export const styleFileUpload = (file) => (dispatch) => {
  const id = uuidv4();
  dispatch(styleFileUploadRequest({ file, id }));
  const controller = new AbortController();
  dispatch(storeAbortController(controller));

  return tusUpload({
    file,
    tag: 'customer_file',
    signal: controller.signal,
    onProgress: percentage((progress) => dispatch(styleFileProgress({ progress, id }))),
  })
    .then((uploadedFile) => {
      const file = {
        uuid: uploadedFile.id,
        name: uploadedFile.name,
      };
      dispatch(styleFileUploadSuccess({ file, id }));
      dispatch(removeAbortController());
      dispatch(setStyleFile(file));
      return file;
    })
    .catch((error) => {
      if (error instanceof DOMException && error.name === 'AbortError') {
        dispatch(removeAbortController());
        return;
      }
      dispatch(styleFileUploadFailure({ error, id }));
      return Promise.reject(error);
    });
};

export const restoreOrderFiles = (files) => ({ type: RESTORE_ORDER_FILES, payload: files });

export const restoreStyleFile = (file) => ({ type: RESTORE_STYLE_FILE, payload: file });

// STYLE PREFERENCES FILE UPLOAD

function stylePreferencesFileUploadRequest(payload) {
  return {
    type: STYLE_PREFERENCES_FILE_UPLOAD_REQUEST,
    payload,
  };
}

export const stylePreferencesFileUploadSuccess = (payload) => ({
  type: STYLE_PREFERENCES_FILE_UPLOAD_SUCCESS,
  payload,
});

function stylePreferencesFileUploadFailure(error) {
  return {
    type: STYLE_PREFERENCES_FILE_UPLOAD_FAILURE,
    error,
  };
}

export function stylePreferencesFileUploadRemove(file) {
  return {
    type: STYLE_PREFERENCES_FILE_UPLOAD_REMOVE,
    payload: {
      file,
    },
  };
}

export function stylePreferencesFileRemoveFailedFiles() {
  return {
    type: STYLE_PREFERENCES_FILE_UPLOAD_REMOVE_FAILED,
  };
}

export function stylePreferencesFileUploadDrop() {
  return {
    type: STYLE_PREFERENCES_FILE_UPLOAD_DROP,
  };
}

const STYLE_PREFERENCES_FILE_UPLOAD = `${SERVER_URL}/v1/customers/preferences/upload`;

// refactor this according to quoteFileUpload, styleFileUpload or orderFileUpload actions
// when style preferences will be released in customer area
export function stylePreferencesFileUpload(file) {
  return (dispatch) => {
    const { name } = file;

    dispatch(stylePreferencesFileUploadRequest({ file, name }));

    const data = new FormData();
    data.append('file', file);

    axios.post(STYLE_PREFERENCES_FILE_UPLOAD, data, { isJSON: false }).then(
      (response) => {
        dispatch(stylePreferencesFileUploadSuccess({ file: response.data.file, name }));
        dispatch(stylePreferencesFileRemoveFailedFiles());
      },
      (error) => {
        dispatch(stylePreferencesFileUploadFailure({ error, name }));
      },
    );
  };
}
