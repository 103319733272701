import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from '~/common/components';
import { routes } from '~/constants';

export const EnterpriseBanner = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'enterprise',
      eventAction: 'launch-banner',
      eventLabel: 'viewed',
    });
  }, []);

  const handleButtonClick = () => {
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'enterprise',
      eventAction: 'launch-banner',
      eventLabel: 'clicked',
    });
  };

  return (
    <div className="p-1">
      <p className="font-brand-b3 text-text-400 mb-2">
        See the impact of our efforts as we saved our clients over 100,000 work hours last year.
      </p>

      <Link
        size="m"
        newTab
        href={`${routes.public.enterprises}?utm_source=customer-area&utm_medium=banner&utm_campaign=popup`}
        onClick={handleButtonClick}
        className="w-fit"
        endIcon={faChevronRight}
      >
        Learn more
      </Link>
    </div>
  );
};
