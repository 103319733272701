import { Table, useFrontendTable } from '~/common/kits/table';
import { pluralize } from '~/common/utils';
import { UnpaidOrder } from '../../../../domain';
import { tableConfig } from './table-config';

interface UnpaidOrdersProps {
  unpaidOrders: UnpaidOrder[];
  className?: string;
}

export const UnpaidOrders = ({ unpaidOrders, className }: UnpaidOrdersProps) => {
  const unpaidOrdersAmount = unpaidOrders.length;

  const view = useFrontendTable({ data: unpaidOrders, tableConfig });

  if (!view) {
    return null;
  }

  return (
    <div className={className}>
      <p className="font-brand-b3 text-text-400 mt-2 mb-3">
        You have {unpaidOrdersAmount} order{pluralize(unpaidOrdersAmount)} that exceeds the total
        amount of credits on your account. Top up your subscription credits so we can proceed with
        it.
      </p>
      <Table {...view} />
    </div>
  );
};
