import { faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { useMemo } from 'react';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { cx } from '~/common/utils';
import { Spinner } from '~/components/loaders/Spinner';
import { PaymentsHistory } from '../domain';
import { getTableConfig } from './table-config';

type Props = {
  minified?: boolean;
  data?: PaymentsHistory;
  className?: string;
  containerClassName?: string;
  paginationClassName?: string;
};

export const PaymentsTable = ({
  minified = false,
  data,
  className,
  containerClassName,
  paginationClassName,
}: Props) => {
  const tableConfig = useMemo(() => getTableConfig(minified), [minified]);
  const view = useBackendTable({
    data,
    tableConfig,
    isFetching: false,
  });

  if (!data || !view) {
    return (
      <div
        className={cx(
          'w-full flex justify-center items-center',
          minified ? 'h-[184px]' : 'h-[472px]',
        )}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <Table
      {...view}
      totalPages={minified ? 1 : view.totalPages}
      items={minified ? view.items.slice(0, 3) : view.items}
      className={className}
      containerClassName={containerClassName}
      paginationClassName={paginationClassName}
      noDataPlaceholder={
        <Nothing
          icon={faReceipt}
          className={minified ? 'h-[184px]' : 'h-[472px]'}
          text="No payments yet"
          size={minified ? 'medium' : 'large'}
        />
      }
    />
  );
};
