import './Overview.scss';
import { BillingInformationBlock } from '../BillingInformation';
import { PaymentMethodsBlock } from '../PaymentMethodsBlock';
import { PaymentsHistoryWidget } from '../PaymentsHistory';
import { General } from './General';

export const Overview = () => {
  return (
    <div className="flex flex-col gap-y-3">
      <General />
      <BillingInformationBlock />
      <PaymentMethodsBlock />
      <PaymentsHistoryWidget />
    </div>
  );
};
