import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconBox } from '~/common/components';
import { cx } from '~/common/utils';

interface NothingProps {
  icon: IconProp;
  className?: string;
  text?: string;
  size?: 'medium' | 'large';
}

export const Nothing = ({
  icon,
  className,
  text = 'Nothing found',
  size = 'large',
}: NothingProps) => (
  <div
    className={cx(
      'flex flex-col justify-center items-center def:h-[600px] text-greyscale-300',
      className,
    )}
  >
    <IconBox
      className={cx({ 'h-5 w-5': size === 'large', 'h-4 w-4 text-': size === 'medium' })}
      iconProps={{ size: size === 'large' ? '2x' : 'xl' }}
      icon={icon}
    />
    <p
      className={cx({
        'font-brant-t1r mt-2': size === 'large',
        'font-brand-b1 mt-[4px]': size === 'medium',
      })}
    >
      {text}
    </p>
  </div>
);
