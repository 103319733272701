import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { nonNullable } from '../utils';

/**
 * A generic hook for updating query cache
 * Provide a query key and a data type (as a generic type parameter)
 */
export function useUpdateCache<T>(
  key: QueryKey,
  errorMessage: string,
): (update: (props: T) => T) => void {
  const queryClient = useQueryClient();

  return useCallback(
    (update) => {
      queryClient.setQueryData<T>(key, (data) => {
        if (data) {
          return update(data);
        }
        console.error(errorMessage);
      });
    },
    [errorMessage, key, queryClient],
  );
}

export const useAdvancedUpdateCache = <T>(queryKey: QueryKey) => {
  const client = useQueryClient();

  return {
    cancelQuery: () => client.cancelQueries(queryKey),
    getQuery: () => nonNullable(client.getQueryData<T>(queryKey)),
    setQuery: (stateOrCb?: ((prev: T) => T) | T) => {
      return client.setQueryData<T>(
        queryKey,
        stateOrCb instanceof Function ? (prev) => prev && stateOrCb(prev) : stateOrCb,
      );
    },
  };
};
