import { z } from 'zod';

export const ProductType = z.enum(['retainer', 'techpack']);

export type ProductType = z.infer<typeof ProductType>;

export const retainerProductSchema = z.object({
  id: z.number(),
  amount: z.number(),
  plan: z.object({
    id: z.number(),
    name: z.string(),
    amount: z.number(),
    type: ProductType,
    isInternal: z.boolean(),
  }),
  credits: z.number().nullable(),
  dailyPoints: z.number().nullable(),
  enableTopup: z.boolean(),
  enableAddons: z.boolean(),
  keepIndividualPrices: z.boolean(),
});

export type RetainerProduct = z.infer<typeof retainerProductSchema>;

export const techpackProductSchema = z.object({
  id: z.number(),
  amount: z.number(),
  plan: z.object({
    id: z.number(),
    name: z.string(),
    amount: z.number(),
    type: ProductType,
    isInternal: z.boolean(),
  }),
  enableCustomFields: z.boolean(),
  enableCustomPortal: z.boolean(),
  enableSso: z.boolean(),
});

export type TechpackProduct = z.infer<typeof techpackProductSchema>;

export const subscriptionProductSchema = z.union([retainerProductSchema, techpackProductSchema]);

export type SubscriptionProduct = z.infer<typeof subscriptionProductSchema>;

export const subscriptionPlanPeriodSchema = z.object({
  methods: z.array(z.enum(['card', 'invoice'])),
  bonus: z.number().optional(),
});

export const subscriptionPlanPeriodsSchema = z.object({
  annual: subscriptionPlanPeriodSchema.optional(),
  'bi-annual': subscriptionPlanPeriodSchema.optional(),
  quarter: subscriptionPlanPeriodSchema.optional(),
  monthly: subscriptionPlanPeriodSchema.optional(),
});

export type SubscriptionPlanPeriods = z.infer<typeof subscriptionPlanPeriodsSchema>;

export type SubscriptionPlanPeriod = keyof SubscriptionPlanPeriods;

export const subscriptionPlanViewSchema = z
  .object({
    amount: z.number(),
    credits: z.number().nullable(),
    dailyCapacity: z.number().nullable(),
    id: z.number(),
    name: z.string(),
    periods: subscriptionPlanPeriodsSchema,
    prices: z
      .object({
        fixup: z.number(),
        freehand: z.number(),
        redesign: z.number(),
        redraw: z.number(),
      })
      .nullable(),
    pros: z.array(z.string()).optional(),
    tags: z.array(z.enum(['new', 'self_service'])),
  })
  .transform((plan) => ({
    ...plan,
    isNew: plan.tags.includes('new'),
    /** Used to specify which plan can be bought from the platform on BE side
     */
    selfService: plan.tags.includes('self_service'),
  }));

export type SubscriptionPlanView = z.infer<typeof subscriptionPlanViewSchema>;

export const subscriptionPlansSchema = z.array(subscriptionPlanViewSchema);

export type SubscriptionPlans = z.infer<typeof subscriptionPlansSchema>;

export type SetSubscriptionPlanPayload = {
  plan: number;
  period: number;
  method: number | null;
  // TODO: Currently BE isn't accepting this field, but looks like it have to
  coupon: string | null;
};

export type SubscriptionCancelPayload = {
  cancellationReason: string;
};
