// Helpers for `data.reducers.js`
import { ComponentNames } from '~/pages/Order-now/constants';

/*
  Function rewrites new `payload` for `details[name]`, but saves rest data.

  Example:
  1. Current store: store.data.details { ..., [name]: { key: 'key', value: 'value'} };
  2. `payload` is: { value: 'new value' } - function changes only `value`. `key` is saved.
  3. New store: store.data.details { ..., [name]: { key: 'key', value: 'new value'} };
*/
// todo: check is posssible to bind `details` for `updateDetailsByName`.
export const updateDetailsByName = (details, name, payload) => ({
  ...details,
  [name]: {
    ...details[name],
    ...payload,
  },
});

/*
  Function rewrites new `payload` for `details[name]`.

  Example:
  1. Current store: store.data.details { ..., [name]: { key: 'key' } };
  2. `payload` is: { value: 'value' }
  3. New store: store.data.details { ..., [name]: { value: 'value'} }; `key` was rewritten
*/
// todo: check is posssible to bind `details` for `replaceDetailsByName`.
export const replaceDetailsByName = (details, name, payload = {}) => ({
  ...details,
  [name]: {
    ...payload,
  },
});

/*
  Function returns the initial values of `components` `fields` based on backend response
  For example, the one of result can be for `deliveryDates`:
  `store.data.details.deliveryDates` = { turnaround: 96 }
*/
export const getInitialComponentValues = (fields = []) =>
  fields.reduce(
    (field, { name, value }) => ({
      ...field,
      ...(value !== null && { [name]: value }),
    }),
    {},
  );

/*
  An initial empty object for every store.data.details[name].
  Now we can skip condition like: state.details[name] && state.details[name].someKey ? ...;
  Result is: state.data.details: { addons: {}, treatments: {}, ... };
*/
export const createComponentsDetails = (components, storeDetails = {}) =>
  components.reduce(
    (details, { name, visible, optional, fields, properties, data, actions, ...rest }) => {
      const filledByUserData = storeDetails[name];
      const componentDetails = {
        [name]: {
          ...(fields && getInitialComponentValues(fields)),
          ...rest,
          ...filledByUserData,
        },
      };

      return Object.assign(details, componentDetails);
    },
    { ...storeDetails },
  );

/*
  For multiple custom form data requests, we replace `store.data.components` with new component only.
  For example, second request on login until custom order form flow.
*/
export const replaceComponents = (state, updatedComponent) =>
  state.components.map((component) =>
    component.name === updatedComponent.name ? updatedComponent : component,
  );

// Get `valueAffections` collection from every `fields` if it exists
export const createValueAffections = (components) =>
  components.reduce((affections, { fields }) => {
    // Because `fields` can be null
    const Fields = fields || [];
    return {
      ...affections,
      ...Fields.reduce(
        (accumulator, { name, valueAffections }) => ({
          ...accumulator,
          ...(valueAffections && { [name]: valueAffections }),
        }),
        {},
      ),
    };
  }, {});

// The function updates the component structure based on `valueAffections` data for this component
export const updateComponents = (components, name, updates) => {
  const index = components.findIndex((entry) => entry.name === name);

  if (index === -1) {
    return components;
  }

  const component = components.splice(index, 1)[0];

  return [...components, { ...component, ...updates }];
};

// The function is hardcoded because of fixed data in topbar.
// It manually handles treatment/turnaroun actions.
export const updateAdditionalOrderDetails = (dataDetails, components) => {
  const details = { ...dataDetails };

  // Update topbar treatment price
  const { treatment, price, treatmentName } = details.treatments;
  const treatments = components.find(
    ({ name: componentName }) => componentName === ComponentNames.TREATMENTS,
  )?.data.treatments;
  const currentTreatment = treatments?.find(({ key }) => key === treatment);

  if (!price) {
    // Set price of current treatment
    details.treatments.price = currentTreatment?.price;
  }

  if (currentTreatment?.prices) {
    // Set prices with turnaround
    details.treatments.turnaroundPrices = currentTreatment?.prices;
  }

  if (!treatmentName) {
    details.treatments.treatmentName = currentTreatment?.name;
  }

  return details;
};

export const stepsHelper = {
  steps: null,

  setSteps(steps) {
    this.steps = steps;
  },

  update(components) {
    const filter = Object.entries(components).reduce((collection, [name, { properties }]) => {
      if (properties?.hideStep) {
        return [...collection, name];
      }

      return collection;
    }, []);

    if (!filter.length) {
      return this.steps;
    }

    const visibleSteps = this.steps
      .map((steps) => steps.filter((step) => !filter.includes(step)))
      .filter((steps) => steps.length);

    return visibleSteps;
  },

  original() {
    return this.steps;
  },
};
