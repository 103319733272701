import { Button, Popover } from '~/common/components';
import { Any, handleEnterPress } from '~/common/utils';
import { useUpdateSettings } from '~/hooks';
import { CustomerUser, useCurrentUser } from '../../Auth';
import { useNavigation } from '../context';
import { NavigationLink } from '../NavigationLink';
import { config } from './config';

const Introducing = ({ onClose = () => {} }) => {
  return (
    <div className="text-text-500 font-brand-b3 space-y-1">
      <p className="font-brand-l2 text-text-500">🚀 New! Style templates feature</p>
      <p>
        Now, you can add and customize your own styles, manage default templates, and ensure
        consistency across your presentations.
      </p>
      <Button color="primary" onClick={onClose} className="ml-auto">
        Got it
      </Button>
    </div>
  );
};

export const StyleTemplatesLink = () => {
  const { isFromHeader } = useNavigation();
  const { mutate } = useUpdateSettings();
  const user = useCurrentUser();
  const mutateSettings = () => mutate({ ...user?.settings, style_templates_seen: true });

  if (!config.links.styleTemplates.canShow(user as CustomerUser)) return null;

  if (isFromHeader) {
    return <NavigationLink {...config.links.styleTemplates} />;
  }

  return (
    <Popover
      className="!p-2 w-[400px] max-w-full !z-[40]"
      placement="right-start"
      openOnMount={!user?.settings.style_templates_seen}
      showArrow
      trigger={(props) => (
        <NavigationLink
          ref={props.ref}
          onPointerDown={(e) => {
            if (!user?.settings.style_templates_seen) {
              mutateSettings();
              props.onPointerDown(e);
            }
          }}
          onKeyDown={
            handleEnterPress(() => {
              if (!user?.settings.style_templates_seen) {
                mutateSettings();
              }
            }) as Any
          }
          {...config.links.styleTemplates}
        />
      )}
      content={(props) => (
        <Introducing
          onClose={() => {
            mutateSettings();
            props.onClose();
          }}
        />
      )}
    />
  );
};
