import { z } from 'zod';
import { billingSharedSchema } from '~/root/domain';

export const billingAddressSchema = billingSharedSchema;

export type BillingAddress = z.infer<typeof billingAddressSchema>;

export type UpdateCompanyDetailsPayload = {
  company?: string | null;
  vatNumber?: string | null;
  billingContact?: string | null;
};

export type UpdateBillingAddressPayload = {
  city: string;
  country: number;
  phoneCountryId: number | null;
  phoneNumber: string | null;
  postcode: string | null;
  state?: string | null;
  street: string;
};

export type UpdateBillingPayload = UpdateCompanyDetailsPayload & UpdateBillingAddressPayload;

export type BillingFormValues = {
  companyName: string;
  billingContact: string;
  country: number | null;
  city: string;
  street: string;
  state: string;
  postcode: string; // ^[0-9a-z\-\s]{2,10}
  vatNumber: string;
  phoneCountryId: number | null;
  phoneNumber: string;
  poNumber: string;
  poExpiresAt: Date | null;
};
