import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cx } from '~/common/utils';
import css from './PaginationItem.module.scss';

type Props = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  icon?: IconProp;
  disabled?: boolean;
  active?: boolean;
};

export const PaginationItem = ({
  icon,
  disabled,
  active,
  children,
  onClick,
  className,
  ...props
}: Props) => {
  const Content = icon ? <FontAwesomeIcon icon={icon} /> : children;
  return (
    <li>
      <a
        {...props}
        onClick={disabled ? undefined : onClick}
        className={cx(css.paginationItem, className, {
          [css.active]: active,
          [css.disabled]: disabled,
          [css.icon]: icon,
        })}
      >
        {Content}
      </a>
    </li>
  );
};
