import {
  faCommentHeart,
  faExternalLink,
  faImage,
  faMicrophoneStand,
  faNewspaper,
} from '@fortawesome/pro-light-svg-icons';
import { IconBox, Link } from '~/common/components';
import { routes } from '~/constants';
import { Item } from './Item';

export const ResourcesContent = () => (
  <div className="flex flex-col lg:flex-row gap-1 lg:gap-2">
    <div className="w-full space-y-1 lg:w-[332px]">
      <Link.Base className="flex -outline-offset-2" href={routes.public.stories}>
        <Item
          label="Customer stories"
          description="Real stories from our satisfied clients"
          icon={<IconBox icon={faCommentHeart} className="text-[#EF5C88]" size="m" />}
        />
      </Link.Base>
      <Link.Base className="flex -outline-offset-2" href={routes.public.present_better} newTab>
        <Item
          label={
            <div className="flex items-center">
              Present better
              <IconBox
                icon={faExternalLink}
                className="ml-[2px] h-3 w-3 !text-[15px] text-text-600"
              />
            </div>
          }
          description="Tips and tutorials for better presentations"
          icon={<IconBox icon={faNewspaper} className="text-[#1EC1A4]" size="m" />}
        />
      </Link.Base>
    </div>
    <div className="w-full space-y-1 lg:w-[332px]">
      <Link.Base className="flex -outline-offset-2" href={routes.public.about.impact}>
        <Item
          label="Impact & Learnings"
          description="Our lessons and insights from the journey"
          icon={<IconBox icon={faMicrophoneStand} className="text-[#8A6DD9]" size="m" />}
        />
      </Link.Base>
      <Link.Base className="flex -outline-offset-2" href={routes.public.templates} newTab>
        <Item
          label={
            <div className="flex items-center">
              Free templates
              <IconBox
                icon={faExternalLink}
                className="ml-[2px] h-3 w-3 !text-[15px] text-text-600"
              />
            </div>
          }
          description="Free, professionally designed templates"
          icon={<IconBox icon={faImage} className="text-[#FFBA0A]" size="m" />}
        />
      </Link.Base>
    </div>
  </div>
);
