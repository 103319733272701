import { IframeHTMLAttributes } from 'react';
import styles from './styles.module.scss';

export const ResponsiveIFrame = ({
  className,
  ...props
}: IframeHTMLAttributes<HTMLIFrameElement>) => {
  return (
    <div className={className}>
      <div className={styles.wrapper}>
        <iframe {...props} className={styles.iframe} />
      </div>
    </div>
  );
};
