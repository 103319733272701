import { Dispatch, SetStateAction } from 'react';
import { set } from './data';

export const toggleSet =
  <T>(setState: Dispatch<SetStateAction<Set<T>>>) =>
  (item: T) => {
    setState((prev) => set.toggle(prev, item));
  };

export const handleStateAction = <T>(stateOrCb: SetStateAction<T>, value: T) =>
  stateOrCb instanceof Function ? stateOrCb(value) : stateOrCb;
