import IconArrowDown from './IconArrowDown';
import IconCheck from './IconCheck';
import IconCommentAltEdit from './IconCommentAltEdit';
import IconCursorPointer from './IconCursorPointer';
import IconEllipsisV from './IconEllipsisV';
import IconQuestionCircle from './IconQuestionCircle';
import IconTimes from './IconTimes';
import IconTrash from './IconTrash';
import IconUpdateClock from './IconUpdateClock';
import type { IconType } from './types';
type IconNameType =
  | 'arrow-down'
  | 'update-clock'
  | 'cursor-pointer'
  | 'comment-alt-edit'
  | 'trash'
  | 'check'
  | 'times'
  | 'question-circle'
  | 'ellipsis-v';
const icons = {
  'arrow-down': IconArrowDown,
  'update-clock': IconUpdateClock,
  'cursor-pointer': IconCursorPointer,
  'comment-alt-edit': IconCommentAltEdit,
  trash: IconTrash,
  check: IconCheck,
  times: IconTimes,
  'question-circle': IconQuestionCircle,
  'ellipsis-v': IconEllipsisV,
};
type Props = IconType & {
  icon: IconNameType;
};

// TODO: Cool, more stuff to remove
const Icon = ({ icon, ...props }: Props) => {
  const IconComponent = icons[icon];

  if (IconComponent) {
    return <IconComponent {...props} />;
  }

  // eslint-disable-next-line no-console
  console.warn(`Icon name "${icon}" is incorrect`);
  return null;
};

export {
  IconArrowDown,
  IconUpdateClock,
  IconCursorPointer,
  IconCommentAltEdit,
  IconTrash,
  IconCheck,
  IconTimes,
  IconQuestionCircle,
  IconEllipsisV,
};
export default Icon;
