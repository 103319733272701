import { Card, Link } from '~/common/components';
import { Spinner } from '~/components/loaders/Spinner';
import { routes } from '~/constants';
import { usePaymentsHistory } from '../hooks';
import { PaymentsTable } from './PaymentsTable';

export const PaymentsHistoryWidget = () => {
  const paymentHistory = usePaymentsHistory();

  if (!paymentHistory.data)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <Card
      title={
        <div className="flex justify-between items-center">
          <span>Payments history</span>
          <Link to={routes.profile.billing.payments_history}>View all</Link>
        </div>
      }
    >
      <PaymentsTable
        minified
        data={paymentHistory.data}
        containerClassName="-mx-3 md:-mx-4"
        paginationClassName="px-4 pb-2"
      />
    </Card>
  );
};
