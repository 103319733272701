export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 128;

export const ONLY_LETTERS_REGEXP = /^(?:[A-Za-zÀ-ÖØ-öø-ÿ\s'-])+$/u;

// LETTERS_ONLY_LATIN_REGEXP for cases when string can contain other symbols too, but letters should be only latin
export const LETTERS_ONLY_LATIN_REGEXP = /^[A-Za-z\s\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;
export const CONTAINS_DIGIT_REGEXP = /\d/;
export const CONTAINS_SYMBOL_REGEXP = /[!@#$%^&*()_+]/;
export const CONTAINS_UPPERCASE_LETTER_REGEXP = /[A-Z]/;
export const CONTAINS_LOWERCASE_LETTER_REGEXP = /[a-z]/;
export const PASSWORD_REGEXP = /^(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,128}$/;

/**
 * Email
 */

// RFC 2822 standard email validation
export const EMAIL_REG_EXP = new RegExp(
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
);

export const LINK_REGEXP =
  // eslint-disable-next-line no-useless-escape
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

interface EmailBrand {
  readonly Email: unique symbol;
}

export type Email = string & EmailBrand;

export const isEmail = (s: string): s is Email => {
  // for some reason with this pretty extensive regexp two @ pass :shrug:
  const hasSingleAt = s.replace(/[^@]/g, '').length === 1;
  return EMAIL_REG_EXP.test(s.toLowerCase()) && hasSingleAt;
};
