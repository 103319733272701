import { z } from 'zod';
import { EMAIL_REG_EXP } from '~/common/utils';
import { teamRoleSchema, axiosErrorMessageSchema } from '~/root/domain';

const permissionSchema = z.enum(['edit', 'comment']);

export type Permission = z.infer<typeof permissionSchema>;

const memberSchema = z.object({
  id: z.number(),
  initials: z.string(),
  email: z.string(),
  name: z.string(),
  permission: permissionSchema,
  role: teamRoleSchema,
  isSubscribed: z.boolean(),
});

export type Member = z.infer<typeof memberSchema>;

const inviteeSchema = z.object({
  email: z.string(),
  invitation_id: z.number(),
  role: teamRoleSchema,
  is_expired: z.boolean(),
  invited_at: z.string(),
  permission: permissionSchema,
});

export type Invitee = z.infer<typeof inviteeSchema>;

// these are beyond ugly types
export const isInvitee = (member: (Invitee | Member) & Editable): member is Invitee & Editable => {
  return 'invitation_id' in member;
};

export const isMember = (member: (Invitee | Member) & Editable): member is Member & Editable =>
  'initials' in member;

export type Editable =
  | {
      editable: false;
      buttonText: 'owner' | 'creator';
    }
  | {
      editable: true;
      buttonText?: never;
    };

export const membersSchema = z
  .object({
    owner: memberSchema.nullable(),
    creator: memberSchema.nullable(),
    you: memberSchema,
    items: z.union([inviteeSchema, memberSchema]).array(),
  })
  // this is a beyond ugly transform :(, at least it doesn't leak into the view too much
  .transform(({ items, ...members }) => {
    const filtered = items.filter(({ email }) => {
      const { owner, creator, you } = members;
      // to show "you" in the general list if "you" are not the owner and not creator
      if (email === you.email && email !== owner?.email && email !== creator?.email) {
        return true;
      }

      return email !== owner?.email && email !== creator?.email;
    });

    const byRole = {
      admins: [] as (Member & Editable)[],
      members: [] as (Member & Editable)[],
      invited: [] as (Invitee & Editable)[],
    };

    for (const member of filtered) {
      if ('invitation_id' in member) {
        byRole.invited.push({ ...member, editable: true });
        continue;
      }

      if (member.role === 'admin') {
        byRole.admins.push({ ...member, editable: true });
        continue;
      }

      byRole.members.push({ ...member, editable: true });
    }

    if (members.owner && members.owner.email !== members.creator?.email) {
      byRole.members.unshift({
        ...members.owner,
        editable: false,
        buttonText: 'owner',
      });
    }

    if (members.creator) {
      byRole.members.unshift({
        ...members.creator,
        editable: false,
        buttonText: 'creator',
      });
    }

    return { ...members, ...byRole };
  });

export const invitationErrorsSchema = axiosErrorMessageSchema.transform((message) => {
  const match = message.match(new RegExp(EMAIL_REG_EXP, 'g'));
  return match ? Array.from(match) : [];
});
