import { ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Scroll from 'react-scroll';
import { getCanonicalHref } from '~/utils/canonicalHref';
import { Header } from './Header';

export const DefaultLayout = ({
  children,
  isHeaderShown,
}: {
  children: ReactNode;
  isHeaderShown?: boolean;
}) => {
  const location = useLocation();

  useEffect(() => {
    Scroll.animateScroll.scrollToTop({
      duration: 0,
    });
  }, [location.pathname]);

  const canonicalHref = getCanonicalHref();

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalHref} />
      </Helmet>
      {isHeaderShown && <Header />}
      {children}
    </div>
  );
};
