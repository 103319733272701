import { SubscriptionPlanPeriod } from '../domain';

export const treatmentMap = {
  fixup: 'Fix Up',
  redesign: 'Redesign',
  redraw: 'Redraw',
  freehand: 'Other',
} as const;

export const subscriptionPaymentMethod = {
  card: 'Credit Card',
  invoice: 'Invoice',
} as const;

export const subscriptionPeriodToMonthAmount: Record<SubscriptionPlanPeriod, number> = {
  monthly: 1,
  quarter: 3,
  'bi-annual': 6,
  annual: 12,
};

export const subscriptionPeriodToName: Record<SubscriptionPlanPeriod, string> = {
  monthly: 'Monthly',
  quarter: 'Quarterly',
  'bi-annual': 'Half-year',
  annual: 'Annually',
};
