const getRatio = (current: number, total: number) => current / total || 0;

export const getPercentage = (current: number, total: number) => {
  return Math.round(getRatio(current, total) * 100);
};

export const megaBytesToBytes = (megaBytes: number) => megaBytes * 1024 * 1024;

const twoDecimalsPrecision = (value: number) => Math.round(value * 100) / 100;

export const formatMoney = (value: number, currency = 'USD') => {
  const roundedValue = twoDecimalsPrecision(value);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumSignificantDigits: roundedValue.toString().length,
    currencyDisplay: 'symbol',
  })
    .format(roundedValue)
    .replace(/,/g, ' ');
};

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat('en-US').format(twoDecimalsPrecision(value)).replace(/,/g, ' ');
};
