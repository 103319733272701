import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FieldProps } from 'formoid';
import { ChangeEventHandler, ComponentProps, forwardRef } from 'react';
import { cx, Overwrite } from '~/common/utils';
import { IconButton } from '../IconContainers';
import { Input } from '../Input';

type CounterProps = Overwrite<
  Omit<ComponentProps<typeof Input>, 'type'> & Partial<FieldProps<number>>,
  {
    onChange: FieldProps<number>['onChange'];
    value: FieldProps<number>['value'];
  }
>;

export const Counter = forwardRef<HTMLDivElement, CounterProps>(
  ({ value, errors, size = 'l', disabled, className, onChange, ...props }: CounterProps, ref) => {
    const increment = () => {
      const newValue = (value || 0) + 1;
      onChange?.(props.max ? Math.min(newValue, Number(props.max)) : newValue);
    };
    const decrement = () => {
      const newValue = (value || 0) - 1;
      onChange?.(Math.max(newValue, 0));
    };

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const value = event.currentTarget.valueAsNumber || 0;

      const validatedValue = props.max ? Math.min(value, Number(props.max)) : value;

      event.currentTarget.value = validatedValue.toString();

      return onChange?.(validatedValue);
    };

    return (
      <Input
        ref={ref}
        size={size}
        value={value}
        disabled={disabled}
        error={!!errors}
        errors={errors}
        className={className}
        inputClassName={cx('font-brand-h5 text-center arrow-hide !py-1', {
          '!px-4': size === 's',
          '!px-5': size === 'm',
          '!px-6': size === 'l',
          'text-text-200': value < 1,
        })}
        type="number"
        onChange={handleInputChange}
        {...props}
      >
        <IconButton
          icon={faMinus}
          size={size}
          disabled={disabled || value === null || !(value > 0)}
          onClick={decrement}
          className="absolute left-1 top-1/2 -translate-y-1/2"
        />
        <IconButton
          icon={faPlus}
          size={size}
          disabled={disabled}
          onClick={increment}
          className="absolute right-1 top-1/2 -translate-y-1/2"
        />
      </Input>
    );
  },
);
