import { useLayoutEffect, useRef } from 'react';
import { Any } from '../utils';

/**
 * Poor man's useEvent, https://github.com/reactjs/rfcs/pull/220
 * Should be used when a stable callback reference is needed,
 * but it is impossible due to callback closing on outside values
 *
 * This makes a stable wrapper and just updates underlying callback
 *
 * Resulting callback could be safely omitted from
 * useMemo, useCallback and useEffect dependency lists
 */
export const useEvent = <T extends (...args: Any[]) => Any>(cb: T): T => {
  const ref = useRef({
    cb,
    wrapper: (...args: Any[]) => ref.current.cb(...args),
  });

  useLayoutEffect(() => {
    ref.current.cb = cb;
  });

  return ref.current.wrapper as T;
};
