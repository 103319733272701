import React from 'react';

type HandledEvent<E extends Element = Element> =
  | React.MouseEvent<E, MouseEvent>
  | React.PointerEvent<E>
  | React.WheelEvent<E>;

export const checkSoftStoppedPropagation = <T extends HandledEvent>(event: T) =>
  (event.target as HTMLElement).closest('[data-stop-propagation]');

export const ignoreHandled = <T extends HandledEvent>(cb: (event: T) => void) => {
  const callback = (event: T) => {
    if (checkSoftStoppedPropagation(event)) {
      return;
    }

    cb(event);
  };

  return callback;
};
