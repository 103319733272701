import { Dispatch, SetStateAction, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { useEvent } from '~/common/hooks';

// TODO check if it works :D
export const getSort = (option: string, sort?: Sort): Sort | undefined => {
  if (sort?.option !== option) {
    return { option, order: 'asc' };
  }

  if (sort?.option === option && sort?.order === 'asc') {
    return { option, order: 'desc' };
  }

  if (sort?.option === option && sort?.order === 'desc') {
    return undefined;
  }
};

type Order = 'asc' | 'desc';

export type Sort = { option: string; order: Order };

export type Filter = Record<string, string | number>;

type AllKindsOfStuffRecord = Record<
  string,
  number | string | boolean | Sort | Filter | undefined | null
>;

type ZodParser<T> = {
  parse: (data: unknown, params?: Partial<z.ParseParams> | undefined) => T;
};

export type PaginationParams = z.infer<ReturnType<typeof paginationSchema>>;

const deserializeParams = (params: string) => {
  return Object.fromEntries(new URLSearchParams(params).entries());
};

const serializeTruthyParams = <T extends AllKindsOfStuffRecord>(params: T) => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([k, v]) => {
    // filter falsy values from query string, but allow 0 to be safe
    if (v && v !== 0) {
      searchParams.set(k, String(v));
    }
  });
  return searchParams;
};

const sortingString = /\w+:(asc|desc)$/;

export const paginationSchema = (limit = 5) =>
  z.object({
    page: z.coerce.number().catch(1),
    limit: z.coerce.number().catch(limit),
    'filter[sort]': z
      .custom<string | undefined>((s) => {
        return typeof s === 'string' ? sortingString.test(s) : false;
      })
      .transform((sort) => {
        if (sort !== undefined) {
          const [option, order] = sort.split(':');
          return { option, order: order as 'asc' | 'desc' };
        }
      })
      .catch(undefined),
  });

export const omitDefaultPaginationParams = <T extends PaginationParams>(next: T, prev: T) => ({
  ...next,
  // avoid populating url with default parameters
  page: prev.page === next.page || next.page === 1 ? undefined : next.page,
  limit: next.limit === 5 ? undefined : next.limit,
});

/**
 * Example
 *
 * const ordersParamSchema = z.object({
 *   page: z.coerce.number().catch(1),
 *   limit: z.coerce.number().catch(5),
 *   'filter[search]': z.string().optional().catch(undefined),
 *   'filter[status]': z.coerce.number().optional().catch(undefined),
 * });
 *
 * export const useOrdersQueryState = createQueryState(ordersParamSchema, (params) => ({
 *   ...params,
 *   // avoid populating url with default parameters
 *   page: params.page === 1 ? undefined : params.page,
 *   limit: params.limit === 5 ? undefined : params.limit,
 * }));
 *
 * const [params, setParams] = useOrdersQueryState();
 *
 * const handleSearchChange = (value: string) => {
 *   setParams((prev) => ({ ...prev, search: value }));
 * };
 */
export const createQueryState =
  <T extends AllKindsOfStuffRecord>(
    schema: ZodParser<T>,
    transform?: (next: T, prev: T) => Partial<T>,
  ) =>
  () => {
    const { search } = useLocation();
    const history = useHistory();

    const state = useMemo(() => {
      const params = deserializeParams(search);
      const parsed = schema.parse(params);
      return parsed;
    }, [search]);

    const setState: Dispatch<SetStateAction<T>> = useEvent((stateOrCb) => {
      const next = stateOrCb instanceof Function ? stateOrCb(state) : stateOrCb;

      // we won't do anything if parameters weren't changed
      const currentKeys = Object.keys(state);
      const nextKeys = Object.keys(next);
      if (
        currentKeys.length === nextKeys.length &&
        currentKeys.every((key) => state[key] === next[key])
      ) {
        return;
      }

      const searchParams = serializeTruthyParams(transform ? transform(next, state) : next);
      history.push({ search: searchParams.toString() });
    });

    return [state, setState] as const;
  };
