import { useEffect, useRef } from 'react';
import { ModalCloseButton, ModalContentProps } from './Modal';

export const VideoPlayerModal = ({ onClose, url }: ModalContentProps & { url: string }) => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    ref.current?.play().catch(() => {});
  }, []);

  return (
    <>
      <ModalCloseButton onClose={onClose} />
      <video ref={ref} className="block w-full md:w-[70vw] md:rounded" src={url} controls />
    </>
  );
};
