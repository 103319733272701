import { useForm, validator } from 'formoid';
import {
  InputField,
  ModalHeader,
  ModalShell,
  PhoneInputField,
  SelectField,
  useByIdOptions,
} from '~/common/components';
import { Overwrite, cx, formatYearMonthDay, record, customValidator } from '~/common/utils';
import { countryFilter, useCountriesData } from '~/hooks';
import { InvoicePaymentMethod } from '~/root/domain';
import { BillingAddress, BillingFormValues, Company } from './domain';
import { useBillingData } from './hooks';

export const transformFormValues = (values: Overwrite<BillingFormValues, { country: number }>) => ({
  ...values,
  companyName: values.companyName || null,
  billingContact: values.billingContact || null,
  state: values.state || null,
  postcode: values.postcode || null,
  poNumber: values.poNumber || null,
  vatNumber: values.vatNumber || null,
  poExpiresAt: values.poExpiresAt ? formatYearMonthDay(values.poExpiresAt) : null,
});

export type GetBillingInitialValuesInput = (InvoicePaymentMethod | BillingAddress) & Company;

export const getBillingInitialValues = (
  input?: GetBillingInitialValuesInput,
): BillingFormValues => ({
  companyName: input?.name || '',
  billingContact: input?.billingContact || '',
  country: input?.country?.id || null,
  city: input?.city || '',
  street: input?.street || '',
  state: input?.state || '',
  postcode: input?.postcode || '', // ^[0-9a-z\-\s]{2,10}
  vatNumber: input?.vatNumber || '',
  phoneCountryId: input?.phoneCountryId || null,
  phoneNumber: input?.phoneNumber || '',
  poNumber: (input && 'poNumber' in input && input.poNumber) || '',
  poExpiresAt: (input && 'poExpiresAt' in input && input.poExpiresAt) || null,
});

type BillingForm = ReturnType<typeof useBillingForm>;

export const useFillWithBillingInfo = (form: BillingForm) => {
  const { billingAddress, company } = useBillingData();

  const fillWithBillingInfo = () => {
    form.setValues((prev) => ({
      ...prev,
      ...getBillingInitialValues({ ...billingAddress, ...company }),
    }));
  };

  return fillWithBillingInfo;
};

export const useBillingForm = (
  initialValues: BillingFormValues,
  params?: { requireBillingContact?: boolean },
) => {
  const { requireBillingContact = false } = params || {};
  const { getCountryById } = useCountriesData();

  return useForm({
    initialValues,
    validationStrategy: 'onBlur',
    validators: ({
      phoneCountryId,
      phoneNumber,
      poNumber,
      poExpiresAt,
      vatNumber,
      state,
      companyName,
      billingContact,
      country,
    }) => ({
      companyName: validator.validateIf(
        () => !!companyName,
        validator.sequence(
          customValidator.required(),
          validator.minLength(2, 'Company name is too short'),
          validator.maxLength(50, 'Company name is too long'),
        ),
      ),
      billingContact: validator.validateIf(
        () => !!billingContact || requireBillingContact,
        validator.sequence(customValidator.required(), customValidator.email()),
      ),
      country: customValidator.required(),
      city: validator.sequence(
        customValidator.required(),
        validator.minLength(2, 'City name is too short'),
        validator.maxLength(40, 'City name is too long'),
      ),
      street: validator.sequence(
        customValidator.required(),
        validator.minLength(4, 'Address is too short'),
        validator.maxLength(50, 'Address is too long'),
      ),
      state: validator.validateIf(
        () => !!state,
        validator.sequence(
          validator.minLength(2, 'State name is too short'),
          validator.maxLength(40, 'State name is too long'),
        ),
      ),
      postcode:
        country && getCountryById(country).hasZip
          ? validator.sequence(
              customValidator.required(),
              validator.minLength(2, 'Postcode is too short'),
              validator.maxLength(10, 'Postcode is too long'),
            )
          : validator.fromPredicate(() => true, ''),
      vatNumber: validator.validateIf(
        () => !!vatNumber,
        validator.sequence(
          validator.minLength(4, "VAT isn't long enough"),
          validator.maxLength(100, 'VAT is too long'),
        ),
      ),
      phoneCountryId: validator.validateIf(() => phoneNumber !== '', customValidator.required()),
      phoneNumber: validator.validateIf(
        () => phoneNumber !== '' || !!phoneCountryId,
        customValidator.phoneNumber(),
      ),
      poNumber: validator.validateIf(() => poExpiresAt !== null, customValidator.required()),
      poExpiresAt: validator.validateIf(() => poNumber !== '', customValidator.required()),
    }),
  });
};

interface BillingFormProps {
  title: string;
  initialValues?: BillingFormValues;
  children?: React.ReactNode;
  billingInfoHeader?: React.ReactNode;
  requireBillingContact?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const BillingForm = ({
  title,
  billingInfoHeader,
  requireBillingContact = false,
  children,
  initialValues = getBillingInitialValues(),
  onClose,
  onSubmit,
  fieldProps,
  values,
  isSubmitting,
}: BillingFormProps & BillingForm) => {
  const { countriesById, getCountryById } = useCountriesData();
  const isDirty = !record.shallowEqual(values, initialValues);
  const countryHasZip = !!values.country && getCountryById(values.country).hasZip;

  return (
    <>
      <ModalHeader onClose={onClose} title={title} />
      <ModalShell
        className="w-[624px]"
        onClose={onClose}
        onSubmit={onSubmit}
        loading={isSubmitting}
        disabled={!isDirty}
      >
        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-3 gap-y-2 pb-4">
          <span className="text-text-500 font-brand-t3m col-span-1 md:col-span-2">
            Company information
          </span>
          <InputField
            {...fieldProps('companyName')}
            title={cx('Company name')}
            placeholder="Enter company name"
            type="text"
          />
          <InputField
            {...fieldProps('billingContact')}
            title="Billing email"
            placeholder="Enter email"
            required={requireBillingContact}
            type="text"
          />
        </div>

        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-1 mb-2">
          <span className="text-text-500 font-brand-t3m">Billing information</span>
          {billingInfoHeader}
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
          <SelectField
            {...fieldProps('country')}
            title="Country"
            required
            options={countriesById}
            filterOptions={countryFilter}
            placeholder="Select country"
          />
          <InputField
            {...fieldProps('city')}
            title="City"
            placeholder="Select city"
            required
            type="text"
          />
          <InputField
            {...fieldProps('state')}
            title="Region/State"
            placeholder="Enter region or state"
            type="text"
          />
          <InputField
            {...fieldProps('street')}
            title="Address"
            placeholder="Enter address"
            required
            type="text"
          />
          <InputField
            {...fieldProps('postcode')}
            required={countryHasZip}
            title="Zip/Postal code"
            placeholder="Enter code"
            type="text"
          />
          <InputField
            {...fieldProps('vatNumber')}
            title="VAT number"
            placeholder="Enter VAT number"
            hint="To avoid additional VAT percentage to all your purchases, please add your VAT details"
            type="text"
          />
        </div>
        <PhoneInputField
          className="mt-3"
          phoneProps={fieldProps('phoneNumber')}
          codeProps={fieldProps('phoneCountryId')}
          title="Phone number"
          required
          useOptions={useByIdOptions}
        />
        {children}
      </ModalShell>
    </>
  );
};
