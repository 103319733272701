import cx from 'classnames';
import { useState } from 'react';
import { IconArrowDown } from '~/components/icons';
import { UploadedFile } from '~/root/domain';
import { colors } from '~/utils/palette';

type Props = {
  files: Array<UploadedFile> | null;
};

const Attachment = ({ files }: Props) => {
  const [isOpened, setOpen] = useState<boolean>(false);
  const filesAmount = files?.length;

  const handleOpen = () => {
    setOpen((open) => !open);
  };

  if (!filesAmount) {
    // Do not display if `null` or no files
    return null;
  }

  const filesMarkup =
    files &&
    files.map(({ name, link }) => (
      <li key={link} className="text-overflow">
        <a href={link} target="_blank" rel="noopener noreferrer" download={name}>
          {name}
        </a>
      </li>
    ));
  return (
    <div
      className={cx('inner-order-comment-attachment ioca__container', {
        open: isOpened,
      })}
    >
      {isOpened && <ul>{filesMarkup}</ul>}
      <button className="flex-align-center ioca__switcher" type="button" onClick={handleOpen}>
        {`${isOpened ? 'Hide' : 'Show'} attached files (${filesAmount})`}
        <span className="boundary-box arrow-icon">
          <IconArrowDown fill={colors.lochmara} />
        </span>
      </button>
    </div>
  );
};

export default Attachment;
