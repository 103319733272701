import { z } from 'zod';
import { uploadedFileSchema } from '~/root/domain';

export const eventSchema = z.object({
  hash: z.string(),
  message: z.string(),
  icon: z.enum(['dollar', 'gift', 'info']).optional().catch(undefined),
  timestamp: z.number(),
});

export const commentSchema = z.object({
  id: z.number(),
  hash: z.string(),
  name: z.string(),
  initials: z.string(),
  avatarUrl: z.any(),
  comment: z.string(),
  timestamp: z.number(),
  files: z.array(uploadedFileSchema),
});

export const commentsEventsSchema = z.object({
  comments: z.array(commentSchema),
  events: z.array(eventSchema),
});

export type CommentsEvents = z.infer<typeof commentsEventsSchema>;
