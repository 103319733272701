export const getCookieStorage = () => {
  return Object.fromEntries(document.cookie.split('; ').map((kvString) => kvString.split('=')));
};

const DEFAULT_BACKEND = 'https://api-staging.24slides.dev';
const COOKIE_USE_BE = getCookieStorage().use_be;

export const PUSHER_APP_KEY = process.env.PUSHER_APP_KEY;

export const POSTHOG_KEY = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
export const POSTHOG_HOST = process.env.REACT_APP_PUBLIC_POSTHOG_HOST;

export const SERVER_URL = COOKIE_USE_BE || process.env.REACT_SERVER_URL || DEFAULT_BACKEND;
export const PUBLIC_PAGES_URL = process.env.REACT_PUBLIC_PAGES_URL;

export const GTM_TRACKING_ID = process.env.SERVICE_GTM_TRACKING_ID;
export const GA_TRACKING_ID = process.env.SERVICE_GA_TRACKING_ID;
export const GA_STAGING_TRACKING_ID = process.env.SERVICE_STAGING_GA_TRACKING_ID;

export const CLIENT_VERSION = process.env.REACT_APP_COMMIT_TIMESTAMP as string;

export const ENV = {
  AS_STRING: process.env.REACT_APP_ENV as 'production' | 'staging' | 'development',
  PRODUCTION: process.env.REACT_APP_ENV === 'production',
  STAGING: process.env.REACT_APP_ENV === 'staging',
  DEVELOPMENT: process.env.REACT_APP_ENV === 'development',
};

export const SENTRY_DSN = process.env.SENTRY_DSN;
export const SENTRY_RELEASE = process.env.SENTRY_RELEASE;
export const SENTRY_ENVIRONMENT = ENV.PRODUCTION ? 'production' : 'testing';

export const TESTING = Boolean(localStorage.getItem('_testing'));
