import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { errorReducer } from './api/error.reducer';
import { lastFetchReducer } from './api/lastFetch.reducer';
import { loadingReducer } from './api/loading.reducer';
import monthlyReports from './capacity-overview/capacity-overview.reducers';
import feedback from './feedback/feedback.reducers';
import fileUpload from './file-upload/file-upload.reducers';
import data from './order-now/data/data.reducers';
import unsubscribe from './unsubscribe/unsubscribe.reducers';

export const rootReducer = combineReducers({
  api: combineReducers({
    loading: loadingReducer,
    error: errorReducer,
    lastFetch: lastFetchReducer,
  }),
  data,
  feedback,
  fileUpload,
  form: formReducer,
  monthlyReports,
  unsubscribe,
});
