import { faMoneyBillWave, faPencilPaintbrush, faBriefcase } from '@fortawesome/pro-light-svg-icons';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Button } from '~/common/components';
import { routes } from '~/constants';
import { background, layout } from './images';
import '~/styles/components/modals/_enterprise-offer-modal.scss';

export const EnterpriseOfferModal = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'enterprise',
      eventAction: 'launch-popup',
      eventLabel: 'viewed',
    });
  }, []);

  const handleButtonClick = () => {
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'enterprise',
      eventAction: 'launch-popup',
      eventLabel: 'clicked',
    });
    window.location.href = `${routes.public.enterprises}?utm_source=customer-area&utm_medium=banner&utm_campaign=popup`;
  };

  const offerList = enterpriseOfferModalC.map(({ icon, text }) => (
    <li key={text} className="enterprise-offer-modal__item">
      <div className="enterprise-offer-modal__image-container relative f-center">
        <img src={layout} alt="" className="enterprise-offer-modal__layout-image" />
        <FontAwesomeIcon icon={icon} className="relative info-300" />
      </div>
      <p className="enterprise-offer-modal__text text-center">{text}</p>
    </li>
  ));

  return (
    <div className="enterprise-offer-modal__container relative">
      <img src={background} alt="" className="enterprise-offer-modal__background-image" />
      <div className="relative">
        <h2 className="enterprise-offer-modal__title text-center">
          In 2022 we saved our enterprise clients <i>over 100,000</i> work hours
        </h2>
        <p className="enterprise-offer-modal__message text-center">
          Speak to us today and find out how we can <i>integrate 24Slides into the workflow</i> of
          everyone in your organization - whether you’re 1,000 people or 100,000 people.
        </p>
        <ul className="enterprise-offer-modal__list flex">{offerList}</ul>
        <div className="f-justify-center">
          <Button size="m" color="secondary" onClick={handleButtonClick}>
            Find out more
          </Button>
        </div>
      </div>
    </div>
  );
};

const enterpriseOfferModalC = [
  {
    icon: faMoneyBillWave,
    text: 'Individual billing and payment types for each team',
  },
  {
    icon: faUsers,
    text: 'Pricing that gets cheaper for everyone the more people that use the service',
  },
  {
    icon: faPencilPaintbrush,
    text: 'A custom portal and order form for your organization',
  },
  {
    icon: faBriefcase,
    text: 'Join many of the world’s leading companies and accelerate your workflow',
  },
];

export default EnterpriseOfferModal;
