/** Adds value to set efficiently and immutably */
export const add = <T>(set: Set<T>, value: T) => {
  const next = new Set(set);
  next.add(value);
  return next;
};

/** Removes value from set efficiently and immutably */
export const remove = <T>(set: Set<T>, value: T) => {
  const next = new Set(set);
  next.delete(value);
  return next;
};

/** Toggles a value in set efficiently and immutably */
export const toggle = <T>(set: Set<T>, value: T) => {
  const next = new Set(set);
  if (next.has(value)) {
    next.delete(value);
  } else {
    next.add(value);
  }
  return next;
};
