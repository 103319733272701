import banner1 from './banner-1.svg';
import banner2 from './banner-2.svg';
import banner3 from './banner-3.svg';
import dashboard from './dashboard.svg';
import discount from './discount.svg';
import manager from './manager.svg';
import orders from './orders.svg';
import savings from './savings.svg';
import slides from './slides.svg';
import teamPopup from './team-popup.svg';
import team from './team.svg';
import terminal from './terminal.svg';

export const banners = {
  team,
  savings,
  discount,
  dashboard,
  orders,
  manager,
  terminal,
  slides,
  teamPopup,
  banner1,
  banner2,
  banner3,
};
