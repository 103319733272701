import { UseQueryResult } from '@tanstack/react-query';

/**
 * Use this function inside of child components to get query data without
 * prop-drilling. If query data is not defined, then an error is thrown.
 *
 * Example:
 *
 * ```
 * const order = assertQueryData(useOrder());
 * ```
 */
export function assertQueryData<T, E>(result: UseQueryResult<T, E>): T {
  if (result.data === undefined) {
    throw new Error('Data is undefined');
  }
  return result.data;
}
