import { useForm, validator } from 'formoid';
import { InputField, ModalContentProps, ModalHeader, ModalShell } from '~/common/components';
import { customValidator, formatDateShort } from '~/common/utils';
import { useBillingData, useCancelSubcription } from '../hooks';

const CANCELLATION_REASON_MIN_LENGTH = 10;

export const CancelSubscription = ({ onClose }: ModalContentProps) => {
  const { subscription } = useBillingData();
  const { mutateAsync, isLoading } = useCancelSubcription();

  const { fieldProps, handleSubmit } = useForm({
    initialValues: { cancellationReason: '' },
    validationStrategy: 'onSubmit',
    validators: () => ({
      cancellationReason: validator.sequence(
        customValidator.required<string>('We really need to hear your feedback🥺'),
        validator.minLength(
          CANCELLATION_REASON_MIN_LENGTH,
          'Please try to be as precise with description as you can',
        ),
      ),
    }),
  });

  const submit = () =>
    handleSubmit((values) => mutateAsync({ data: values, id: subscription!.id }).then(onClose));

  return (
    <>
      <ModalHeader
        onClose={onClose}
        className="md:w-[616px]"
        title="Sorry to see you go!"
        subtitle={`Once you cancel, your subscription will remain active until the end of your billing period on ${formatDateShort(
          subscription!.renewsAt,
        )}. After that, you won't be able to use the benefits of the Pro subscription.`}
      />
      <ModalShell
        submitText="Cancel subscription"
        cancelText="Go back"
        onClose={onClose}
        onSubmit={submit}
        loading={isLoading}
      >
        <InputField
          type="text"
          title="Why do you want to cancel?"
          required
          placeholder="Enter your feedback"
          {...fieldProps('cancellationReason')}
        />
      </ModalShell>
    </>
  );
};
