import { useLayoutEffect } from 'react';

export const useEqualMaxHeight = (selector: string) => {
  // TODO I don't know a good way to align images in cards with different
  // height text above and under the image
  useLayoutEffect(() => {
    const elements = Array.from(document.querySelectorAll(selector));
    const height = Math.max(...elements.map((element) => element.clientHeight));
    requestAnimationFrame(() => {
      elements.forEach(
        (element) => element instanceof HTMLElement && (element.style.height = `${height}px`),
      );
    });
  }, [selector]);
};
