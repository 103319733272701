import {
  FilterMap,
  FlatSearchParams,
  GenericItem,
  MultipleValue,
  OnFilterChange,
  SearchParamsMap,
  Sort,
  TransformedTableFilters,
  Value,
} from './types';

const isOption = <K>(sort: Sort<K> | K): sort is K => typeof sort === 'string';

export const getSort = <K>(sort: Sort<K> | K): Sort<K> | undefined => {
  if (isOption(sort)) {
    return {
      option: sort,
      order: 'asc',
    };
  }

  if (sort.order === 'desc') {
    return undefined;
  }

  return { ...sort, order: 'desc' };
};

const isValueString = (value: unknown) => typeof value === 'string';
const isValueBoolean = (value: string) => value === 'true' || value === 'false';
const isValueNumber = (value: string) => !isNaN(+value);

const isValueAnArray = (value: string) => {
  return value.startsWith('[') && value.endsWith(']');
};

const normalizeValue = (value: string) => {
  if (!isValueString(value))
    throw new TypeError(
      `Query normalization failed. Value: ${value} of type ${typeof value} must be a string!`,
    );
  if (isValueNumber(value)) {
    return Number(value);
  }
  if (isValueBoolean(value)) return JSON.parse(value);
  return value;
};

const isKeyBracketed = (key: string) => key.includes('[') && key.endsWith(']');

const getArrayValue = (value: string): string[] | number[] | undefined => {
  return value.slice(1, -1).split(',').map(normalizeValue);
};

const getPrimitiveValue = (value: string) => {
  return normalizeValue(value);
};

export const parseQuery = <T extends GenericItem>(search: string): SearchParamsMap<T> => {
  const searchParams = new URLSearchParams(search);
  const searchParamsMap: SearchParamsMap<T> = { filter: {} };
  for (const [spKey, spValue] of searchParams.entries()) {
    const parsedValue = isValueAnArray(spValue)
      ? getArrayValue(spValue)
      : getPrimitiveValue(spValue);
    if (isKeyBracketed(spKey)) {
      const [category, key] = spKey.slice(0, -1).split('[');
      if (category === 'filter') {
        if (key === 'sort') {
          const [option, order] = spValue.split(':');
          searchParamsMap.sort = { option, order } as SearchParamsMap<T>['sort'];
        } else {
          if (!searchParamsMap.filter) {
            searchParamsMap.filter = {};
          }
          searchParamsMap.filter[key] = parsedValue;
        }
      }
    } else searchParamsMap[spKey as keyof SearchParamsMap] = parsedValue;
  }
  return searchParamsMap;
};

export const getFlatQuery = <T extends GenericItem>(
  parsedQuery: SearchParamsMap<T>,
): FlatSearchParams => {
  const searchParams: FlatSearchParams = {};
  for (const [k, v] of Object.entries(parsedQuery.filter ?? {})) {
    if (v != null && v !== '') {
      if (Array.isArray(v)) {
        if (v.length) {
          searchParams[`filter[${k}]`] = `[${v.join(',')}]`;
        }
        continue;
      }
      searchParams[`filter[${k}]`] = String(v);
    }
  }

  if (parsedQuery.sort) {
    searchParams['filter[sort]'] = `${String(parsedQuery.sort.option)}:${parsedQuery.sort.order}`;
  }

  if (parsedQuery.page != null && parsedQuery.page !== 1) {
    searchParams.page = String(parsedQuery.page);
  }

  if (parsedQuery.limit != null) {
    searchParams.limit = String(parsedQuery.limit);
  }

  return searchParams;
};

export const encodeQueryString = <T extends GenericItem>(parsedQuery: SearchParamsMap<T>) => {
  return `?${Object.entries(getFlatQuery(parsedQuery))
    .map(([k, v]) => `${k}=${v}`)
    .join('&')}`;
};

export const getSearchFilterDataFromOptions = <T extends string>(
  options: T[],
  filterMap?: FilterMap<T>,
) => {
  const selectedFilter = options.find((option) => filterMap?.[option]) ?? options[0];
  return { selectedFilter, value: String(filterMap?.[selectedFilter] ?? '') };
};

export const getFilterProps =
  <T extends string, K extends T>(
    filters: TransformedTableFilters<T>,
    queryParamsFilter: FilterMap<K>,
    onFilterChange: OnFilterChange<K>,
  ) =>
  <TValue extends Value | MultipleValue>(filter: K) => ({
    options: filters[filter],
    value: queryParamsFilter[filter] as TValue,
    onChange: (value: TValue | undefined) => onFilterChange(filter, value),
  });

export const stringify = (value: Value | undefined) => String(value ?? '');
