import styles from './SubscriptionPlansVideo.module.scss';

export const SubscriptionPlansVideo = () => {
  return (
    <video
      title="Our Subscription Plans Explained"
      className={styles.explainedIframe}
      src="https://24slides.com/videos/our_subscription_plans_-_24slides_497942868.mp4"
      controls
    />
  );
};
