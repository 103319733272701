import { useEffect } from 'react';
import { Toast, useStaleBundle } from '~/common/components';
import { ENV } from '~/env';

const scriptUrls = Array.from(document.querySelectorAll('script'))
  .map((script) => {
    try {
      return new URL(script.src);
    } catch {
      return null;
    }
  })
  .filter((url): url is URL => url !== null && url.origin === window.location.origin)
  .map((url) => url.pathname);

export const useFreshTabs = () => {
  useEffect(() => {
    if (!ENV.PRODUCTION) {
      return;
    }

    const updateChannel =
      'BroadcastChannel' in window ? new BroadcastChannel('update_channel') : null;

    const checkStale = (html: string) => {
      if (!scriptUrls.every((url) => html.includes(url))) {
        useStaleBundle.setState(true);
        clearInterval(interval);
        updateChannel?.removeEventListener('message', updateListener);
      }
    };

    const interval = setInterval(async () => {
      // only poll when tab is active
      if (document.visibilityState === 'hidden') {
        return;
      }

      const html = await fetch('/').then((res) => res.text());
      updateChannel?.postMessage(html);
      checkStale(html);
    }, 30_000);

    const updateListener = ({ data }: MessageEvent<string>) => checkStale(data);
    updateChannel?.addEventListener('message', updateListener);

    return () => {
      clearInterval(interval);
      updateChannel?.removeEventListener('message', updateListener);
    };
  }, []);

  useEffect(() => {
    if (!ENV.PRODUCTION) {
      return;
    }

    const unsubscribe = useStaleBundle.subscribe(() => {
      Toast.info({
        title: 'New version is available',
        message: 'Reload the page to update',
        duration: Infinity,
      });
      unsubscribe();
    });

    return unsubscribe;
  }, []);
};
