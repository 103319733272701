import { colors } from '~/utils/palette';
import type { IconType } from './types';
const defaultProps = {
  fill: colors.graysuit,
  width: 14,
  height: 14,
};

const IconUpdateClock = ({ fill, width, height = width }: IconType) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5.538H8.727l2.13-2.194c-2.123-2.1-5.56-2.177-7.684-.077a5.357 5.357 0 000 7.622 5.474 5.474 0 007.685 0c1.058-1.05 1.586-2.271 1.586-3.811H14c0 1.54-.684 3.539-2.053 4.892-2.73 2.707-7.164 2.707-9.894 0-2.722-2.699-2.745-7.086-.015-9.784a6.99 6.99 0 019.839 0L14 0v5.538zm-6.611-1.65v3.306l2.722 1.618-.56.941-3.329-1.975v-3.89H7.39z"
      fill={fill}
    />
  </svg>
);

IconUpdateClock.defaultProps = defaultProps;
export default IconUpdateClock;
