import Loadable from 'react-loadable';

import PleaseWait from '~/components/loaders/please-wait';

export default Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "capacity-overview", webpackPrefetch: true */ './Capacity-overview'
    ),
  loading: () => <PleaseWait />,
});
