import { Button, Link, useModalContext } from '~/common/components';
import { routes } from '~/constants';
import { useForceRerender } from '~/hooks';
import { useInitData } from '~/root/Auth';
import { CookieSettingsModal } from './Modal/Content/CookieSettingsModal';

import '~/styles/components/_cookie-settings.scss';

export const COOKIES_SETTINGS = 'cookiesSettings';

//TODO a quick improvement we can make is move this piece of HTML to the bottom of the page and make it sticky
//This will prevent some interactive evements to be hidden by this overlay
export const CookieSettings = () => {
  const { isEu } = useInitData();
  const forceRerender = useForceRerender();
  const { modalOpener } = useModalContext();
  const acceptButtonText = isEu ? 'Accept all' : 'I accept';
  const cookiesSettings = window.localStorage.getItem(COOKIES_SETTINGS);
  const showCookieSettings = !cookiesSettings;

  const handleAccept = (thirdParties: boolean) => {
    window.localStorage.setItem(COOKIES_SETTINGS, JSON.stringify({ thirdParties }));
    forceRerender();
  };

  const handleAcceptClick = () => {
    handleAccept(true);
  };

  if (!showCookieSettings) {
    return null;
  }

  return (
    <div className="cookie-settings cookie-settings__wrapper">
      <div className="cookie-settings__container">
        <span className="cookie-settings__title">A quick note about our cookies</span>
        <div className="cookie-settings__content flex-between-start">
          <p className="cookie-settings__text">
            We use cookies so we can give you the best website experience possible and to provide us
            with anonymous data so we can improve our marketing efforts. Read our&nbsp;
            <Link size="m" href={routes.public.documents.cookies_policy}>
              cookie policy
            </Link>
            &nbsp;and&nbsp;
            <Link size="m" href={routes.public.documents.privacy_policy}>
              privacy policy
            </Link>
            .
          </p>
          <div className="flex items-center gap-3 smo:mt-2">
            {isEu && (
              <Button
                color="text"
                size="m"
                onClick={modalOpener(CookieSettingsModal, { onSubmit: handleAccept })}
              >
                Cookie settings
              </Button>
            )}
            <Button color="secondary" onClick={handleAcceptClick}>
              {acceptButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
