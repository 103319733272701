import { Button, ModalContentProps, ModalHeader, SwitcherButton } from '~/common/components';
import { useToggle } from '~/common/hooks';

type Props = ModalContentProps & {
  onSubmit: (thirdParties: boolean) => void;
};

export const CookieSettingsModal = ({ onSubmit, onClose }: Props) => {
  const [thirdParties, toggleThirdParties] = useToggle(true);

  const handleSaveClick = () => {
    onSubmit(thirdParties);
    onClose();
  };

  return (
    <>
      <ModalHeader onClose={onClose} title="Cookie settings" />
      <div className="px-3 md:px-4 pt-0 pb-3 max-w-[400px]">
        <p>We use cookies, some of them are essential, others are optional.</p>
        <hr className="border-other-300 my-3" />
        <div className="flex items-center justify-between mb-1">
          <h4 className="font-brand-h5 text-text-500">Neccessary</h4>
          <SwitcherButton enabled disabled />
        </div>
        <p className="mr-7">
          These cookies are necessary for the website and can’t be deactivated.
        </p>

        <div className="flex items-center justify-between mt-3 mb-1">
          <h4 className="font-brand-h5 text-text-500">Performance</h4>
          <SwitcherButton enabled={thirdParties} onClick={toggleThirdParties} />
        </div>
        <p className="mr-7">
          We share cookies with some third parties (like analytics partners) to provide us with
          anonymous data that allows to track your use of our website and give you the best website
          experience possible.
        </p>
        <Button className="w-full mt-3" color="secondary" onClick={handleSaveClick}>
          Save settings
        </Button>
      </div>
    </>
  );
};
