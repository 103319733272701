import WindowCloseIcon from 'mdi-react/WindowCloseIcon';
import { Button } from '~/common/components';
import backgroundImage from './bg.png';

interface ReferralPopupProps {
  credits: number;
  minOrderPrice: number;
  onClose: () => void;
}

export const ReferralPopup = ({ credits, minOrderPrice, onClose }: ReferralPopupProps) => (
  <div className="upsell__container referral__container">
    <img src={backgroundImage} alt="" className="upsell__background-image" />

    <WindowCloseIcon onClick={onClose} color="#fff" size={22} className="upsell__close" />

    <div className="upsell__content">
      <h2 className="upsell__title referral__title">
        Welcome to the world’s leading presentation redesign service
      </h2>
      <p className="upsell__regular referral__subtitle">{`Your $${credits} credit awaits`}</p>
      <p className="upsell__regular referral__regular">{`In order to qualify for the $${credits} credit, please make an order of at least $${minOrderPrice}. You’ll be able to use the $${credits} credit when paying, which is done once you approve our redesign of your presentation.`}</p>

      <div>
        <Button className="upsell__button bold" size="m" color="secondary" onClick={onClose}>
          Continue
        </Button>
      </div>
    </div>
  </div>
);
