import {
  faBriefcase,
  faPencilPaintbrush,
  faScrewdriverWrench,
  faSparkles,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { IconBox, Link } from '~/common/components';
import { routes } from '~/constants';
import { Item } from './Item';

export const ServicesContent = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-1 lg:gap-2">
      <div className="w-full space-y-1 lg:w-[332px]">
        <Link.Base href={routes.public.services} className="flex -outline-offset-2">
          <Item
            label="Presentation services"
            description="Custom slide decks that stand out"
            icon={<IconBox icon={faScrewdriverWrench} className="text-[#EF5C88]" size="m" />}
          />
        </Link.Base>
        <Link.Base className="flex -outline-offset-2" href={routes.public.dedicated_team}>
          <Item
            label="Dedicated team"
            description="Get a team of professional designers"
            icon={<IconBox icon={faUsers} className="text-[#8A6DD9]" size="m" />}
          />
        </Link.Base>
        <Link.Base className="flex -outline-offset-2" href={routes.public.enterprises}>
          <Item
            label="Enterprise solution"
            description="Custom solutions for your company"
            icon={<IconBox icon={faBriefcase} className="text-[#69C361]" size="m" />}
          />
        </Link.Base>
      </div>
      <div className="w-full space-y-1 lg:w-[332px]">
        <Link.Base className="flex -outline-offset-2" href={routes.public.custom_design}>
          <Item
            label="Major Projects and Rebranding"
            description="Large-scale corporate projects"
            icon={<IconBox icon={faSparkles} className="text-[#619AC3]" size="m" />}
          />
        </Link.Base>
        <Link.Base className="flex -outline-offset-2" href={routes.public.master_template}>
          <Item
            label="Branded & master template"
            description="Fully editable master templates"
            icon={<IconBox icon={faPencilPaintbrush} className="text-[#FFBA0A]" size="m" />}
          />
        </Link.Base>
      </div>
    </div>
  );
};
