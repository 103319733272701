export default {
  // Network constants
  GET_DATA_REQUEST: 'GET_DATA_REQUEST',
  GET_DATA_SUCCESS: 'GET_DATA_SUCCESS',
  GET_DATA_FAILURE: 'GET_DATA_FAILURE',

  COUPON_REQUEST: 'COUPON_REQUEST',
  COUPON_SUCCESS: 'COUPON_SUCCESS',
  COUPON_FAILURE: 'COUPON_FAILURE',
  RESET_COUPON: 'RESET_COUPON',

  ORDER_FORM_REQUEST: 'ORDER_FORM_REQUEST',
  ORDER_FORM_SUCCESS: 'ORDER_FORM_SUCCESS',
  ORDER_FORM_FAILURE: 'ORDER_FORM_FAILURE',
  ORDER_FORM_RESET: 'ORDER_FORM_RESET',

  GET_QUESTIONS_REQUEST: 'GET_QUESTIONS_REQUEST',
  GET_QUESTIONS_SUCCESS: 'GET_QUESTIONS_SUCCESS',
  GET_QUESTIONS_FAILURE: 'GET_QUESTIONS_FAILURE',

  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',

  UPDATE_NOTIFICATIONS_REQUEST: 'UPDATE_NOTIFICATIONS_REQUEST',
  UPDATE_NOTIFICATIONS_SUCCESS: 'UPDATE_NOTIFICATIONS_SUCCESS',
  UPDATE_NOTIFICATIONS_FAILURE: 'UPDATE_NOTIFICATIONS_FAILURE',

  DELIVERY_DATES_REQUEST: 'DELIVERY_DATES_REQUEST',
  DELIVERY_DATES_SUCCESS: 'DELIVERY_DATES_SUCCESS',
  DELIVERY_DATES_FAILURE: 'DELIVERY_DATES_FAILURE',

  REMOVE_SERVER_VALIDATION_ERROR: 'REMOVE_SERVER_VALIDATION_ERROR',
  RESET_SERVER_VALIDATION_ERRORS: 'RESET_SERVER_VALIDATION_ERRORS',
  RESTORE_DATA_FROM_LS: 'RESTORE_DATA_FROM_LS',
  RESET_DATA_PATH: 'RESET_DATA_PATH',

  UPDATE_STEPS: 'UPDATE_STEPS',

  // Component constants
  RESET_DETAILS: 'RESET_DETAILS',
  SET_TREATMENT: 'SET_TREATMENT',
  SET_STYLE: 'SET_STYLE',
  RESET_STYLE: 'RESET_STYLE',
  SET_STYLE_FILE: 'SET_STYLE_FILE',
  RESET_STYLE_FILE: 'RESET_STYLE_FILE',
  SET_TURNAROUND: 'SET_TURNAROUND',
  SET_ADDON: 'SET_ADDON',
  RESET_ADDON: 'RESET_ADDON',
  SET_GRAMMAR: 'SET_GRAMMAR',
  SET_SLIDES: 'SET_SLIDES',
  SET_SUBMIT: 'SET_SUBMIT',
  SET_SHARING_LINKS: 'SET_SHARING_LINKS',
  ADD_UPLOAD_FILE: 'ADD_UPLOAD_FILE',
  REMOVE_UPLOAD_FILE: 'REMOVE_UPLOAD_FILE',
  SET_BRIEF: 'SET_BRIEF',
  UPDATE_COMPONENT: 'UPDATE_COMPONENT',
  SET_SIGNUP_FIELD: 'SET_SIGNUP_FIELD',
  SET_CUSTOM_FIELDS: 'SET_CUSTOM_FIELDS',
  SET_STRIPE: 'SET_STRIPE',
  SET_COUPON: 'SET_COUPON',
  SET_UPSELL: 'SET_UPSELL',
  SET_COUPON_VALIDATION: 'SET_COUPON_INITIAL_VALIDATION',
  SET_RATIO: 'SET_RATIO',
  SET_CARD_TOKEN: 'SET_CARD_TOKEN',
  UPDATE_DETAILS: 'UPDATE_DETAILS',
  SET_PAYMENT_DETAILS: 'SET_PAYMENT_DETAILS',

  SET_CARD: 'SET_CARD',

  // Affect constants
  AFFECT_STYLES: 'AFFECT_STYLES',
  AFFECT_DELIVERYDATES: 'AFFECT_DELIVERYDATES',
  AFFECT_ADDONS: 'AFFECT_ADDONS',
  AFFECT_SLIDESNUMBER: 'AFFECT_SLIDESNUMBER',
  AFFECT_BRIEF: 'AFFECT_BRIEF',
};
