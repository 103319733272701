import { createContext, useContext } from 'react';
import { CardPaymentMethod } from '~/root/domain';
import { useOrderConfirmation } from '../hooks';

type ConfirmationContext = ReturnType<typeof useOrderConfirmation> & {
  setPaymentMethods: (cards: CardPaymentMethod[]) => void;
};

export const ConfrimationContext = createContext<ConfirmationContext | undefined>(undefined);

export const useConfirmationContext = () => useContext(ConfrimationContext) as ConfirmationContext;
