import { ZodType, ZodError, z } from 'zod';
import { getDateWithoutTime, joinTruthy } from './data';

export function formatZodError(error: ZodError, indent = false): string {
  const messages: string[] = [];

  error.issues.forEach((issue, index) => {
    const issuePath = issue.path.join('.');
    if (issue.code === 'invalid_union' && issue.unionErrors) {
      messages.push(
        `${issuePath}: invalid union`,
        issue.unionErrors.map((e) => formatZodError(e, true)).join('\n'),
      );
    } else {
      const message =
        'expected' in issue
          ? `expected: ${issue.expected}, received: ${issue.received}`
          : issue.message;
      messages.push(
        `${indent ? '  ' : ''}${issuePath}\n${indent ? '  ' : ''} └─ ${message}${
          indent && index !== error.issues.length - 1 ? '' : '\n'
        }`,
      );
    }
  });

  return messages.join('\n');
}

export class DecodingError extends Error {
  constructor(payload: z.ZodError, info?: string) {
    const message = joinTruthy(['Unexpected data:', info, formatZodError(payload)]);
    super(message);
    this.name = 'DecodingError';
  }
}

type DecodeParams<I> = {
  value: unknown;
  decoder: ZodType<I>;
  getInfo?: () => string;
};

type SafeDecodeResult<I> =
  | {
      success: null;
      error: DecodingError;
    }
  | {
      success: I;
      error: null;
    };

export const safeDecode = <I>(params: DecodeParams<I>): SafeDecodeResult<I> => {
  const result = params.decoder.safeParse(params.value);
  return result.success
    ? { success: result.data, error: null }
    : { success: null, error: new DecodingError(result.error, params.getInfo?.()) };
};

export const unsafeDecode = <I>(params: DecodeParams<I>): I => {
  const result = safeDecode(params);
  if (result.error !== null) {
    throw result.error;
  }
  return result.success;
};

export function decodeOrNull<I>(value: unknown, decoder: ZodType<I>): I | null {
  return safeDecode({ value, decoder }).success;
}

const dateRegex = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{6}Z$');
const timelessDateRegex = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');

/**
 * Common decoders
 */
export const DateFromIsoString = z
  .string()
  .refine((value) => dateRegex.test(value) || timelessDateRegex.test(value), {
    message: 'Invalid date string format',
  })
  .transform((value, ctx) => {
    // Convert the string to a Date object
    const date = new Date(value);
    if (!Number.isNaN(date.getTime())) {
      return date;
    }
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Invalid date',
    });
    return z.NEVER;
  });

export const TimelessDateFromIsoString = DateFromIsoString.transform((date) => {
  date.setMinutes(date.getTimezoneOffset());
  return date;
});

interface LinkBrand {
  readonly Link: unique symbol;
}

export type Link = string & LinkBrand;

export function isLink(value: string): value is Link {
  try {
    new URL('', value);
    return true;
  } catch {
    return false;
  }
}

export const Link = z.string().refine(isLink);

/**
 * FlashMessage
 */
export const FlashMessageSuccess = z.object({
  type: z.literal('success'),
  message: z.string(),
});

export const FlashMessageFailure = z.object({
  type: z.literal('error'),
  message: z.string(),
});

export const FlashMessage = z.union([FlashMessageSuccess, FlashMessageFailure]);

export type FlashMessage = z.infer<typeof FlashMessage>;

export const DateWithFallback = TimelessDateFromIsoString.catch(() => getDateWithoutTime());
