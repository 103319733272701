import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useModalContext, Button, Link, Card } from '~/common/components';
import { Spinner } from '~/components/loaders/Spinner';
import { CustomFieldModal } from './CustomFieldsModal';
import { CustomFieldsTable } from './CustomFieldsTable';
import { useCustomFields } from './hooks';

export const CustomFields = () => {
  const { modalOpener } = useModalContext();
  const customFields = useCustomFields();

  if (!customFields.data) {
    return (
      <Card className="h-full flex justify-center items-center">
        <Spinner />
      </Card>
    );
  }

  return (
    <Card>
      <div className="flex items-center gap-1 mb-3">
        <h2 className="font-brand-h6">Custom fields</h2>
        <Link href="https://help.24slides.com/en/articles/9026050-custom-fields">Learn more</Link>
      </div>

      <Button
        icon={faPlus}
        color="text"
        size="m"
        className="mb-3"
        onClick={modalOpener(CustomFieldModal)}
      >
        Add new custom field
      </Button>

      <CustomFieldsTable data={customFields.data} />
    </Card>
  );
};
