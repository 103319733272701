import dayjs from 'dayjs';
import { Slide, SlideWithAnnotations } from '../domain';
import { orderIdStorage } from './orderId';

const CUSTOMER_AREA_RELEASE_DATE = dayjs(1601204400000);

const isAddedBeforeRelease = (timestamp: number) =>
  CUSTOMER_AREA_RELEASE_DATE.isAfter(dayjs(timestamp));

const normalizeSlideNewness = {
  versions: (storage, slides: Slide[]) => {
    const slidesStorage = storage;
    slides.forEach(({ id: slideId, version, timestamp }) => {
      const slide = slidesStorage[slideId];

      if (isAddedBeforeRelease(timestamp * 1000)) {
        return;
      }

      if (version !== slide?.version) {
        slidesStorage[slideId] = { ...slide, version, versioned: version > 1 };
      }
    });
    return slidesStorage;
  },
  annotations: (storage, slides: SlideWithAnnotations[]) => {
    const slidesStorage = storage;
    slides.forEach(({ id: slideId, annotations }) => {
      annotations.forEach(({ id: annotationId, comments, timestamp }) => {
        const slide = slidesStorage[slideId];
        const commentAmount = comments.length;
        const storedAnnotation = slide?.annotations?.[annotationId];

        if (isAddedBeforeRelease(timestamp * 1000)) {
          return;
        }

        if (storedAnnotation) {
          if (commentAmount !== storedAnnotation.commentAmount) {
            storedAnnotation.commented = true;
            storedAnnotation.commentAmount = commentAmount;
          }
        } else {
          // It means a new annotation
          slidesStorage[slideId] = {
            ...slide,
            annotations: {
              ...slide?.annotations,
              [annotationId]: {
                commented: true,
                commentAmount,
              },
            },
          };
        }
      });
    });
    return slidesStorage;
  },
};

class SlidesNewness {
  // todo: describe types
  // todo: add class description

  versions = [];

  annotations = {};

  aside = [];

  init = {
    versions: (slides) => {
      const [slidesStorage] = this.getSlides();

      const normalizedSlidesStorage = normalizeSlideNewness.versions(slidesStorage, slides);

      this.setSlides(normalizedSlidesStorage);
      this.build.versions(normalizedSlidesStorage);
    },

    annotations: (slides) => {
      const [slidesStorage] = this.getSlides();

      const normalizedSlidesStorage = normalizeSlideNewness.annotations(slidesStorage, slides);

      this.setSlides(normalizedSlidesStorage);
      this.build.annotations(normalizedSlidesStorage);
    },
  };

  reset = () => {
    this.versions = [];
    this.annotations = {};
  };

  build = {
    versions: (slidesStorage) => {
      this.versions = Object.entries(slidesStorage)
        .map(([slideId, slide]) => (slide.versioned ? Number(slideId) : null))
        .filter(Boolean);

      this.build.aside();
    },

    annotations: (slidesStorage) => {
      this.annotations = Object.entries(slidesStorage).reduce((accumulator, [slideId, slide]) => {
        const annotations = accumulator;

        const comments = Object.entries(slide.annotations || []).reduce(
          (collection, [annotationId, { commented }]) =>
            commented ? { ...collection, [annotationId]: true } : collection,
          null,
        );

        if (comments) {
          annotations[slideId] = comments;
        }

        return annotations;
      }, {});

      this.build.aside();
    },

    aside: () => {
      /**
       * `Object.keys` method returns keys as `string`
       * `map` with `Number` constructor is used to make them numerical
       */
      const annotationKeys = Object.keys(this.annotations).map(Number);
      const updates = new Set(annotationKeys.concat(this.versions));
      this.aside = [...updates];
    },
  };

  skip = {
    version: (slideId) => {
      const [slidesStorage, orderId] = this.localStorage.get();
      const slides = slidesStorage[orderId];

      if (slides?.[slideId]?.versioned) {
        slides[slideId].versioned = false;

        this.localStorage.set(slidesStorage);
        this.build.versions(slides);

        return true;
      }

      return false;
    },

    annotation: (slideId, annotationId) => {
      const [slidesStorage, orderId] = this.localStorage.get();
      const slides = slidesStorage[orderId];

      if (slides?.[slideId]?.annotations?.[annotationId]?.commented) {
        slides[slideId].annotations[annotationId].commented = false;

        this.localStorage.set(slidesStorage);
        this.build.annotations(slides);

        return true;
      }

      return false;
    },
  };

  localStorage = {
    name: 'slides',

    get() {
      const id = orderIdStorage.get();

      const storage = JSON.parse(window.localStorage.getItem(this.name)) ?? {};

      return [storage, id];
    },

    set(slideStorage) {
      window.localStorage.setItem(this.name, JSON.stringify(slideStorage));
    },
  };

  getSlides = () => {
    const [slidesStorage, orderId] = this.localStorage.get();
    const slideStorage = slidesStorage[orderId] || {};

    return [slideStorage, slidesStorage];
  };

  setSlides = (slides = {}) => {
    const [slidesStorage, orderId] = this.localStorage.get();

    slidesStorage[orderId] = slides;
    this.localStorage.set(slidesStorage);
  };

  update = {
    annotations: (slideId, { id: annotationId, comments }) => {
      const [slidesStorage, orderId] = this.localStorage.get();
      const annotationStorage = slidesStorage[orderId]?.[slideId]?.annotations;

      slidesStorage[orderId][slideId].annotations = {
        ...annotationStorage,
        [annotationId]: {
          commented: false,
          commentAmount: comments.length,
        },
      };

      this.localStorage.set(slidesStorage);
    },
  };
}

export const slidesNewness = new SlidesNewness();
