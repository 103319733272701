import { colors } from '~/utils/palette';
import type { IconType } from './types';
const defaultProps = {
  fill: colors.graysuit,
  width: 10,
  height: 7,
};

const IconArrowDown = ({ fill, width, height = width }: IconType) => (
  <svg width={width} height={height} viewBox="0 0 10 7" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.175.158L5 3.975 8.825.158 10 1.333l-5 5-5-5L1.175.158z"
      fill={fill}
    />
  </svg>
);

IconArrowDown.defaultProps = defaultProps;
export default IconArrowDown;
