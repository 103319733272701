import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { IconBox } from '~/components';
import { StatusModal, StatusModalProps } from './StatusModal';

export const ErrorModal = ({
  icon = <IconBox icon={faExclamationTriangle} className="w-10 h-10 text-[62px] text-error-300" />,
  header,
  title = 'Error!',
  message = 'Something went wrong. Please try again or contact our support team for assistance.',
  loading,
  onClose,
  onSubmit,
}: StatusModalProps) => (
  <StatusModal {...{ icon, header, title, message, loading, onClose, onSubmit }} />
);
