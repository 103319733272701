/**
 * Cached order id to share it between functions and
 * axios requests for inner order page
 */
class OrderId {
  // `id` is private
  #id = '';
  set = (id: string) => {
    this.#id = id;
  };
  reset = () => {
    this.#id = '';
  };
  get = () => this.#id;
}

export const orderIdStorage = new OrderId();
