import { useLayoutEffect } from 'react';

export const useLockBodyScroll = (lock: boolean) => {
  useLayoutEffect(() => {
    const originalStyle = document.body.style.overflow;

    if (!lock) {
      return;
    }

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [lock]);
};
