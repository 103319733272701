import { useRef, useState, useEffect, InputHTMLAttributes, RefObject } from 'react';
import { ignoreHandled } from '../utils';
import { useEvent } from './useEvent';

export interface DropzoneProps<E extends HTMLElement> {
  onClick: React.MouseEventHandler<E>;
  onDragOver: React.DragEventHandler<E>;
  onDragLeave: React.DragEventHandler<E>;
  onDrop: React.DragEventHandler<E>;
}

const filterFiles = (files: FileList, mimeTypes: string[]): File[] =>
  Array.from(files).filter((file) => mimeTypes.includes(file.type));

/**
 * Provides callbacks that can be binded to element to make him behave like files dropzone
 *
 * Callbacks already wrapped with "ignoreHandled", so you can prevent unnecessary events propagation
 * by simply adding data-stop-propagation to elements that contains inside dropzone container
 * and shouldn't trigger this behavior (f.e delete buttons that clears already selected file)
 */

export const useFileDropzone = <E extends HTMLElement>(
  {
    onChange,
    multiple,
    disabled = false,
    acceptedMimeTypes = defaultMimeTypes,
  }: Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'type' | 'size' | 'value' | 'accept' | 'multiple'
  > & {
    acceptedMimeTypes?: string[];
  } & (
      | { multiple?: false; onChange: (file: File) => void }
      | {
          multiple: true;
          onChange: (files: File[]) => void;
        }
    ),
  containerRef?: RefObject<E>,
): [DropzoneProps<E>, boolean] => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDisabled, setIsDisabled] = useState(disabled);

  const handleChange = useEvent((event: Event) => {
    const target = event.target as HTMLInputElement;
    if (onChange && target.files && fileInputRef.current) {
      const allowedFiles = filterFiles(target.files, acceptedMimeTypes);

      if (allowedFiles.length) {
        multiple ? onChange(allowedFiles) : onChange(allowedFiles[0]);
      }

      // Clears file input for next file upload
      fileInputRef.current.value = '';
    }
  });

  const handleClick = ignoreHandled(() => {
    if (!isDisabled && fileInputRef.current) {
      fileInputRef.current.click();
    }
  });

  const handleDragOver = ignoreHandled((event: React.DragEvent) => {
    if (!isDisabled) {
      event.preventDefault();
      setIsDragging(true);
    }
  });

  const handleDragLeave = ignoreHandled(() => {
    if (!isDisabled) {
      setIsDragging(false);
    }
  });

  const handleDrop = ignoreHandled((event: React.DragEvent) => {
    if (!isDisabled && fileInputRef.current) {
      event.preventDefault();
      setIsDragging(false);

      if (event.dataTransfer.files) {
        const files = filterFiles(event.dataTransfer.files, acceptedMimeTypes);
        multiple ? onChange(files) : onChange(files[0]);
      }

      // Clears file input for next file upload
      fileInputRef.current.value = '';
    }
  });

  useEffect(() => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.style.display = 'none';

    fileInput.addEventListener('change', handleChange);

    const container = containerRef?.current ?? document.body;

    container.appendChild(fileInput);
    fileInputRef.current = fileInput;

    return () => {
      container.removeChild(fileInput);
    };
  }, [containerRef, handleChange]);

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = acceptedMimeTypes?.join(', ');
      fileInputRef.current.multiple = Boolean(multiple);
    }
  }, [acceptedMimeTypes, multiple]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  return [
    {
      onClick: handleClick,
      onDragOver: handleDragOver,
      onDragLeave: handleDragLeave,
      onDrop: handleDrop,
    },
    isDragging,
  ];
};

const defaultMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  'image/svg+xml',
  'image/bmp',
  'image/webp',
  'image/vnd.adobe.photoshop',
  'video/mp4',
  'application/postscript',
  'font/otf',
  'font/ttf',
  'font/woff',
  'font/woff2',
  'text/plain',
  'application/vnd.ms-excel',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/xls',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint [official]',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/mspowerpoint',
  'application/ms-powerpoint',
  'application/mspowerpnt',
  'application/vnd-mspowerpoint',
  'application/powerpoint',
  'application/x-powerpoint',
  'application/x-m',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/msword',
  'application/pdf',
  'application/zip',
  'application/x-zip-compressed',
  'application/x-rar-compressed',
  'application/x-7z-compressed',
  'application/vnd.ms-word.document.macroenabled.12',
  'application/vnd.ms-word.template.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-iwork-keynote-sffkey',
  'application/x-iwork-pages-sffpages',
  'application/x-iwork-numbers-sffnumbers',
  'application/x-photoshop',
  'application/photoshop',
  'application/psd',
  'application/vnd.apple.keynote',
  'application/vnd.apple.pages',
  'application/vnd.apple.numbers',
  'application/octet-stream',
  'multipart/x-zip',
];
