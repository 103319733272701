import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { ZodError, z } from 'zod';
import { formatZodError } from '~/common/utils';
import { ENV } from '~/env';

export const errorContains = (error: unknown, ...substrings: (string | number)[]) =>
  Boolean(error instanceof Error && error.message.match(new RegExp(substrings.join('|'))));

export class RedirectError extends Error {
  redirectTo: string;

  constructor({ redirectTo }: { redirectTo: string }) {
    super();
    this.name = 'RedirectError';
    this.message =
      "If you see this error don't panic, this is to stop js execution on redirects.\n \nRedirect from: " +
      window.location.pathname +
      '\nRedirect to: ' +
      redirectTo;
    this.redirectTo = redirectTo;
  }
}

const handleError = (error: unknown) => {
  if (error instanceof RedirectError) {
    return (window.location.href = error.redirectTo);
  }
  if (!ENV.DEVELOPMENT) {
    console.error(error instanceof ZodError ? formatZodError(error) : error);
    // TODO let's check if we're going to get flooded with errors
    captureException(error);
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 45000,
      // won't refetch when opening a modal that is subscribed to react-query cache
      refetchOnMount: true,
      // will refetch when going to another browser tab and back
      refetchOnWindowFocus: true,
      retry: (count, error) => {
        if (errorContains(error, 401, 403, 404, 500) || error instanceof z.ZodError) {
          return false;
        }
        return !ENV.PRODUCTION && count < 2;
      },
      onError: handleError,
    },
    mutations: {
      onError: handleError,
    },
  },
});
