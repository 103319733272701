import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NonEmptyArray } from 'formoid/lib/Array';
import { ComponentPropsWithoutRef, forwardRef, MouseEvent } from 'react';
import { cx, Overwrite } from '~/common/utils';
import { IconBox } from '../IconContainers';
import { Errors } from './Errors';
import { FormElementLabel, FormElementLabelProps } from './FormElementLabel';
import css from './Input.module.scss';

export type TextAlignment = 'left' | 'center' | 'right';

type Props = Overwrite<
  ComponentPropsWithoutRef<'input'>,
  FormElementLabelProps &
    Partial<{
      icon: IconProp;
      error: boolean;
      errors: null | NonEmptyArray<string>;
      inputClassName: string;
      textAlignment: TextAlignment;
      size: 's' | 'm' | 'l';
    }>
>;

export const Input = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      inputClassName,
      required,
      icon,
      error,
      errors,
      title,
      hint,
      hintClickable,
      children,
      textAlignment,
      size = 'l',
      ...props
    },
    ref,
  ) => {
    // this is needed to prevent annoying input blurring and re-focusing
    const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => e.preventDefault();

    return (
      <label
        className={cx(
          css.wrapper,
          {
            [css.error]: error,
            [css.disabled]: props.disabled,
            [css.readOnly]: props.readOnly,
            '!text-center': textAlignment === 'center',
            '!text-left': textAlignment === 'left',
            '!text-right': textAlignment === 'right',
          },
          className,
        )}
      >
        <FormElementLabel
          title={title}
          hint={hint}
          required={required}
          hintClickable={hintClickable}
          disabled={props.disabled}
        />
        <div className={cx(css.inputWrapper, css[size])} ref={ref}>
          {icon && <IconBox className={css.icon} icon={icon} onMouseDown={handleMouseDown} />}
          <input {...props} className={cx(css.input, inputClassName)} />
          {children && (
            <div onMouseDown={handleMouseDown} className={css.children}>
              {children}
            </div>
          )}
        </div>
        {errors && <Errors errors={errors} />}
      </label>
    );
  },
);
