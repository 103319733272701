import { faCog } from '@fortawesome/pro-duotone-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { Button, Link, Tooltip, useModalContext, withModalContext } from '~/common/components';
import { cx } from '~/common/utils';
import { LoginModal } from '~/components/Modal/Modals';
import { routes } from '~/constants';
import { ENV, PUBLIC_PAGES_URL, SERVER_URL } from '~/env';
import { useScreenSize } from '~/hooks';
import { useCurrentUser } from '~/root/Auth';
import { logoutRequest } from '~/root/Auth/utils';
import { ResourcesContent, ServicesContent } from '~/root/Navigation';
import { BCorpLogo } from './BCorpLogo';
import { TFLogo, TFLogoDark } from './images';
import { MobileMenu } from './MobileMenu';
import { SubsectionMenu } from './SubsectionMenu';
import { getItemClassName, Theme } from './utils';

const themes = {
  primary: {
    logo: TFLogoDark,
    bCorp: 'text-text-100',
    background: 'bg-text-600',
    separator: 'bg-text-100',
    mobileMenuColor: '#fff',
  } as const,
  secondary: {
    logo: TFLogo,
    bCorp: 'text-text-600',
    background: 'bg-text-100',
    separator: 'bg-other-400',
    mobileMenuColor: '#1E2860',
  } as const,
};

// TODO: This one can be changed when order form logic will be redone, so...
const orderFormRegExp = /step\/\d$/;

export const Header = withModalContext(() => {
  const screenSize = useScreenSize();
  const { modalOpener } = useModalContext();
  const user = useCurrentUser();
  const headerRef = useRef<HTMLHeadingElement>(null);
  const [theme, setTheme] = useState<Theme>('primary');

  // Change header theme on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const headerHeight = headerRef.current.offsetHeight;
        if (window.scrollY > headerHeight) {
          setTheme('secondary');
        } else {
          setTheme('primary');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onOrderForm = orderFormRegExp.test(location.pathname);
  const onSignupOrLogin = ([routes.login, routes.signup] as string[]).includes(location.pathname);

  return (
    <header
      ref={headerRef}
      className={cx(
        'sticky top-0 z-30 flex flex-none items-center justify-between px-4 h-8 lg:h-9 transition-colors duration-300 ease-in',
        themes[theme].background,
      )}
    >
      <div className="flex items-center gap-2">
        <a href={PUBLIC_PAGES_URL}>
          <img className="w-[88px]" src={themes[theme].logo} alt="Logo" />
        </a>
        <hr className={cx('h-3 w-[1px] text-transparent', themes[theme].separator)} />
        <Tooltip
          placement="bottom"
          content="In October 2018, 24Slides became a Certified B Corporation which means we meet high standards of social and environmental impact, public transparency, and legal accountability to balance profit and purpose."
        >
          <BCorpLogo className={cx('w-[17px]', themes[theme].bCorp)} />
        </Tooltip>

        <UnauthenticatedNavigation theme={theme} />

        {!ENV.PRODUCTION && (
          <preview-switcher
            placement="bottom-start"
            development={ENV.DEVELOPMENT}
            server={SERVER_URL}
          >
            <div className="flex items-center justify-center h-3 w-3 lg:h-5 lg:w-5">
              <Button.Icon
                icon={faCog}
                className="!text-text-100 opacity-0 hover:opacity-100 transition-brand"
              />
            </div>
          </preview-switcher>
        )}
      </div>

      <div className="flex items-center gap-2 lg:gap-4">
        {!onSignupOrLogin && (
          <Button
            color="text"
            className={cx(
              '!font-brand-l2',
              theme === 'primary' ? '!text-text-100' : '!text-text-600',
            )}
            onClick={user ? logoutRequest : modalOpener(LoginModal)}
          >
            {user ? 'Log out' : 'Log in'}
          </Button>
        )}

        {onOrderForm && user && (
          <Link.Button
            to={routes.profile.orders}
            color={theme === 'primary' ? 'secondary' : 'secondary-navy'}
            className="!font-brand-l2 hidden lg:flex"
            size="m"
          >
            My orders
          </Link.Button>
        )}
        {!onOrderForm && (
          <Link.Button
            to={routes.order}
            color={theme === 'primary' ? 'secondary' : 'secondary-navy'}
            className="!font-brand-l2 hidden lg:flex"
            size="m"
          >
            Order now
          </Link.Button>
        )}

        {screenSize !== 'lg' && <MobileMenu color={themes[theme].mobileMenuColor} />}
      </div>
    </header>
  );
});

const UnauthenticatedNavigation = ({ theme }: { theme: Theme }) => {
  const user = useCurrentUser();

  if (user) {
    return null;
  }

  const itemClassName = getItemClassName(theme);

  return (
    <div className="hidden lg:flex items-center gap-5 ml-5">
      <SubsectionMenu label="Services" theme={theme} content={<ServicesContent />} />
      <Link.Base href={routes.public.examples} className={itemClassName}>
        Our work
      </Link.Base>
      <Link.Base href={routes.public.pricing.slides} className={itemClassName}>
        Pricing
      </Link.Base>
      <Link.Base href={routes.public.about.index} className={itemClassName}>
        About us
      </Link.Base>
      <SubsectionMenu label="Resources" theme={theme} content={<ResourcesContent />} />
    </div>
  );
};
