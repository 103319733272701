import { useCallback, useReducer } from 'react';
import { ConfirmationDetails } from '~/pages/My-profile/components/InnerOrderPage/domain';

import type { PayWithMethodType } from '../domain';
import { getInitialCreditPackage, getInitialPaymentMethod } from '../utils';

type OrderConfirmationArugmentsType = Pick<
  ConfirmationDetails['billing'],
  'paymentMethods' | 'creditBalance' | 'creditPackages'
> & {
  enoughCredits: boolean;
};

type ConfirmationStateType = {
  /** a tab user is on */
  payWithMethod: PayWithMethodType;
  /** selected credit package id */
  creditPackageId: string | number;
  /** selected payment method id */
  paymentMethodId: string | number;
  /** false if most expensive package + available credits won't cover this order */
  isToppingUp: boolean;
};

type ConfirmationActionsType =
  | {
      type: 'METHOD';
      method: PayWithMethodType;
    }
  | {
      type: 'PACKAGE';
      id: string;
    }
  | {
      type: 'PAYMENT';
      id: string;
    }
  | {
      type: 'TOP_UP';
      isToppingUp: boolean;
    };

export const confirmationReducer = (
  state: ConfirmationStateType,
  action: ConfirmationActionsType,
) => {
  switch (action.type) {
    case 'METHOD':
      return { ...state, payWithMethod: action.method };

    case 'PACKAGE':
      return { ...state, creditPackageId: action.id };

    case 'PAYMENT':
      return { ...state, paymentMethodId: action.id };

    case 'TOP_UP':
      return { ...state, isToppingUp: action.isToppingUp };
  }
};

export const useOrderConfirmation = ({
  enoughCredits,
  creditBalance,
  creditPackages,
  paymentMethods,
}: OrderConfirmationArugmentsType) => {
  const initialValues: ConfirmationStateType = {
    payWithMethod: creditBalance > 0 ? 'credits' : 'card',
    creditPackageId: getInitialCreditPackage(enoughCredits, creditPackages),
    paymentMethodId: getInitialPaymentMethod(paymentMethods),
    isToppingUp: !!creditPackages.length,
  };
  const [state, dispatch] = useReducer(confirmationReducer, initialValues);
  const setPayWithMethod = useCallback(
    (method: PayWithMethodType) => () => {
      dispatch({
        type: 'METHOD',
        method,
      });
    },
    [],
  );
  const setPackage = useCallback((event?: React.SyntheticEvent<EventTarget>) => {
    // `event` can be undfined because of clear action
    const id = event?.target?.value || '';
    dispatch({
      type: 'PACKAGE',
      id,
    });
  }, []);
  const setPaymentMethod = useCallback((event: React.SyntheticEvent<EventTarget>) => {
    dispatch({
      type: 'PAYMENT',
      id: event.target.value,
    });
  }, []);
  const setTopUpBind = useCallback(
    (isToppingUp: boolean) => () => {
      dispatch({
        type: 'TOP_UP',
        isToppingUp,
      });
    },
    [],
  );
  return {
    ...state,
    setPayWithMethod,
    setPackage,
    setPaymentMethod,
    setTopUpBind,
  };
};
