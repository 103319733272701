import { useUserRole } from './hooks';
import { ManagerNavigation, RegularNavigation, SubscriptionNavigation } from './roles';

export const AuthenticatedNavigation = () => {
  const userRole = useUserRole();

  return (
    <>
      {userRole === 'subscription' && <SubscriptionNavigation />}
      {userRole === 'manager' && <ManagerNavigation />}
      {userRole === 'regular' && <RegularNavigation />}
    </>
  );
};
