import cx from 'classnames';
import { formatMoney } from '~/common/utils';
import { CreditPackage } from '~/root/domain';
import type { PayWithMethodType, PaymentDetailsTotal, PaymentDetailsVat } from '../domain';
import { ConfirmationDetails } from '../domain';
import VatHint from './VatHint';

type Props = {
  creditBalance: number;
  payWithMethod: PayWithMethodType;
  order: ConfirmationDetails['order'];
  currentPackage?: CreditPackage;
  vat: PaymentDetailsVat;
  total: PaymentDetailsTotal;
};

const PaymentDetails = ({
  creditBalance,
  payWithMethod,
  order: { unit, discount },
  currentPackage,
  vat,
  total,
}: Props) => {
  if (payWithMethod === 'credits') {
    return (
      <>
        <div className="total-to-pay">
          <h4 className="semibold">Total to pay</h4>
          {/* `total.pay` can be `$0` if enough credits and a user doesn't buy a credit package */}
          {/* In this case, the order price will be shown which will be paid by credits only */}
          <span className="cpa__amount semibold">${total.pay || total.order}</span>
        </div>
        <ul>
          <li className="f-justify-between dots semibold">
            <span>Credit balance</span>
            <span>${creditBalance}</span>
          </li>
          {!!vat.credits && currentPackage && (
            <>
              <li className="f-justify-between dots semibold">
                <span>{currentPackage.name} package</span>
                <span>{formatMoney(currentPackage.credits)}</span>
              </li>
              <VatHint
                className="f-justify-between dots with-margin question-circle pointer"
                vat={vat.credits}
                vatPercent={vat.percent}
              />
            </>
          )}
          <li className="f-justify-between dots semibold">
            <span>Order total</span>
            <span>&ndash; ${total.order}</span>
          </li>
          <li className="f-justify-between dots with-margin">
            <span>Order price</span>
            <span>${unit}</span>
          </li>
          <li className="f-justify-between dots with-margin">
            <span>Discount</span>
            <span>&ndash; ${discount}</span>
          </li>
          {!!vat.order && (
            <VatHint
              className="f-justify-between dots with-margin question-circle pointer"
              vat={vat.order}
              vatPercent={vat.percent}
            />
          )}
        </ul>
        <span className="cpa__total f-between-center">
          Credits Remaining
          <span
            className={cx('margin-left', {
              terracotta: !total.remaining,
            })}
          >
            ${total.remaining}
          </span>
        </span>
      </>
    );
  }

  return (
    <>
      <div className="total-to-pay">
        <h4 className="semibold">Total to pay</h4>
        <span className="cpa__amount semibold">${total.order}</span>
      </div>
      <ul>
        <li className="f-justify-between dots">
          <span>Order price</span>
          <span>${unit}</span>
        </li>
        <li className="f-justify-between dots">
          <span>Discount</span>
          <span>&ndash; ${discount}</span>
        </li>
        <VatHint
          className="f-justify-between dots question-circle pointer"
          vat={vat.order}
          vatPercent={vat.percent}
        />
      </ul>
      <span className="cpa__total f-between-center">
        Order total <span>${total.order}</span>
      </span>
    </>
  );
};

export default PaymentDetails;
