import dayjs, { ConfigType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

/**
 * Gets date without time to calculate date intervals
 * correctly with timeless dates coming from backend
 */
export function getDateWithoutTime(date = new Date()) {
  date.setHours(0, 0, 0, 0);
  return date;
}
export function addHours(hours: number) {
  return dayjs().add(hours, 'hours');
}

export function compensateDateOffset(date: Date) {
  // for some reason, date.setMinutes(date.getTimezoneOffset()) works fine in
  // -7 timezone, in +2, but doesn't work correctly for +5:30 timezone, wow
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}

export function formatDayFullMonthYear(date: ConfigType) {
  return dayjs(date).format('D MMMM YYYY');
}

export function formatYearMonthDay(date: ConfigType) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function formatDateFull(date: ConfigType) {
  return dayjs(date).format('h:mm A, DD MMMM, YYYY');
}

export function formatDateShort(date: ConfigType): string {
  return dayjs(date).format('D MMM YYYY');
}

export const getTimezone = (): string => {
  const timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;
  const minutes = Math.abs(timezoneOffset - Math.trunc(timezoneOffset));
  return `${timezoneOffset >= 0 ? '+' : ''}${Math.trunc(timezoneOffset)}${
    minutes === 0 ? ':00' : `:${minutes * 60}`
  }`;
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getFullMonthName = (monthIndex: number) => monthNames[monthIndex];

/**
 * Regular expression to match ISO 8601 format
 */
export const isISODateString = (dateString: string) => {
  const isoRegex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})?)$/;

  return isoRegex.test(dateString);
};
