import { Button } from '~/common/components';
import googleSlidesTooltip from './images/google-slides-tooltip.png';

export const ShareGooglePresentationModal = ({ onClose }: { onClose: () => void }) => (
  <div className="security-modal__container">
    <h1 className="security-modal__title">How to share your Google Slides Presentation</h1>
    <img src={googleSlidesTooltip} alt="How to share your Google Slides Presentation" />
    <div className="security-modal__content__button-section">
      <Button onClick={onClose} size="m" color="secondary">
        Continue
      </Button>
    </div>
  </div>
);
