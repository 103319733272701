import { cx } from '~/common/utils';
import { authProviders } from '~/images/Brands';
import { useOauthLogin } from '~/root/Auth';
import css from './OauthProviders.module.scss';

export const OauthProviders = ({
  className,
  inline = false,
}: {
  className?: string;
  inline?: boolean;
}) => {
  const { mutateAsync, isLoading } = useOauthLogin();

  return (
    <div className={cx('space-y-3 font-brand-b3', className)}>
      <div className={cx('flex', inline ? 'smo:flex-col gap-3 smo:gap-2' : 'flex-col gap-3')}>
        {authServicesContent.map(({ id, name, icon }) => (
          <button
            key={id}
            disabled={isLoading}
            className="flex items-center gap-2 text-primary-300 border border-other-400 rounded w-full h-6 px-[12px]"
            onClick={() => mutateAsync({ provider: id })}
          >
            <img src={icon} alt={name} />
            Continue with {name}
          </button>
        ))}
      </div>
      <div
        className={cx(
          css.divider,
          'relative text-center font-brand-b3',
          inline && 'before:w-[45%] after:w-[45%]',
        )}
      >
        or
      </div>
    </div>
  );
};

const authServicesContent = [
  {
    id: 'google',
    name: 'Google',
    icon: authProviders.google,
  },
  {
    id: 'microsoft',
    name: 'Microsoft',
    icon: authProviders.microsoft,
  },
] as const;
