import otherExplain1 from '~/images/OtherExplain/other-explain-1.jpg';
import otherExplain2 from '~/images/OtherExplain/other-explain-2.jpg';
import otherExplain3 from '~/images/OtherExplain/other-explain-3.jpg';
import otherExplain4 from '~/images/OtherExplain/other-explain-4.jpg';

export const otherExplain = {
  otherExplain1,
  otherExplain2,
  otherExplain3,
  otherExplain4,
};
