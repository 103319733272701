import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { getLinkInfo } from '~/common/utils';
import { SERVER_URL } from '~/env';
import { axios, qk } from '~/root';
import { SignupRequest, LoginRequest, loginSchema, signupSchema, CustomerUser } from '../domain';
import { authorizeUser, reportReturningWhenOrdering } from '../utils';
import { useSetUser } from './init';
import { useOauthLogin } from './oauth';

export const useLogin = () => {
  const { mutateAsync: oauthLogin } = useOauthLogin();
  const setUser = useSetUser();

  return useMutation({
    mutationKey: qk.login,
    mutationFn: (data: LoginRequest) =>
      axios
        .post('/v1/users/login', data)
        .then((res) => {
          const user = loginSchema.parse(res.data);

          // Just TS fix, user can be staff too)
          setUser(user as CustomerUser);
          authorizeUser(user);
          reportReturningWhenOrdering();

          return user;
        })
        .catch(async (error) => {
          // TODO: Check is it checked on axios interceptors level and probably can be removed
          const redirectUrl = error.response?.data?.redirectUrl;

          if (error instanceof AxiosError && error.response?.status === 412 && redirectUrl) {
            window.location.href = redirectUrl;
            return error;
          }

          if (redirectUrl && error.response?.data.channel) {
            await oauthLogin({ provider: 'google', oauthData: error.response?.data });
          }

          return Promise.reject(error);
        }),
  });
};

export const useSignup = () => {
  const history = useHistory();

  return useMutation({
    mutationKey: qk.signup,
    mutationFn: (payload: SignupRequest) =>
      axios.post('/v1/users/register', payload).then((res) => {
        const signup = signupSchema.parse(res.data);

        return signup;
      }),
    onSuccess: (data) => {
      // TODO staging-only simulation of validating email
      if (data.token) {
        window.location.href = `${SERVER_URL}/v1/users/verify/${data.token}`;
      }

      if (!data.token && data.redirectUrl) {
        const { client, url } = getLinkInfo(data.redirectUrl);
        if (client) {
          history.push(url);
        } else {
          window.location.href = url;
        }
      }
    },
  });
};
