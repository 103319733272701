import { validator, useForm } from 'formoid';
import {
  InputField,
  ModalContentProps,
  ModalHeader,
  ModalShell,
  PhoneInputField,
  Toast,
  useByDialCodeOptions,
} from '~/common/components';
import { Email, customValidator, propagateBackendValidationErrors } from '~/common/utils';
import { useCountriesData } from '~/hooks';
import { useCurrentUserData } from '~/root/Auth';
import { facebook } from '~/utils';
import { useBillingData } from '../hooks/useBilling';
import { useBookMeeting } from './hooks';
import { ContactFormNames } from './types';

interface BookMeetingProps extends ModalContentProps {
  title?: string;
  message?: string;
  contactForm: ContactFormNames;
  onBook?: () => void;
}

export const BookMeetingModal = ({
  title = 'Book a meeting',
  message = 'We’ll get notified and contact you as soon as possible',
  contactForm,
  onBook,
  onClose,
}: BookMeetingProps) => {
  const user = useCurrentUserData();
  const { getCountryById } = useCountriesData();
  const { company, billingAddress } = useBillingData();

  const bookMeetingMutation = useBookMeeting();

  const { fieldProps, setErrors, handleSubmit, errors } = useForm({
    initialValues: {
      name: user.name,
      dialCode: billingAddress.phoneCountryId
        ? getCountryById(billingAddress.phoneCountryId).dialCode
        : null,
      phone: billingAddress.phoneNumber || '',
    },
    validators: () => ({
      name: validator.minLength(2, 'The name is too short'),
      dialCode: customValidator.required<number | null>('Please select your country code'),
      phone: customValidator.required<string>('Please enter your phone number'),
    }),
    validationStrategy: 'onBlur',
  });

  const submit = () => {
    handleSubmit((values) => {
      const facebookPayload = {
        ph: `${values.dialCode}${values.phone}`,
        fn: user.name,
        email: user.email,
      };

      const payload = {
        ...values,
        email: user.email as Email,
        company: company?.name,
        country: billingAddress.country?.countryCode,
      };

      return bookMeetingMutation
        .mutateAsync({ contactForm, payload })
        .then(() => {
          onBook?.();
          facebook.track('CompleteRegistration', facebookPayload);

          Toast.success({
            message:
              'Your request has been submitted successfully. Our team will contact you shortly to schedule the call.',
          });
        })
        .catch((error: unknown) => {
          propagateBackendValidationErrors({ error, setErrors, formErrors: errors });
        });
    });
  };

  return (
    <>
      <ModalHeader
        onClose={onClose}
        title={title}
        subtitle={message}
        className="w-[343px] md:w-[616px]"
      />
      <ModalShell
        submitText="Book a meeting"
        onClose={onClose}
        onSubmit={submit}
        loading={bookMeetingMutation.isLoading}
        className="w-[343px] md:w-[616px] flex flex-col md:flex-row gap-3"
      >
        <InputField
          type="text"
          title="Your name"
          placeholder="Enter your full name"
          className="w-full md:w-1/2"
          {...fieldProps('name')}
        />
        <PhoneInputField
          title="Phone number"
          codeProps={fieldProps('dialCode')}
          phoneProps={fieldProps('phone')}
          className="w-full md:w-1/2"
          useOptions={useByDialCodeOptions}
        />
      </ModalShell>
    </>
  );
};
