import { useState, useEffect, useCallback } from 'react';
import { DESKTOP_WIDTH, MOBILE_WIDTH } from '~/common/kits/device';

const SCREEN_SIZES = {
  DESKTOP: 'lg',
  TABLET: 'md',
  MOBILE: 'sm',
} as const;

export const useScreenSize = () => {
  const [currentSize, setCurrentSize] = useState<'sm' | 'md' | 'lg'>(SCREEN_SIZES.DESKTOP);

  const handleScreenSize = useCallback(() => {
    const screenSize = window.innerWidth;

    if (screenSize < MOBILE_WIDTH) {
      setCurrentSize('sm');
    } else if (screenSize >= DESKTOP_WIDTH) {
      setCurrentSize('lg');
    } else {
      setCurrentSize('md');
    }
  }, []);

  useEffect(() => {
    handleScreenSize();

    window.addEventListener('resize', handleScreenSize);

    return () => window.removeEventListener('resize', handleScreenSize);
  }, [handleScreenSize]);

  return currentSize;
};
