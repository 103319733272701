import { faCircle, faCircleDot } from '@fortawesome/pro-regular-svg-icons';
import { FieldProps } from 'formoid';
import { NonEmptyArray } from 'formoid/lib/Array';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Errors, IconBox } from '~/common/components';
import { Overwrite, cx } from '~/common/utils';
import css from './Radio.module.scss';

// TODO we need to add tooltip icons here

// TODO interface is not ideal, for forms there are usually multiple radio
// boxes, that could be drawn using map or with plain jsx
//
// try to improve once used in a form
//
// this looks kinda ugly :shrug:
//
// <Radio
//   value={values.pickone === 'somevalue'}
//   onChange={() => fieldProps('pickone').onChange('somevalue')}
//   title="Some value"
// />

type Props = Overwrite<
  ComponentPropsWithoutRef<'input'>,
  {
    title: string;
    value: boolean;
    onChange: () => void;
    error?: boolean;
    errors?: NonEmptyArray<string> | null;
    touched?: boolean;
  }
>;

export const Radio = forwardRef<HTMLLabelElement, Props>(
  ({ className, title, value: checked, onChange, error, errors, touched, ...props }, ref) => (
    <label
      ref={ref}
      className={cx(css.wrapper, className, checked && css.checked, props.disabled && css.disabled)}
    >
      <input
        {...props}
        className="absolute -z-1 opacity-0 cursor-pointer"
        type="radio"
        checked={checked}
        onChange={onChange}
      />
      <IconBox icon={checked ? faCircleDot : faCircle} className={css.checkmark} />
      <span className={css.text}>{title}</span>
      {errors && <Errors errors={errors} />}
    </label>
  ),
);

export const RadioField = forwardRef<HTMLLabelElement, Props & FieldProps<boolean>>(
  ({ touched, ...props }, ref) => <Radio ref={ref} {...props} />,
);
