import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons';
import { Link } from '~/common/components';
import { TableConfig } from '~/common/kits/table';
import { formatDayFullMonthYear } from '~/common/utils';
import { PaymentsHistoryItem } from '../domain';

export const getTableConfig = (minified = false): TableConfig<PaymentsHistoryItem> => ({
  columns: [
    {
      key: 'id',
      name: 'ID',
      width: '88px',
    },
    {
      key: 'description',
      name: 'Title',
      width: 'minmax(390px, 1fr)',
    },
    {
      key: 'timestamp',
      name: 'Date',
      cellRenderer: ({ cellData }) => cellData && formatDayFullMonthYear(cellData),
      width: '162px',
    },
    {
      key: 'amount',
      name: 'Amount',
      width: '80px',
    },
    {
      key: 'status',
      name: 'Status',
      width: '100px',
    },
    {
      key: 'paymentMethod',
      name: 'Payment Method',
      cellRenderer: ({ cellData }) => cellData && cellData.replace('-', '  '),
      width: 'minmax(110px, 1fr)',
    },
    {
      key: 'invoiceUrl',
      name: 'Invoice',
      width: '86px',
      getClassName: () => 'pl-2',
      cellRenderer: ({ cellData }) =>
        cellData && <Link.Icon href={cellData} newTab icon={faArrowToBottom} />,
    },
  ],
  sortable: minified ? [] : ['id', 'timestamp', 'amount'],
});
