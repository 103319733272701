import { Toast } from '../components/Toast';
import { Any } from './app';

export const handleErrorMessage = (error: Any) => {
  // TODO BE should provide flashMessage instead
  const message = error?.data?.data?.message;
  if (message) {
    Toast.error({ message });
  }
};
