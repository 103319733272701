import { faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { Nothing, Table, useFrontendTable } from '~/common/kits/table';
import { Spinner } from '~/components/loaders/Spinner';
import { CustomFieldsList } from './domain';
import { tableConfig } from './table-config';

type Props = {
  data?: CustomFieldsList;
};

export const CustomFieldsTable = ({ data }: Props) => {
  const view = useFrontendTable({ data, tableConfig });

  if (!data || !view) {
    return (
      <div className="w-full flex justify-center items-center h-[472px]">
        <Spinner />
      </div>
    );
  }

  return (
    <Table
      {...view}
      containerClassName="-mx-3 md:-mx-4"
      noDataPlaceholder={
        <Nothing icon={faReceipt} className="h-[184px]" text="No custom fields yet" />
      }
    />
  );
};
