import { Card } from '~/common/components';
import { DateRangeFilter } from '~/common/kits/table';
import { usePaymentsHistory } from '../hooks';
import { PaymentsTable } from './PaymentsTable';

export const PaymentsHistory = () => {
  const paymentHistory = usePaymentsHistory();

  return (
    <Card
      title={
        <div className="flex justify-between sm:flex-col sm:items-start gap-x-2 gap-y-3 items-center">
          <span>Payments history</span>
          <DateRangeFilter className="sm:w-full !p-0" />
        </div>
      }
    >
      <PaymentsTable
        data={paymentHistory.data}
        containerClassName="-mx-3 md:-mx-4"
        paginationClassName="px-4 pb-2"
      />
    </Card>
  );
};
