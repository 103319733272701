import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { TextLink } from '~/components/ui/text-link';

const propTypes = {
  linkClassName: PropTypes.string,
  source: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const defaultProps = {
  linkClassName: null,
  onClick: () => {},
};

const ModalParser = ({ source, onClick }) => {
  if (!source) {
    console.error('ModalParser: `source` attribute is required');
    return null;
  }

  const addLineBreaks = (message) =>
    message.split('\\n').map((text) => (
      <Fragment key={text}>
        {text}
        <br />
      </Fragment>
    ));

  const parse = () => {
    const triggerLink = /\[.*?\]/.exec(source);

    if (!triggerLink) {
      return addLineBreaks(source);
    }

    const index = source.indexOf(triggerLink[0]);
    const text = source.slice(0, index);

    const trigger = triggerLink[0].replace(/[[\]]/g, '');

    return (
      <>
        {addLineBreaks(text)}
        <TextLink onClick={onClick}>{trigger}</TextLink>
      </>
    );
  };

  return parse();
};

ModalParser.propTypes = propTypes;
ModalParser.defaultProps = defaultProps;

export default ModalParser;
