import { Select, InputLabel, FormControl } from '@material-ui/core';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { Any } from '~/common/utils';
import Icon from '~/components/icons';

type Props = {
  clearable?: boolean;
  error?: boolean;
  className?: string;
  label?: string;
  value?: string | number;
  defaultValue?: string | number;
  children: ReactNode;
  classes?: {
    select: string;
  } | null;
  MenuProps?: {
    classes?: {
      list?: string;
      paper?: string;
    };
  };
  onChange: (arg0: React.SyntheticEvent<EventTarget>) => Any;
  onReset?: (arg0: Any) => Any;
};
const defaultProps = {
  clearable: false,
  error: false,
  value: '',
  className: '',
  label: '',
  classes: null,
  defaultValue: '',
  MenuProps: {},
  onReset: () => {},
};

const OrdersSelect = ({
  clearable,
  error,
  className,
  label,
  value,
  defaultValue,
  classes,
  MenuProps,
  children,
  onChange,
  onReset = () => {},
}: Props) => {
  const handleClear = () => {
    if (clearable) {
      onReset();
    }
  };

  const clearIcon = (
    <button type="button" className="clear" onClick={handleClear}>
      <Icon icon="times" width={24} height={24} />
    </button>
  );
  return (
    <FormControl className={cx('select-control relative', className)} error={error}>
      {clearable && value && clearIcon}
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        defaultValue={defaultValue}
        label={label}
        classes={classes}
        MenuProps={MenuProps}
        onChange={onChange}
      >
        {children}
      </Select>
    </FormControl>
  );
};

OrdersSelect.defaultProps = defaultProps;
export default OrdersSelect;
