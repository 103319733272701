import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';

import { SERVER_URL } from '~/env';
import { store } from '~/redux/store';
import { userStore } from '../globalStores';
import { posthog, dreamdata, ga } from '../services';
import { User } from './domain';

const getOrderFormAndUrl = () => ({
  order_form: store.getState()?.data?.details?.name,
  url: window.location.pathname,
});

const getGAInfo = (eventLabel: string) => {
  const info = {
    hitType: 'event',
    eventAction: 'auth_while_ordering',
    eventLabel,
    ...getOrderFormAndUrl(),
  };
  // eslint-disable-next-line no-console
  console.log('auth while ordering', info);
  return info;
};

export const authorizeUser = (user: User) => {
  userStore.setState({
    id: String(user.id),
    name: user.name,
    email: user.email,
    company: user.company,
    customerAccountId: user.customerAccountId,
  });

  ReactGA.set({ userId: user.id });
  dreamdata.identify(user.email);
  Sentry.setUser({ id: user.id, name: user.name, email: user.email || undefined });
  ga.dimension(user.customerType || undefined);
};

export const isUserOnOrderForm = () => window.location.pathname.match(/\/step\/\d/);

export const reportReturningWhenOrdering = () => {
  if (isUserOnOrderForm()) {
    ReactGA.send(getGAInfo('login'));
    posthog?.capture('login-while-ordering', getOrderFormAndUrl());
  }
};

export const reportNewWhenOrdering = () => {
  if (isUserOnOrderForm()) {
    ReactGA.send(getGAInfo('signup'));
    posthog?.capture('signup-while-ordering', getOrderFormAndUrl());
  }
};

const COOKIE = '_has_previously_logged_in';

export const hasPreviouslyLoggedIn = () => document.cookie.includes(COOKIE);

export const markAsPreviouslyLoggedIn = () => {
  try {
    const expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 1);

    if (!hasPreviouslyLoggedIn()) {
      document.cookie = `${COOKIE}=yes; expires=${expireDate}; path=/`;
    }
    // eslint-disable-next-line no-empty
  } catch {}
};

export function logoutRequest() {
  markAsPreviouslyLoggedIn();
  window.localStorage.removeItem('orderData');
  window.localStorage.removeItem('commentEvents');

  try {
    posthog?.reset();
    // eslint-disable-next-line no-empty
  } catch {}

  window.location.href = `${SERVER_URL}/v1/users/logout?redirect=${window.location.origin}`;
}
