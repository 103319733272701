import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Button, IconBox } from '~/common/components';
import { cx, formatMoney, ignoreHandled } from '~/common/utils';
import { Spinner } from '~/components/loaders/Spinner';
import { CreditPackage as CreditPackageType } from '~/root/domain';
import { blue, green, yellow } from './images';
import styles from './CreditsPackages.module.scss';

interface CreditsPackagesProps {
  creditsPackages?: CreditPackageType[];
  className?: string;
  prosIconClassName?: string;
  buyButtonClassName?: string;
  onPackageClick?: (creditsPackage: CreditPackageType) => void;
}

export const CreditsPackages = ({ creditsPackages, className, ...props }: CreditsPackagesProps) => {
  if (!creditsPackages) {
    return (
      <div className={cx('w-full h-[200px] flex justify-center items-center', className)}>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={cx(
        'flex flex-col lg:flex-row justify-between mdo:justify-center items-center lg:items-start gap-2 w-full',
        className,
      )}
    >
      {creditsPackages.map((creditsPackage) => (
        <CreditsPackage key={creditsPackage.id} creditsPackage={creditsPackage} {...props} />
      ))}
    </div>
  );
};

interface CreditsPackageProps extends Omit<CreditsPackagesProps, 'creditsPackages'> {
  creditsPackage: CreditPackageType;
}

export const CreditsPackage = ({
  className,
  creditsPackage,
  prosIconClassName,
  buyButtonClassName,
  onPackageClick,
}: CreditsPackageProps) => {
  const handleBuyClick = () => {
    onPackageClick?.(creditsPackage);
  };

  const creditsPackageProps = {
    creditsPackage,
    prosIconClassName,
    buyButtonClassName,
    onPackageClick: handleBuyClick,
  };

  return (
    <>
      <CreditsPackageWide
        className={cx('smo:hidden lg:hidden', className)}
        {...creditsPackageProps}
      />

      <CreditsPackageTall className={cx('mdo:hidden', className)} {...creditsPackageProps} />
    </>
  );
};

type CreditsPackageVariantProps = Omit<CreditsPackageProps, 'onPackageClick'> & {
  onPackageClick: () => void;
  className?: string;
};

const CreditsPackageTall = ({
  creditsPackage: { name, amount, credits, pros, color, discount },
  onPackageClick,
  className,
  prosIconClassName,
  buyButtonClassName,
}: CreditsPackageVariantProps) => {
  return (
    <div
      className={cx(
        'flex flex-col gap-2 p-4 min-w-[218px] border border-solid border-other-300 bg-white rounded-lg cursor-pointer',
        className,
        styles.creditsPackage,
      )}
      onClick={ignoreHandled(onPackageClick)}
    >
      <Sale percentage={discount.percentage} />
      <CreditsPackageInfo name={name} credits={credits} color={color} />
      <Price amount={amount} discount={discount} />
      <ProsList pros={pros} className="mt-3" prosIconClassName={prosIconClassName} />
      <Button
        data-stop-propagation
        color="secondary"
        className={cx('mt-4 w-full', buyButtonClassName)}
        onClick={onPackageClick}
      >
        Buy
      </Button>
    </div>
  );
};

const CreditsPackageWide = ({
  creditsPackage: { name, amount, credits, pros, color, discount },
  onPackageClick,
  className,
  prosIconClassName,
  buyButtonClassName,
}: CreditsPackageVariantProps) => {
  return (
    <div
      className={cx(
        'w-full inline-flex gap-2 justify-between px-3 pt-3 pb-1 border border-solid border-other-300 bg-white rounded-lg cursor-pointer',
        className,
        styles.creditsPackage,
      )}
      onClick={ignoreHandled(onPackageClick)}
    >
      <CreditsPackageInfo name={name} credits={credits} color={color} />
      <div className="flex flex-col items-center">
        <Sale percentage={discount.percentage} />
        <ProsList pros={pros} className="mt-3" prosIconClassName={prosIconClassName} />
      </div>
      <div className="flex flex-col items-center">
        <Price amount={amount} discount={discount} />
        <Button
          data-stop-propagation
          color="secondary"
          className={cx('mt-3', buyButtonClassName)}
          onClick={onPackageClick}
        >
          Buy
        </Button>
      </div>
    </div>
  );
};

const Sale = ({ percentage }: { percentage: CreditPackageType['discount']['percentage'] }) => (
  <span className="rounded font-brand-sm text-primary-400 bg-primary-200 px-1 py-[2px] whitespace-nowrap self-center">
    Sale {percentage}%
  </span>
);

export const ProsList = ({
  pros,
  className,
  prosIconClassName,
}: Pick<CreditPackageType, 'pros'> &
  Pick<CreditsPackageProps, 'prosIconClassName'> & { className?: string }) => {
  if (!pros.length) {
    return null;
  }

  return (
    <ul className={cx('space-y-1', className)}>
      {pros.map((pro) => (
        <li key={pro} className="flex gap-x-1 items-start">
          <IconBox
            icon={faCheck}
            size="s"
            className={cx('text-secondary-300', prosIconClassName)}
          />
          {pro}
        </li>
      ))}
    </ul>
  );
};

const Price = ({ amount, discount }: Pick<CreditPackageType, 'amount' | 'discount'>) => {
  const fullPrice = amount + discount.amount;

  return (
    <div className="inline-flex flex-col items-center self-center">
      <span className="font-brand-h5">Pay just</span>
      <div className="flex flex-wrap mdo:flex-col items-center justify-center gap-1 mt-2 mdo:mt-1">
        <span className="font-brand-h4 text-text-200 line-through whitespace-nowrap">
          {formatMoney(fullPrice)}
        </span>
        <span className="font-brand-h3 text-error-300 whitespace-nowrap">
          {formatMoney(amount)}
        </span>
      </div>
    </div>
  );
};

const CreditsPackageInfo = ({
  name,
  credits,
  color,
}: Pick<CreditPackageType, 'name' | 'credits' | 'color'>) => (
  <div className="inline-flex flex-col self-center">
    <h3 className="inline-flex mdo:flex-col items-baseline mdo:items-center gap-x-1 font-brand-h4">
      {name}
      <span className="font-brand-sm">({formatMoney(credits)})</span>
    </h3>

    <CreditsAmount credits={credits} color={color} className="mt-2 mdo:mt-1" />
  </div>
);

const colorToGiftImage = {
  bronze: green,
  silver: blue,
  gold: yellow,
};

const CreditsAmount = ({
  credits,
  color,
  className,
}: Pick<CreditPackageType, 'credits' | 'color'> & { className?: string }) => {
  return (
    <div className={cx('inline-flex self-center relative', className)}>
      <img src={colorToGiftImage[color]} alt="Credit package gift" />
      <span className="inline-flex text-center flex-col absolute top-[56px] right-0 left-0 -translate-x-[5px] -translate-y-[3px]">
        <span className="font-brand-h4 text-error-300 tracking-[0.5px]">{credits}</span>
        Credits
      </span>
    </div>
  );
};
