import { Any } from '~/common/utils';

type ErrorState = Record<string, Any>;
type ErrorAction = {
  type: string;
  payload: Any;
  error: Any;
  field: string;
  name: string;
};

const REMOVE_ERROR = 'REMOVE_ERROR';
const REMOVE_VALIDATION_ERROR = 'REMOVE_VALIDATION_ERROR';

export const errorReducer = (state: ErrorState = {}, action: ErrorAction) => {
  const { type, payload, error } = action;

  switch (type) {
    case REMOVE_ERROR:
      return { ...state, [action.name]: '' };

    case REMOVE_VALIDATION_ERROR: {
      const { name, field } = action;

      if (!state[name]) {
        return state;
      }

      if (typeof state[name] === 'string') {
        return { ...state, [name]: '' };
      }

      const { [field]: _, ...errors } = state[name];
      return { ...state, [name]: errors };
    }

    default: {
      const matches = /(.*)_(REQUEST|FAILURE|RESET)/.exec(type);
      // not a *_REQUEST / *_FAILURE actions, so we ignore them
      if (!matches) return state;
      const [, requestName, requestState] = matches;
      return {
        ...state,
        // Store errorMessage
        // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
        //      else clear errorMessage when receiving GET_TODOS_REQUEST
        [requestName]: requestState === 'FAILURE' ? error || payload : '',
      };
    }
  }
};
