import { useHistory } from 'react-router';
import { useModalContext, Button } from '~/common/components';
import { PackagesExplainedModal } from '~/components/Modal/Modals';
import { routes } from '~/constants';
import { CreditPackage } from '~/root/domain';
import { useBillingData, useCreditPackagesData } from '../hooks';
import { EditAutoTopupModal } from './AutoTopup';
import { CreditsPackages } from './CreditsPackages';

export const CreditsPackagesPage = () => {
  const history = useHistory();
  const creditPackages = useCreditPackagesData();

  const handleBuyClick = (creditsPackage: CreditPackage) => {
    history.push(`${routes.profile.billing.credits_purchase}/${creditsPackage.id}`);
  };

  return (
    <>
      <TopSection />
      <CreditsPackages
        creditsPackages={creditPackages}
        onPackageClick={handleBuyClick}
        className="mt-4"
      />
    </>
  );
};

const TopSection = () => {
  const { modalOpener } = useModalContext();
  const { topUp } = useBillingData();

  return (
    <div className="flex justify-between">
      <div className="flex items-center md:items-baseline flex-col">
        <h1 className="flex font-brand-h5">
          <span className="hidden md:inline mr-2">Credit packages</span>
          <PackagesExplainedModal />
        </h1>
        <span className="mt-1 font-brand-b1 text-text-300 text-center">
          Save up to 10% with our flexible and hassle-free credit packages
        </span>
      </div>

      {topUp && (
        <div className="inline">
          <span className="font-brand-b3 text-text-400">Auto top-up status</span>
          <div className="flex gap-1 items-baseline">
            <span className="font-brand-b1">Active ({topUp?.name} package)</span>
            <Button color="text" size="m" onClick={modalOpener(EditAutoTopupModal)}>
              Change
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
