import { useEffect, useRef, useState, DragEvent, KeyboardEvent } from 'react';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { cx } from '~/common/utils';
import { DefaultItem } from './DefaultItem';
import { Direction, DragDropProps, DragItemProps } from './types';

const getReorderDirection = (clientX: number, clientY: number, dropRect: DOMRect) => {
  const top = Math.abs(dropRect.top - clientY);
  const bottom = Math.abs(dropRect.bottom - clientY);
  const right = Math.abs(dropRect.right - clientX);
  const left = Math.abs(dropRect.left - clientX);
  const min = Math.min(top, bottom, right, left);

  return {
    direction: min === top ? 'top' : min === bottom ? 'bottom' : min === right ? 'right' : 'left',
    value: min,
  } as {
    direction: Direction;
    value: number;
  };
};

// Things to overcome
// 1. Keyboard navigation when long pressed animation triggers on every reposition, it's anoying on long lists
export const DragDrop = <T extends { id: number | string; name: string }>({
  items,
  itemRenderer = DefaultItem,
  onChange,
  containerClassName,
  disabled = false,
  size = 'm',
  useCustomDraggableTarget = false,
  sensitivity = 8,
}: DragDropProps<T>) => {
  const [tempOrder, setTempOrder] = useState<T[]>(items);
  const draggingItemIndex = useRef<number | null>(null);
  const draggingItemRef = useRef<HTMLLIElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const draggingTargetIndex = useRef<number | null>(null);
  const isAnimationPlaying = useRef(false);
  const [isSnapshot, setIsSnapshot] = useState(false);
  const tempDragDirection = useRef<Direction | null>(null);
  const reorderSensitivity = sensitivity / 5;

  const handleTempReorder = (e: DragEvent<HTMLElement>, index: number) => {
    if (
      !isDragging ||
      !draggingItemRef.current ||
      draggingItemIndex.current === null ||
      isAnimationPlaying.current
    ) {
      return;
    }

    draggingTargetIndex.current = index;
    if (draggingItemIndex.current === index) return;

    const dropRect = e.currentTarget.getBoundingClientRect();
    const dragRect = draggingItemRef.current?.getBoundingClientRect();

    if (dropRect && dragRect) {
      const { direction } = getReorderDirection(e.clientX, e.clientY, dropRect);
      if (!tempDragDirection.current) tempDragDirection.current = direction;

      const thresholdConditions: Record<Direction, boolean> = {
        top: e.clientY >= dropRect.bottom - (dragRect.height / 2) * reorderSensitivity,
        bottom: e.clientY <= dropRect.top + (dragRect.height / 2) * reorderSensitivity,
        left: e.clientX >= dropRect.right - (dragRect.width / 2) * reorderSensitivity,
        right: e.clientX <= dropRect.left + (dragRect.width / 2) * reorderSensitivity,
      };

      if (!thresholdConditions[tempDragDirection.current]) return;
    }

    tempDragDirection.current = null;
    const nextOrder = [...tempOrder];
    const [removed] = nextOrder.splice(draggingItemIndex.current!, 1);
    nextOrder.splice(draggingTargetIndex.current, 0, removed);
    setTempOrder(nextOrder);
    draggingItemIndex.current = draggingTargetIndex.current;
  };

  useEffect(() => {
    setTempOrder(items);
  }, [items]);

  const resetDrag = () => {
    draggingItemRef.current = null;
    draggingItemIndex.current = null;
    draggingTargetIndex.current = null;
    setIsDragging(false);
  };

  //TODO Improve reordering because now we have directional reordering
  const handleKeyboardReorder = (e: KeyboardEvent<HTMLElement>, index: number) => {
    if (e.shiftKey && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
      e.preventDefault();
      const newOrder = [...tempOrder];
      const [removed] = newOrder.splice(index, 1);
      newOrder.splice(e.key === 'ArrowUp' ? index - 1 : index + 1, 0, removed);
      onChange(newOrder);
    }
  };

  const onDragStart = (e: DragEvent<HTMLLIElement>, index: number) => {
    setIsDragging(true);
    setIsSnapshot(true);
    draggingItemRef.current = e.currentTarget;
    draggingItemIndex.current = index;
    draggingTargetIndex.current = null;
  };

  const onDragEnd = () => {
    // If onDrop hasn't fired
    if (isDragging) {
      resetDrag();
      setTempOrder(items);
    }
  };

  const onDrop = () => {
    resetDrag();
    onChange(tempOrder);
  };

  const onDragOver = (e: DragEvent<HTMLElement>, index: number) => {
    setIsSnapshot(false);
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    handleTempReorder(e, index);
  };

  return (
    <Flipper
      onComplete={() => (isAnimationPlaying.current = false)}
      onStart={() => (isAnimationPlaying.current = true)}
      flipKey={tempOrder.map((item) => item.id).join(' ')}
      spring={{
        overshootClamping: true,
        damping: 30,
        stiffness: 350,
      }}
    >
      <ul
        className={containerClassName}
        {...(disabled
          ? {}
          : {
              onDrop,
              onDragEnd,
            })}
      >
        {tempOrder.map((item, index) => {
          const itemProps: DragItemProps<T> = {
            item,
            isSnapshot: isSnapshot && draggingItemIndex.current === index,
            isDraggedOver: draggingTargetIndex.current === index,
            isDragging: isDragging,
            removeItem: () => onChange(items.filter((_, i) => i !== index)),
            disabled: items.length <= 1 || disabled,
            size,
            index,
          };

          return (
            <Flipped key={item.id} flipId={item.id}>
              <li
                tabIndex={0}
                key={item.id}
                {...(disabled
                  ? {}
                  : {
                      onPointerDown: () => {
                        document.getSelection()?.empty();
                      },
                      onKeyDown: (e) => handleKeyboardReorder(e, index),
                      onDragStart: (e) => onDragStart(e, index),
                      onDragOver: (e) => onDragOver(e, index),
                    })}
                draggable={!(useCustomDraggableTarget || itemProps.disabled)}
                className={cx(
                  'group',
                  // transform is used to preserve rounded corners on items, pops up in chrome
                  // https://github.com/react-dnd/react-dnd/issues/788#issuecomment-367300464
                  itemProps.isSnapshot && 'transform',
                )}
              >
                {itemRenderer(itemProps)}
              </li>
            </Flipped>
          );
        })}
      </ul>
    </Flipper>
  );
};
