import { useState } from 'react';
import { ModalContentProps } from '~/common/components';
import { propagateBackendValidationErrors } from '~/common/utils';
import {
  BillingForm,
  getBillingInitialValues,
  transformFormValues,
  useBillingForm,
} from '../BillingForm';
import { useBillingData, useUpdateBilling } from '../hooks';

export const BillingInformationModal = ({ onClose }: ModalContentProps) => {
  const { billingAddress, company } = useBillingData();

  const { mutateAsync } = useUpdateBilling();

  const [initialValues] = useState(getBillingInitialValues({ ...billingAddress, ...company }));

  const form = useBillingForm(initialValues);

  const onSubmit = () => {
    form.handleSubmit((values) => {
      return mutateAsync(transformFormValues(values))
        .then(onClose)
        .catch((error) => {
          propagateBackendValidationErrors({
            error,
            setErrors: form.setErrors,
            formErrors: form.errors,
          });
        });
    });
  };

  return (
    <BillingForm
      {...form}
      title="Billing information"
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
