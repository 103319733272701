import { ModalContentProps, ModalHeader, ModalShell } from '../Modal';

export interface StatusModalProps extends ModalContentProps {
  header?: string;
  title?: string;
  message?: string;
  icon?: React.ReactNode;
  onSubmit?: () => void;
  loading?: boolean;
}

export const StatusModal = ({
  onClose,
  onSubmit,
  header,
  title,
  message,
  loading,
  icon,
}: StatusModalProps) => {
  const submit = () => {
    onSubmit?.();
    onClose();
  };

  return (
    <>
      <ModalHeader title={header} onClose={onClose} />
      <ModalShell
        submitText="OK"
        onClose={onClose}
        onSubmit={submit}
        loading={loading}
        className="flex flex-col items-center"
      >
        {icon}
        {title && <h2 className="font-brand-h6b text-center mt-3">{title}</h2>}
        {message && <p className="text-center mt-[12px]">{message}</p>}
      </ModalShell>
    </>
  );
};
