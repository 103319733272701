import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import { Button, Tooltip } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import { handleErrorMessage } from '~/common/utils';
import { useCurrentUserData } from '~/root/Auth';
import PaymentDetails from '../confirmation/PaymentDetails';
import { ConfirmationDetails } from '../domain';
import { ConfirmPaymentData, useConfirmPayment, useOrderConfirmation } from '../hooks';
import { ConfrimationContext, getConfirmationPaymentDetails } from '../utils';
import PayWithContainer from './PayWithContainer';

interface InnerOrderConfirmationPopupProps {
  enoughCredits: boolean;
  onClose: () => void;
  details: ConfirmationDetails;
}

export const InnerOrderConfirmationPopup = ({
  enoughCredits,
  onClose,
  details: { billing, order },
}: InnerOrderConfirmationPopupProps) => {
  const orderId = useIdParam();
  const { purchasedPackages } = useCurrentUserData();

  const [paymentMethods, setPaymentMethods] = useState(billing.paymentMethods);

  // TODO check if we should use regular credit packages instead
  const creditPackages = billing.creditPackages.filter(
    ({ credits }) => credits + billing.creditBalance >= order.discount + order.unit,
  );

  const orderConfirmationInfo = useOrderConfirmation({
    enoughCredits,
    creditBalance: billing.creditBalance,
    creditPackages,
    paymentMethods,
  });

  const { isToppingUp, paymentMethodId, creditPackageId, payWithMethod } = orderConfirmationInfo;

  const currentPackage = creditPackages.find(({ id }) => id === creditPackageId);
  const paymentMethod = paymentMethods.find(({ id }) => id === paymentMethodId);
  const paymentDetails = getConfirmationPaymentDetails({
    enoughCredits,
    isToppingUp,
    payWithMethod,
    currentPackage,
    order,
    billing,
    paymentMethod,
  });

  const client = useQueryClient();
  const confirmPayment = useConfirmPayment();

  // TODO this logic might be faulty :)
  const getConfirmationData = (): ConfirmPaymentData => {
    if (payWithMethod === 'card') {
      return {
        method: 'card',
        payload: {
          method: paymentMethodId,
        },
      };
    }

    if (payWithMethod === 'credits' && isToppingUp && creditPackageId) {
      // If credit package is selected
      return {
        method: 'package',
        payload: {
          package: creditPackageId,
          method: paymentMethodId,
        },
      };
    }

    if (payWithMethod === 'credits' && isToppingUp && !enoughCredits) {
      return {
        method: 'recharge',
        payload: { method: paymentMethodId },
      };
    }

    if (payWithMethod === 'credits' && !isToppingUp) {
      return {
        method: 'recharge',
        payload: { method: paymentMethodId },
      };
    }

    if (payWithMethod === 'credits' && enoughCredits) {
      // If credits balance enought to pay
      return { method: 'credits' };
    }

    return null;
  };

  const shouldChooseCreditPack =
    payWithMethod === 'credits' && !creditPackageId && !enoughCredits && isToppingUp;

  const shouldAddCard =
    (payWithMethod === 'card' && !paymentMethodId) ||
    (payWithMethod === 'credits' && !paymentMethodId && (!!creditPackageId || !enoughCredits));

  const confirmDisabled = shouldAddCard || shouldChooseCreditPack;

  const handleConfirm = () => {
    const data = getConfirmationData();
    confirmPayment.mutate(data, {
      onSuccess: ({ downloadPresentationUrl }) => {
        if (data?.method === 'package') {
          fireGAEvent();
        }

        if (downloadPresentationUrl) {
          window.open(downloadPresentationUrl, '_blank');
        }

        client.invalidateQueries(['billing']);
        onClose();
      },
      onError: handleErrorMessage,
    });
  };

  const fireGAEvent = () => {
    const eventAction = purchasedPackages
      ? 'payment-confirmation-new'
      : 'payment-confirmation-topup';
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'credits',
      eventAction,
      eventLabel: currentPackage?.name.toLowerCase(),
      eventValue: currentPackage?.credits,
    });
  };

  return (
    <div className="confirmation-popup f-column">
      <div className="confirmation-popup-aside cpa__container">
        <h3 className="cpa__title semibold">Order #{orderId}</h3>
        <PaymentDetails {...paymentDetails} order={order} />
      </div>

      <div className="cp__container flex-1 f-column">
        <ConfrimationContext.Provider value={{ ...orderConfirmationInfo, setPaymentMethods }}>
          <PayWithContainer
            enoughCredits={enoughCredits}
            packageDiscount={currentPackage?.discount.percentage}
            creditPackages={creditPackages}
            total={paymentDetails.total}
            billing={{ ...billing, paymentMethods }}
          />
        </ConfrimationContext.Provider>

        <footer className="f-center">
          <Tooltip
            content={
              shouldAddCard
                ? 'You should add the card payment method'
                : shouldChooseCreditPack
                ? 'You should choose a credit pack'
                : null
            }
          >
            <Button
              color="secondary"
              loading={confirmPayment.isLoading}
              className="f-center full-width"
              onClick={handleConfirm}
              disabled={confirmDisabled}
            >
              Confirm and pay
            </Button>
          </Tooltip>
        </footer>
      </div>
    </div>
  );
};
