import { z } from 'zod';
import { oldCountrySchema } from '~/root/domain';
import { customerSchema, staffToCustomerSchemaDiff } from './user';

const alertSchema = z.object({
  key: z.enum(['vat', 'subscriptionpaused', 'holiday', 'suspended', 'maintenance']),
  hash: z.string(),
  message: z.string(),
  type: z.enum(['info', 'success', 'warning', 'error']),
});

const popupSchema = z.object({
  key: z.enum(['upsell']),
  amount: z.string(), // currency sign + amount, f.e $30
  slides: z.number(),
  discount: z.string(), // currency sign + 50% of amount, f.e $15
  percentage: z.string(), // '50%'
});

const initCustomerSchema = customerSchema.omit({ country: true }).extend({
  country: oldCountrySchema.nullable(),
});

const initStaffSchema = initCustomerSchema.extend(staffToCustomerSchemaDiff);

const initUserSchema = initCustomerSchema.or(initStaffSchema);

export const initSchema = z.object({
  alerts: z.array(alertSchema).nullable(),
  countryCode: z.string(),
  env: z.object({
    stripeToken: z.string(),
    userIp: z.string(),
  }),
  flashMessage: z.any().nullable(),
  isEu: z.boolean(),
  popups: z.array(popupSchema).nullable(),
  user: initUserSchema.nullable().catch(null),
  enableProCoupons: z.boolean().optional().catch(false),
});

export type Init = z.infer<typeof initSchema>;
