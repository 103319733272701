/**
 * Inserts an element between each two elements of the array
 * [A, A, A] becomes [A, B, A, B, A]
 */
export function intersperse<A, B>(array: A[], element: B) {
  const result = [];
  let i = 0;

  if (i < array.length) {
    result.push(array[i++]);
  }

  while (i < array.length) {
    result.push(element, array[i++]);
  }

  return result;
}

export function modifyLast<T>(arr: T[], value: T) {
  if (!arr.length) {
    return arr;
  }

  const next = [...arr];
  next.pop();
  next.push(value);
  return next;
}

export function range(start: number, end: number): number[];

export function range<T>(start: number, end: number, fn: (index: number) => T): T[];

export function range<T>(start: number, end: number, fn?: (index: number) => T) {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(fn ? fn(i + 1) : i);
  }
  return result;
}

export function lastItem<T>(array: T[]): T {
  return array[array.length - 1];
}

export function joinTruthy<T>(array: T[], delimeter = '\n'): string {
  return array.filter(Boolean).join(delimeter);
}

export function dedupeBy<T>(array: T[], fn: (item: T) => keyof T) {
  const ids = new Set();
  const result = [];
  for (const item of array) {
    const prop = fn(item);
    if (!ids.has(prop)) {
      ids.add(prop);
      result.push(item);
    }
  }
  return result;
}

export function findIndex<T>(array: T[], fn: (item: T) => T) {
  const result = array.findIndex(fn);
  return result === -1 ? undefined : result;
}
