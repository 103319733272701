import { Toast } from '../components/Toast';

export const copyToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    Toast.success({ message: 'Copied!' });
  } catch (err) {
    Toast.error({ message: 'Oops, unable to copy' });
  }

  document.body.removeChild(textArea);
};
