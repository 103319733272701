import { useState } from 'react';
import {
  DatepickerField,
  InputField,
  ModalContentProps,
  Button,
  Checkbox,
} from '~/common/components';
import { useToggle } from '~/common/hooks';
import { createPrefetchWrapper, propagateBackendValidationErrors } from '~/common/utils';
import {
  BillingForm,
  getBillingInitialValues,
  transformFormValues,
  useBillingForm,
  useFillWithBillingInfo,
} from '../BillingForm';
import { useAddPaymentMethod, useBilling, useBillingData, useUpdatePaymentMethod } from '../hooks';

export const InvoicePaymentMethodModal = createPrefetchWrapper(useBilling)(({
  onClose,
  edit = false,
}: ModalContentProps & { edit?: boolean }) => {
  const { invoicePaymentMethod, paymentMethods, company } = useBillingData();

  const addPaymentMethod = useAddPaymentMethod();
  const updatePaymentMethod = useUpdatePaymentMethod();

  const [initialValues] = useState(
    getBillingInitialValues(
      invoicePaymentMethod ? { ...invoicePaymentMethod, ...company } : undefined,
    ),
  );
  const [setDefault, toggleDefault] = useToggle(true);

  const form = useBillingForm(initialValues, { requireBillingContact: true });
  const fillWithBillingInfo = useFillWithBillingInfo(form);

  const onSubmit = () => {
    form.handleSubmit((payload) => {
      if (edit) {
        return updatePaymentMethod
          .mutateAsync({
            id: invoicePaymentMethod?.id as number,
            payload: transformFormValues(payload),
          })
          .then(onClose)
          .catch((error) => {
            propagateBackendValidationErrors({
              error,
              setErrors: form.setErrors,
              formErrors: form.errors,
            });
          });
      }

      return addPaymentMethod
        .mutateAsync({ ...transformFormValues(payload), type: 'invoice', default: setDefault })
        .then(onClose)
        .catch((error) => {
          propagateBackendValidationErrors({
            error,
            setErrors: form.setErrors,
            formErrors: form.errors,
          });
        });
    });
  };

  return (
    <BillingForm
      {...form}
      title={`${edit ? 'Edit' : 'Add'} invoice information`}
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={onSubmit}
      requireBillingContact
      billingInfoHeader={
        <Button color="text" onClick={fillWithBillingInfo}>
          Copy from billing address
        </Button>
      }
    >
      <div className="grid md:grid-cols-2 grid-cols-1 gap-x-3 gap-y-2 mt-4">
        <p className="text-text-500 font-brand-t3m col-span-1 md:col-span-2">PO number</p>
        <InputField
          {...form.fieldProps('poNumber')}
          title="PO number"
          placeholder="Enter PO number"
          type="text"
        />
        <DatepickerField
          {...form.fieldProps('poExpiresAt')}
          title="Expiration date"
          required={Boolean(form.values.poNumber)}
          hint="When selected, CS and Finance will be reminded to update the PO number"
          nullable
        />
      </div>
      {!edit && paymentMethods.length > 0 && (
        <Checkbox
          value={setDefault}
          title="Set as default payment method"
          onChange={toggleDefault}
          className="flex-nowrap mt-3"
        />
      )}
    </BillingForm>
  );
});
