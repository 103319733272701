import { useMutation } from '@tanstack/react-query';
import { Toast } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import { axios } from '~/root';
import { Member } from '../domain';
import { setSubscribedTo, useFullOrderData, useUpdateOrderCache } from './useOrder';

const getSubscribersAmount = (members: Member[]) => {
  return members.filter((member) => member.isSubscribed).length;
};

export const useOrderSubscription = () => {
  /**
   * This hook uses optimistic updates, see https://tanstack.com/query/v4/docs/react/guides/optimistic-updates
   */
  const orderId = useIdParam();
  const order = useFullOrderData();
  const { getQuery, setQuery, cancelQuery } = useUpdateOrderCache();

  const { mutate: subscribe } = useMutation({
    mutationFn: () => axios.post(`/v1/orders/${orderId}/follow/${order.members.you.id}`),
    onMutate: async () => {
      await cancelQuery();
      const prev = getQuery();
      setQuery((prev) => setSubscribedTo(true, prev));
      return prev;
    },
    onError: (_error, _variables, prev) => setQuery(prev),
    onSuccess: () => Toast.success({ message: 'You are now following this order' }),
  });

  const { mutate: unsubscribe } = useMutation({
    mutationFn: () => axios.post(`/v1/orders/${orderId}/unfollow/${order.members.you.id}`),
    onMutate: async () => {
      await cancelQuery();
      const prev = getQuery();
      setQuery((prev) => setSubscribedTo(false, prev));
      return prev;
    },
    onError: (_error, _variables, prev) => setQuery(prev),
    onSuccess: () => Toast.warning({ message: 'Now you won’t get this order notifications.' }),
  });

  const isSubscribed = order.members.you.isSubscribed;
  const toggleSubscription = () => (isSubscribed ? unsubscribe() : subscribe());

  const canToggleSubscription =
    getSubscribersAmount(order.members.members) + getSubscribersAmount(order.members.admins) > 1 ||
    !isSubscribed;

  return {
    order,
    isSubscribed,
    canToggleSubscription,
    toggleSubscription,
    subscribe,
    unsubscribe,
  };
};
