import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react';
import { cx } from '~/common/utils';
import { IconBox } from './IconContainers';

type Props = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const ImageLoader = (props: Props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && (
        <div
          className={cx(
            'flex items-center justify-center before:block def:before:mt-[75%]',
            props.className || 'w-full',
          )}
        >
          <IconBox
            size="l"
            icon={faSpinnerThird}
            className="text-greyscale-500 animate-spin h-fit w-fit"
          />
        </div>
      )}
      <img
        {...props}
        className={cx(props.className, {
          hidden: !loaded,
        })}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};
