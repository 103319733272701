import { colors } from '~/utils/palette';
import type { IconType } from './types';
const defaultProps = {
  fill: colors.graysuit,
  width: 8,
  height: 32,
};

const IconEllipsisV = ({ fill, width, height }: IconType) => (
  <svg width={width} height={height} viewBox="0 0 128 512" fill="none">
    <path
      fill={fill}
      d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z"
    />
  </svg>
);

IconEllipsisV.defaultProps = defaultProps;
export default IconEllipsisV;
