export const PasswordTooltipContent = () => (
  <div className="font-brand-ol">
    <ul className="list-disc ml-1">
      <li>Minimum 8 characters</li>
      <li>At least one number and special character</li>
      <li>At least one uppercase and lowercase letters</li>
      <li>Does not contain user or company name</li>
    </ul>
  </div>
);
