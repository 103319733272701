import { Button, useModalContext } from '~/common/components';
import { ProModal } from '~/components/Modal/ProModal';

export const ProBanner = ({ className }: { className?: string }) => {
  const { modalOpener } = useModalContext();
  return (
    <Button className={className} color="primary-outlined" size="m" onClick={modalOpener(ProModal)}>
      Explore our new Pro plan 🚀
    </Button>
  );
};
