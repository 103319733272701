import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useDelayGroup,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStatus,
} from '@floating-ui/react';
import { Strategy } from '@floating-ui/utils';
import { cloneElement, ReactNode, useRef, useState } from 'react';
import { cx } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import { useArrow } from '../Floating';
import styles from './Tooltip.module.scss';

type Props = {
  content: ReactNode;
  children: JSX.Element;
  color?: 'white' | 'grey' | 'darkgrey' | 'primary';
  className?: string;
  placement?: Placement;
  fallbackPlacements?: Placement[];
  delay?: number;
  compensateOffset?: number;
  noArrow?: boolean;
  clickable?: boolean;
  strategy?: Strategy;
};

export const Tooltip = ({
  content,
  children,
  color = 'white',
  className,
  placement = 'top',
  fallbackPlacements = ['top', 'bottom', 'left', 'right'],
  delay = 300,
  compensateOffset = 0,
  noArrow = false,
  clickable = false,
  strategy = 'absolute',
}: Props) => {
  const [open, setOpen] = useState(false);

  const arrowRef = useRef<HTMLDivElement>(null);

  const {
    context,
    refs,
    middlewareData,
    placement: floatingPlacement,
    floatingStyles,
  } = useFloating({
    placement,
    open: open && !!content,
    onOpenChange: setOpen,
    middleware: [
      offset(12 - compensateOffset),
      flip({ fallbackPlacements }),
      shift({ padding: 8 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
    strategy,
  });

  const { arrowProps, staticSide } = useArrow(arrowRef, floatingPlacement);
  const { delay: groupDelay, isInstantPhase } = useDelayGroup(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: groupDelay ?? delay,
      restMs: 40,
      handleClose: clickable ? safePolygon({ blockPointerEvents: true }) : undefined,
    }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  const { isMounted, status } = useTransitionStatus(context, {
    duration: groupDelay || 250,
  });

  return (
    // TODO use render-props instead of cloneElement to be able to merge
    // multiple ref callbacks from above, make multiRef(ref1, ref2) utility
    // use-case - dropdown popover & tooltip on select fields on the same node
    <>
      {cloneElement(children, getReferenceProps({ ref: refs.setReference, ...children.props }))}
      {isMounted && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              className: cx(
                styles.tooltip,
                styles[color],
                styles[staticSide],
                fade.floating,
                className,
              ),
              style: floatingStyles,
              onClick: () => setOpen(false),
            })}
            data-status={status}
            data-instant={isInstantPhase}
          >
            {!noArrow && <div {...arrowProps(middlewareData)} className={styles.arrow} />}
            {content}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
