import { getTimezone } from '~/common/utils';
import { ComponentNames } from '~/pages/Order-now/constants';
import types from './data.types';
import * as utils from './data.utils';

const initialState = {
  details: {},
  serverValidationErrors: {},
  notifications: {
    status: false,
    option: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Sms notifications
    case types.GET_NOTIFICATIONS_SUCCESS:
    case types.UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };

    case types.RESET_DATA_PATH:
      return {
        ...state,
        path: null,
      };
    case types.GET_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_DATA_SUCCESS: {
      const {
        name,
        stepped,
        properties,
        currency: { sign },
        steps,
        ...payload
      } = action.payload;
      const { components } = payload;

      // timezone is required by default for submitting
      const timezone = getTimezone();

      return {
        ...state,
        details: {
          ...utils.createComponentsDetails(components, state.details),
          name,
          stepped,
          price: properties?.price,
          currency: sign,
          timezone: { timezone },
        },
        isLoading: false,
        affections: utils.createValueAffections(components),
        properties,
        steps,
        ...payload,
      };
    }
    case types.GET_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case types.DELIVERY_DATES_REQUEST:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.DELIVERY_DATES, {
          loading: true,
        }),
      };
    case types.DELIVERY_DATES_SUCCESS:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.DELIVERY_DATES, {
          loading: false,
        }),
      };
    case types.DELIVERY_DATES_FAILURE:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.DELIVERY_DATES, {
          loading: false,
        }),
      };

    case types.COUPON_REQUEST:
      return {
        ...state,
        coupon: { ...state.coupon, loading: true },
      };

    case types.COUPON_SUCCESS: {
      const {
        status,
        title, // `title` is coupon valid message usual
        message,
        fields,
        coupon,
      } = action.payload;

      const code = status === 'success' ? coupon : null;

      return {
        ...state,
        coupon: {
          ...fields,
          status,
          loading: false,
          message: title || message,
        },
        details: utils.replaceDetailsByName(state.details, ComponentNames.COUPON, { coupon: code }),
      };
    }

    case types.COUPON_FAILURE: {
      const { message } = action.payload;

      return {
        ...state,
        coupon: {
          message: message,
          loading: false,
        },
      };
    }

    case types.RESET_COUPON:
      return {
        ...state,
        coupon: { loading: false },
        details: utils.replaceDetailsByName(state.details, ComponentNames.COUPON, { coupon: null }),
      };
    case types.SET_COUPON:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.COUPON, {
          coupon: action.payload,
        }),
      };

    case types.SET_CARD:
      return {
        ...state,
        card: action.payload,
      };

    case types.ORDER_FORM_FAILURE:
      return {
        ...state,
        serverValidationErrors: action.payload,
      };
    case types.RESET_SERVER_VALIDATION_ERRORS:
      return {
        ...state,
        serverValidationErrors: {},
      };
    case types.ORDER_FORM_RESET:
      return {
        serverValidationErrors: {},
        details: {},
        orderId: state.orderId,
        needsQuestions: state.needsQuestions,
        notifications: initialState.notifications,
      };

    case types.SET_TREATMENT:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.TREATMENTS, {
          treatment: action.payload.key,
          treatmentName: action.payload.name,
          price: action.payload.price,
          turnaroundPrices: action.payload.turnaroundPrices,
        }),
      };
    case types.SET_STYLE:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.STYLES, {
          style: action.payload.id,
          styleType: action.payload.styleType,
        }),
      };
    case types.RESET_STYLE:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.STYLES, {
          style: null,
          styleType: null,
        }),
      };
    case types.SET_TURNAROUND: {
      const { details } = state;

      return {
        ...state,
        details: utils.updateDetailsByName(details, ComponentNames.DELIVERY_DATES, action.payload),
      };
    }
    case types.SET_SLIDES:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.SLIDES_NUMBER, {
          slides: action.payload,
        }),
      };
    case types.SET_ADDON:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.ADDONS, action.payload),
      };
    case types.RESET_ADDON:
      return {
        ...state,
        details: utils.replaceDetailsByName(state.details, ComponentNames.ADDONS, {}),
      };
    case types.SET_GRAMMAR:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.ADDONS, {
          grammar: action.payload,
        }),
      };
    case types.SET_SHARING_LINKS:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.UPLOAD_FILES, {
          useSharingLinks: action.payload,
        }),
      };
    case types.ADD_UPLOAD_FILE: {
      const { files = [] } = state.details.uploadFiles;
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.UPLOAD_FILES, {
          files: [...files, action.payload],
        }),
      };
    }
    case types.REMOVE_UPLOAD_FILE: {
      const files = state.details.uploadFiles.files || [];
      const filteredFiles = files.filter(
        ({ uuid, name }) => ![uuid, name].includes(action.payload),
      );
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.UPLOAD_FILES, {
          files: [...filteredFiles],
        }),
      };
    }
    case types.SET_BRIEF:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.BRIEF, {
          brief: action.payload,
        }),
      };
    case types.UPDATE_COMPONENT:
      return {
        ...state,
        components: utils.replaceComponents(state, action.payload),
      };
    case types.SET_PAYMENT_DETAILS:
      return {
        ...state,
        details: utils.updateDetailsByName(
          state.details,
          ComponentNames.PAYMENT_DETAILS,
          action.payload,
        ),
      };
    case types.SET_SIGNUP_FIELD:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.SIGNUP, action.payload),
      };

    case types.SET_CUSTOM_FIELDS:
      return {
        ...state,
        details: utils.updateDetailsByName(
          state.details,
          ComponentNames.CUSTOM_FIELDS,
          action.payload,
        ),
      };

    case types.SET_SUBMIT:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.SUBMIT, action.payload),
      };

    case types.RESTORE_DATA_FROM_LS: {
      const restoredDetails = Object.keys(state.details).reduce((stateDetails, componentName) => {
        const restoringComponentDetails = action.payload[componentName];
        if (restoringComponentDetails) {
          return {
            ...stateDetails,
            [componentName]: { ...stateDetails[componentName], ...restoringComponentDetails },
          };
        }

        return stateDetails;
      }, state.details);

      return {
        ...state,
        details: restoredDetails,
      };
    }
    case types.SET_STYLE_FILE:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.STYLES, {
          file: action.payload,
        }),
      };
    case types.RESET_STYLE_FILE:
      return {
        ...state,
        details: utils.updateDetailsByName(state.details, ComponentNames.STYLES, { file: {} }),
      };

    case types.AFFECT_STYLES: {
      /** It calls by `affect` data.action */
      const { data, update, fieldName } = action.payload;
      const { affections } = state;

      if (update) {
        affections[fieldName].update = update;
      }

      const style = data.fields?.find(({ name }) => name === 'style')?.value;

      const details = style
        ? utils.updateDetailsByName(state.details, ComponentNames.STYLES, { style })
        : state.details;

      return {
        ...state,
        components: utils.updateComponents(state.components, ComponentNames.STYLES, data),
        details,
        affections,
      };
    }
    case types.AFFECT_DELIVERYDATES: {
      /** It calls by `affect` data.action */
      const { data, update, fieldName } = action.payload;
      const { affections } = state;

      if (update) {
        affections[fieldName].update = update;
      }

      return {
        ...state,
        affections,
        components: utils.updateComponents(state.components, ComponentNames.DELIVERY_DATES, data),
      };
    }
    case types.AFFECT_SLIDESNUMBER: {
      /** It calls by `affect` data.action */
      const { data, update, fieldName } = action.payload;
      const { affections } = state;

      if (update) {
        affections[fieldName].update = update;
      }

      return {
        ...state,
        affections,
        components: utils.updateComponents(state.components, ComponentNames.SLIDES_NUMBER, data),
      };
    }
    case types.AFFECT_BRIEF: {
      /** It calls by `affect` data.action */
      const { data, update, fieldName } = action.payload;
      const { affections } = state;

      if (update) {
        affections[fieldName].update = update;
      }

      return {
        ...state,
        affections,
        components: utils.updateComponents(state.components, ComponentNames.BRIEF, data),
      };
    }

    case types.AFFECT_ADDONS: {
      /** It calls by `affect` data.action */
      const { data, update, fieldName } = action.payload;
      const { affections } = state;

      if (update) {
        affections[fieldName].update = update;
      }

      return {
        ...state,
        affections,
        components: utils.updateComponents(state.components, ComponentNames.ADDONS, data),
      };
    }

    case types.SET_UPSELL: {
      const { coupon, ...fields } = action.payload;

      return {
        ...state,
        coupon: fields,
        details: utils.replaceDetailsByName(state.details, ComponentNames.COUPON, {
          upsellCoupon: true,
          coupon,
        }),
      };
    }
    case types.RESET_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          ...utils.createComponentsDetails(state.components),
        },
      };

    case types.REMOVE_SERVER_VALIDATION_ERROR: {
      const serverValidationErrors = { ...state.serverValidationErrors };
      const componentName = Object.keys(serverValidationErrors).find(
        (name) => !!serverValidationErrors[name][action.payload],
      );

      if (componentName) {
        delete serverValidationErrors[componentName][action.payload];
      } else {
        return state;
      }

      return {
        ...state,
        serverValidationErrors,
      };
    }
    case types.UPDATE_DETAILS:
      return {
        ...state,
        details: utils.updateAdditionalOrderDetails(state.details, state.components),
      };

    case types.UPDATE_STEPS:
      if (action.steps) {
        return { ...state, steps: action.steps };
      }

      return state;

    default:
      return state;
  }
};
