import { z } from 'zod';
import { paymentMethodSchema, creditPackageSchema } from '~/root/domain';

const orderSchema = z.object({
  id: z.number(),
  unit: z.number(),
  discount: z.number(),
});

const billingSchema = z.object({
  creditBalance: z.number(),
  paymentMethods: z.array(paymentMethodSchema),
  creditPackages: z.array(creditPackageSchema),
  vatPercent: z.number(),
});

const confirmationDataSchema = z.object({
  enoughCredits: z.boolean(),
  order: orderSchema,
  billing: billingSchema,
});

export type ConfirmationDetails = z.infer<typeof confirmationDataSchema>;

const responseStatusSchema = z.enum(['success', 'error']);

const orderNeedsConfirmationSchema = z.object({
  status: responseStatusSchema,
  action: z.literal('showConfirmationPopup'),
  data: confirmationDataSchema,
  redirectUrl: z.string().url().optional(),
});

const orderAlreadyPaidSchema = z.object({
  status: responseStatusSchema,
  action: z.literal('download'),
  data: z.object({
    downloadUrl: z.string(),
  }),
  redirectUrl: z.string().url().optional(),
});

const orderSkipConfirmationSchema = z.object({
  status: responseStatusSchema,
  action: z.literal('skipConfirmationPopup'),
  data: z
    .object({
      downloadUrl: z.string(),
    })
    .optional(),
  redirectUrl: z.string().url().optional(),
});

export const getConfirmationResponseSchema = z.union([
  orderNeedsConfirmationSchema,
  orderAlreadyPaidSchema,
  orderSkipConfirmationSchema,
]);
