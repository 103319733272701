import { createContextPair } from '~/common/kits/context';
import { NavigationProps } from './types';

const useContextData = ({ isFromHeader = false, isMenuCollapsed = false }: NavigationProps) => {
  return {
    isFromHeader,
    isMenuCollapsed,
  };
};

const [useNavigation, withNavigation] = createContextPair(useContextData);

export { useNavigation, withNavigation };
