import { colors } from '~/utils/palette';
import type { IconType } from './types';
const defaultProps = {
  fill: colors.graysuit,
  width: 12,
  height: 12,
};

const IconCommentAltEdit = ({ fill, width, height = width }: IconType) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.455 0H1.162C.517 0 0 .517 0 1.162v6.97c0 .645.523 1.161 1.162 1.161h2.323v1.743c0 .32.261.58.58.58h.291c.146 0 .29-.057.407-.168l2.149-2.155h3.543c.64 0 1.162-.522 1.162-1.161v-6.97C11.617.517 11.094 0 10.455 0zM4.112 7.55H2.904V6.338l3.584-3.595L7.69 3.95l-3.578 3.6zm3.921-3.943l.587-.587a.328.328 0 000-.458l-.75-.727c-.139-.127-.337-.122-.453 0l-.586.593 1.202 1.179z"
      fill={fill}
    />
  </svg>
);

IconCommentAltEdit.defaultProps = defaultProps;
export default IconCommentAltEdit;
