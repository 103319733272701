import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { uploadPhotoIcon } from '~/assets/images/icons';
import { cx } from '~/common/utils';
import { IconBox } from '../IconContainers';
import { FileUploadPlaceholder } from './FilesUpload';
import { FileUpload, FileUploadProps, NonEmptyItemRendererProps } from './FileUpload';

const NonEmptyRenderer = ({
  isLoading,
  src,
  clear,
}: NonEmptyItemRendererProps & { src?: string }) => {
  return (
    <>
      {isLoading ? (
        <IconBox
          size="l"
          icon={isLoading ? faSpinnerThird : faFileCheck}
          className={cx('mx-[12px] z-10 text-text-100', isLoading && 'animate-spin')}
        />
      ) : (
        <div
          onClick={clear}
          className="flex flex-col justify-center items-center opacity-0 w-full h-full transition-brand z-10 group-hover:bg-black group-hover:bg-opacity-40 group-hover:opacity-100"
          data-stop-propagation
        >
          <IconBox icon={faTrashCan} size="m" className="mx-[12px] text-text-100" />
          <p className="text-text-100 font-brand-l3 ">Remove cover</p>
        </div>
      )}
      {src && (
        <img
          src={src}
          className={cx(
            'absolute w-full h-full object-cover transition-brand',
            isLoading && 'brightness-50',
          )}
        />
      )}
    </>
  );
};

const acceptedMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/avif', 'image/webp'];

export const PhotoUploadField = ({
  file,
  onChange,
  ...props
}: Omit<FileUploadProps, 'itemRenderer' | 'placeholderRenderer'>) => {
  const [src, setSrc] = useState(file?.link);

  const onFileChange = (newFile: File | null) => {
    if (newFile) {
      src && URL.revokeObjectURL(src);
      setSrc(URL.createObjectURL(newFile));
    }

    onChange(newFile);
  };

  return (
    <FileUpload
      {...props}
      file={file}
      onChange={onFileChange}
      itemRenderer={(props) => <NonEmptyRenderer {...props} src={src} />}
      placeholderRenderer={({ draggedOver, error }) => (
        <FileUploadPlaceholder
          icon={uploadPhotoIcon}
          text="Choose a cover"
          description="Max. size is 2 MB and 4096x2644px"
          className={cx('flex-col', {
            'border-secondary-300': draggedOver,
            'bg-error-100 border-error-300': error,
          })}
          containerClassName="h-full"
        />
      )}
      acceptedMimeTypes={acceptedMimeTypes}
    />
  );
};
