import { useEffect, useState } from 'react';

export const MAX_TAB_INDEX = 32767;

/*
 * This ugly garbage is to prevent focusing first focusable element in floating
 * elements (modal / popover) on opening, but allow tabbing through the form
 * inputs without explicitly clicking in the floating element's space
 *
 * It works by making div of the floating element focusable and then
 * immediately removing the focusability
 * */
export const useTabIndex = () => {
  const [tabIndex, setTabIndex] = useState<number | undefined>(MAX_TAB_INDEX);

  useEffect(() => {
    setTimeout(() => {
      setTabIndex(undefined);
    });
  }, []);

  return tabIndex;
};
