import ImageBlobReduce from 'image-blob-reduce';

type ResizeOptions = ImageBlobReduce.ResizeOptions;

export const resizeImage = async (file: File, options?: ResizeOptions) => {
  const reducer = new ImageBlobReduce();
  const dest = await reducer.toBlob(file, options);

  return new File([dest], file.name, {
    lastModified: file.lastModified,
    type: file.type,
  });
};
