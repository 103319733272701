import { library } from '@fortawesome/fontawesome-svg-core';

import {} from '@fortawesome/pro-regular-svg-icons';

import {} from '@fortawesome/pro-light-svg-icons';

import {} from '@fortawesome/pro-solid-svg-icons';

import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';

library.add(
  /**
   * Light
   */

  /**
   * Regular
   */

  /**
   * Solid
   */

  /**
   * Duotone
   */
  faExclamationTriangle,
);
