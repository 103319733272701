import { NavigationLink } from '../NavigationLink';
import { NavigationSection } from '../NavigationSection';
import { SubNavigation } from '../SubNavigation';
import { config } from './config';
import { ResourcesContent } from './ResoursesContent';
import { ServicesContent } from './ServicesContent';

export const UnauthenticatedNavigation = () => {
  return (
    <NavigationSection>
      <SubNavigation {...config.subMenus.services}>
        <ServicesContent />
      </SubNavigation>
      <NavigationLink {...config.links.examples} />
      <NavigationLink {...config.links.pricing} />
      <NavigationLink {...config.links.about} />

      <SubNavigation {...config.subMenus.resources}>
        <ResourcesContent />
      </SubNavigation>
    </NavigationSection>
  );
};
