import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Badge, Button, Link, useModalContext, Radio, Tooltip } from '~/common/components';
import { cx, formatMoney, record } from '~/common/utils';
import { IconBox } from '~/components';
import { Spinner } from '~/components/loaders/Spinner';
import { routes } from '~/constants';
import { BookMeetingModal } from '../BookMeetingModal';
import { SubscriptionPlanView } from '../domain';
import { useBillingData, useSubscriptionPlans } from '../hooks';
import {
  subscriptionPaymentMethod,
  subscriptionPeriodToMonthAmount,
  subscriptionPeriodToName,
  treatmentMap,
} from './constants';

export const Subscription = () => {
  const plans = useSubscriptionPlans();
  const { subscription } = useBillingData();

  if (subscription?.products.retainer) {
    return <Redirect to={routes.profile.billing.index} />;
  }

  if (!plans.data) {
    return (
      <div className="flex justify-center p-5">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col p-3 md:px-4 bg-white rounded-md">
      <h1 className="font-brand-h6">
        Subscriptions
        <Link href={routes.public.dedicated_team} newTab className="ml-1">
          Learn more
        </Link>
      </h1>

      <ul className="grid sm:grid-cols-1 mdo:grid-cols-2 lg:grid-cols-4 w-full gap-2 mt-3 mb-2">
        {plans.data.map((plan) => (
          <SubscriptionPlan key={plan.id} plan={plan} />
        ))}
      </ul>

      <Enterprise />
    </div>
  );
};

export const useInitialPeriod = (plan?: SubscriptionPlanView) => {
  const { period: periodParam } = useParams<{ period?: keyof SubscriptionPlanView['periods'] }>();

  return useState(() => {
    if (periodParam) {
      return periodParam;
    }

    const availablePeriods = plan?.periods
      ? (Object.keys(plan.periods) as (keyof SubscriptionPlanView['periods'])[])
      : (['monthly'] as const);

    return availablePeriods.reduce((initialPeriod, p) => {
      if (subscriptionPeriodToMonthAmount[p] < subscriptionPeriodToMonthAmount[initialPeriod]) {
        return p;
      }

      return initialPeriod;
    }, availablePeriods[0]);
  });
};

const periodOptions = (['monthly', 'quarter', 'bi-annual', 'annual'] as const).map((period) => ({
  value: period,
  title: subscriptionPeriodToName[period],
}));

const SubscriptionPlan = ({ plan }: { plan: SubscriptionPlanView }) => {
  const history = useHistory();
  const [period, setPeriod] = useInitialPeriod(plan);
  const { modalOpener } = useModalContext();

  const onClick = () => {
    if (plan.selfService) {
      history.push(`${routes.profile.billing.subscription_purchase}/${plan.id}/${period}`);

      return;
    }

    modalOpener(BookMeetingModal, {
      contactForm: 'subscriptions-contact-form',
    })();
  };

  return (
    <li className="relative border border-solid border-other-300 rounded">
      {plan.isNew && (
        <Badge
          color="secondary"
          className="text-text-100 bg-secondary-300 font-brand-c3 uppercase absolute top-1 right-1"
        >
          New
        </Badge>
      )}
      <div
        className={cx(
          'flex flex-col space-y-1 p-2 border-b border-solid border-other-300',
          plan.selfService ? 'bg-success-100' : 'bg-info-100',
        )}
      >
        <h5 className="font-brand-h5">{plan.name}</h5>
        <p className="font-brand-b3">
          {plan.dailyCapacity ? `${plan.dailyCapacity} slides per day` : <>&nbsp;</>}
        </p>
        {/* min-w is set to prevent changing card width on "lg" screens on period(price) change */}
        <span className="font-brand-h3 min-w-[140px]">
          {formatMoney(plan.amount * subscriptionPeriodToMonthAmount[period])}
        </span>
      </div>

      <div className="p-2 space-y-3">
        <div className="space-y-1 inline-flex flex-col">
          <h5 className="font-brand-link">Payment period</h5>
          {periodOptions.map(({ title, value }) => {
            const disabled = !plan.periods[value];

            return (
              <Tooltip
                key={value}
                content={
                  disabled &&
                  `For the ${
                    plan.name
                  } plan, ${title.toLowerCase()} payment period is not available.`
                }
                className="max-w-[252px]"
              >
                <Radio
                  title={title}
                  value={period === value}
                  onChange={() => {
                    setPeriod(value);
                  }}
                  disabled={disabled}
                />
              </Tooltip>
            );
          })}
        </div>

        <div className="space-y-1">
          <h5 className="font-brand-link">Payment method</h5>
          <ul>
            {record.keys(subscriptionPaymentMethod).map((paymentMethod) => {
              const available = plan.periods[period]?.methods.includes(paymentMethod);

              return (
                <li key={paymentMethod} className="flex items-center font-brand-b3">
                  <IconBox
                    icon={available ? faCheck : faTimes}
                    className={cx('mr-1', available ? 'secondary-300' : 'error-300')}
                  />
                  {subscriptionPaymentMethod[paymentMethod]}
                </li>
              );
            })}
          </ul>
        </div>

        {plan.prices && (
          <div className="space-y-1">
            <h5 className="font-brand-link">Price starts from:</h5>
            <ul>
              {record.entries(plan.prices).map(([treatment, price]) => (
                <li key={treatment} className="font-brand-b3">
                  <span className="text-text-400 mr-1">{treatmentMap[treatment]}</span>
                  {formatMoney(price)}
                </li>
              ))}
            </ul>
          </div>
        )}

        <Button
          color="secondary"
          className={cx('w-full', {
            '!bg-info-300 !border-info-300': !plan.selfService,
          })}
          onClick={onClick}
        >
          {plan.selfService ? 'Subscribe' : 'Contact us'}
        </Button>
      </div>
    </li>
  );
};

const Enterprise = () => {
  const { modalOpener } = useModalContext();

  return (
    <div className="flex flex-col md:flex-row gap-x-2 gap-y-5 p-2 rounded bg-info-100 border border-solid border-info-200">
      <div className="grow">
        <h5 className="font-brand-h5">Enterprise</h5>
        <p className="mt-1 font-brand-b3 text-text-400">
          Discover how you can accelerate your business with dedicated presentation design support
          from 24Slides.
        </p>
      </div>
      <Button
        className="!bg-info-300 !border-info-300 md:max-w-[195px]"
        color="secondary"
        onClick={modalOpener(BookMeetingModal, {
          contactForm: 'enterprises-contact-form',
        })}
      >
        Contact us
      </Button>
    </div>
  );
};
