import { useCurrentUserData } from '~/root/Auth';

export const useUserRole = () => {
  const { isStaff, customerRole, subscription, hasDailyCapacity } = useCurrentUserData();

  if (isStaff) {
    return 'staff';
  }

  if (customerRole !== 'owner') {
    return 'manager';
  }

  if (subscription && hasDailyCapacity) {
    return 'subscription';
  }

  return 'regular';
};
