import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck, faFileLines } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode, useMemo } from 'react';
import { Card, Collapse, IconBox, Link } from '~/common/components';
import { useToggle } from '~/common/hooks';
import {
  createPrefetchWrapper,
  cx,
  formatDayFullMonthYear,
  formatMoney,
  nonNullable,
} from '~/common/utils';
import { routes } from '~/constants';
import { useCurrentUserData } from '~/root/Auth';
import { useBillingData, useSubscriptionPlans, useSubscriptionPlansData } from '../hooks';

const subscriptionTreatments = {
  fixup: 'Fix up',
  redesign: 'Redesign',
  redraw: 'Redraw',
};

const withPrefetchQuery = createPrefetchWrapper(useSubscriptionPlans);

export const ThankYou = withPrefetchQuery(() => {
  const billing = useBillingData();
  const user = useCurrentUserData();
  const plans = useSubscriptionPlansData();

  const {
    subscription: billingSubscription,
    company,
    billingAddress,
    defaultPaymentMethod: billingDefaultPaymentMethod,
  } = billing;

  const subscription = nonNullable(billingSubscription);
  const currentPlan = nonNullable(
    plans.find((plan) => plan.id === subscription.products.retainer?.plan.id),
  );
  const defaultPaymentMethod = nonNullable(billingDefaultPaymentMethod);

  const subscriptionPrice = useMemo(() => {
    const monthAmount = subscription.renewalPeriod;

    if (monthAmount <= 1) {
      return `${formatMoney(currentPlan.amount)} per month`;
    }

    return `${formatMoney(currentPlan.amount * monthAmount)} per ${monthAmount} months`;
  }, [currentPlan, subscription.renewalPeriod]);

  const treatmentPrices = useMemo(() => {
    if (!currentPlan.prices) {
      return;
    }

    return Object.entries(currentPlan.prices).map(([treatment, price]) => {
      if (treatment === 'freehand') {
        return;
      }

      return `1 ${
        subscriptionTreatments[treatment as keyof typeof subscriptionTreatments]
      } slide = $${price}`;
    });
  }, [currentPlan]);

  return (
    <>
      <Card className="flex justify-center mb-3">
        <div className="flex flex-col items-center max-w-[656px] mt-1 mb-2">
          <IconBox icon={faCircleCheck} className="text-[48px] text-secondary-300 h-8 w-8" />
          <p className="font-brand-h4 text-text-500 mb-1">Thank you!</p>
          <p className="font-brand-b3 text-text-400 mb-3 text-center">
            Welcome aboard! Your project manager will email you within 1 working day. In the
            meantime, you can submit a new order or contact{' '}
            <Link href="mailto:support@24slides.com">support@24slides.com</Link> for any assistance.
          </p>
          <Link.Button to={routes.order} color="secondary">
            Order Now
          </Link.Button>
        </div>
      </Card>

      <Card className="flex flex-col gap-4 sm:gap-3">
        <div className="p-[12px] border border-solid border-secondary-200 bg-secondary-100 rounded-md text-text-500 font-brand-b3">
          <div className="flex gap-1 items-center font-brand-h6 text-secondary-300 mb-1">
            <IconBox icon={faFileLines} className="text-secondary-200 text-[26px] w-4 h-4" />
            Subscription confirmation
          </div>
          Your order confirmation is below. We’ll also send you an email with it shortly.
        </div>
        <div className="flex sm:flex-col gap-2">
          <div className="space-y-3 flex-1 max-w-[330px]">
            <SummaryItem title="Customer" entries={[user.name]} />
            {company && (
              <SummaryItem
                title="Company details"
                entries={[company.name, company.billingContact, company.vatNumber]}
              />
            )}
          </div>
          <div className="flex-1">
            <SummaryItem
              title="Billing address"
              entries={[
                billingAddress.country?.name,
                billingAddress.street,
                billingAddress.city,
                billingAddress.postcode,
                billingAddress.state,
              ]}
            />
          </div>
        </div>
        <hr className="h-[1px] text-other-300" />
        <div className="flex sm:flex-col gap-2">
          <div className="space-y-3 flex-1 max-w-[330px]">
            <SummaryItem
              title="Service purchased"
              entries={[subscription.products.retainer?.plan.name + ' Subscription']}
            />
            <SummaryItem
              title="Payment method"
              entries={[defaultPaymentMethod.type === 'card' ? 'Credit card' : 'Invoice']}
            />
            <SummaryItem title="Price" entries={[subscriptionPrice]} />
          </div>
          <div className="space-y-3 flex-1">
            {treatmentPrices && <SummaryItem title="Price per slide" entries={treatmentPrices} />}
            <SummaryItem
              title="Next billing date"
              entries={[
                subscription.renewsAt ? formatDayFullMonthYear(subscription.renewsAt) : undefined,
              ]}
            />
          </div>
        </div>
        <p className="text-brand-b2 text-text-400 mb-1">
          Your subscription is automatically renewable until canceled, in accordance with 24Slides's
          Terms and Conditions of Contract. Your order is subject to these terms, and we process
          data in compliance with the agreed data protection terms from your sign-up.
        </p>
      </Card>

      {/* Show only after "Pro" plan purchase */}
      {subscription.products.retainer?.plan.id === 7 && (
        <Card title="Frequently Asked Questions" className="mt-3">
          <div className="flex flex-col gap-2">
            {proPlanFAQ.map(({ question, answer }, i) => (
              <FAQ key={i} question={question} answer={answer} />
            ))}
          </div>
        </Card>
      )}
    </>
  );
});

type SummaryItemProps = {
  title: string;
  entries: (string | undefined | null)[];
};

const SummaryItem = ({ title, entries }: SummaryItemProps) => {
  const filteredEntries = entries.filter(Boolean) as string[];

  return (
    <div className="font-brand-b3 text-text-500">
      <p className="text-text-300 font-brand-c3 uppercase">{title}</p>
      {filteredEntries.map((entry) => (
        <p key={entry}>{entry}</p>
      ))}
    </div>
  );
};

const proPlanFAQ = [
  {
    question: 'How 10-hour turnaround works?',
    answer:
      'As a Pro Subscriber, you will receive your first draft of submitted slides within a maximum of 10 hours. You can use any combination of slide treatments.',
  },
  {
    question: 'Can I pay by invoice?',
    answer:
      'You can not pay a Pro Subscription by Invoice. For this subscription type we only accept credit cards. However, we have other subscription plans where you can pay by Invoice. Feel free to check them out here.',
  },
  {
    question: 'What happens if my payment fails?',
    answer:
      'If payment is not completed, your subscription will be paused or canceled. During this period, you can still place orders, but only at standard pricing and without the perks of your subscription plan.',
  },
  {
    question: 'How do I view my billing history and invoices?',
    answer: (
      <>
        You can access your billing history in your account:
        <p>
          {'->'} Select <span className="font-bold text-text-500">Billing</span> in the left hand
          menu.
        </p>
        <p>
          {'->'} Select the tab <span className="font-bold text-text-500">Billing overview</span>.
        </p>
        Here you can find all information regarding your{' '}
        <span className="font-bold text-text-500">Current Balance</span>,{' '}
        <span className="font-bold text-text-500">Orders Created</span>,{' '}
        <span className="font-bold text-text-500">Billing Information</span> &{' '}
        <span className="font-bold text-text-500">Payment History</span>
      </>
    ),
  },
  {
    question: 'How can I cancel my Pro Subscription?',
    answer: (
      <>
        To cancel your Pro Subscription or any Subscription your need to log in to your account:
        <p>{'->'} Select Billing in the left hand menu.</p>
        <p>{'->'} Select the tab Billing overview.</p>
        <p>
          {'->'} Click the <span className="font-bold text-text-500">Cancel Subscription</span>{' '}
          button displayed under your <span className="font-bold text-text-500">Subscription</span>{' '}
          box at the top.
        </p>
      </>
    ),
  },
  {
    question: 'Can I get a refund if I cancel my subscription?',
    answer:
      'No, it is not possible to get a refund on a subscription. However, any credits that were accumlated with a any subscription will still be valid to use for a limited amount of time.',
  },
  {
    question: 'What should I do if I have a billing dispute or issue?',
    answer: (
      <>
        Please contact <Link href="mailto:support@24slides.com">support@24slides.com</Link> or{' '}
        <Link href="mailto:finance@24slides.com">finance@24slides.com</Link> for support.
      </>
    ),
  },
];

const FAQ = ({ question, answer }: { question: string; answer: ReactNode }) => {
  const [isCollapsed, toggleCollapsed] = useToggle(true);

  return (
    <div className="py-2 px-3 bg-other-100 rounded-md">
      <div className="flex gap-2 items-center justify-between" onClick={toggleCollapsed}>
        <p className="font-brand-l2">{question}</p>
        <IconBox icon={faChevronUp} className={cx('ml-auto', isCollapsed && 'rotate-180')} />
      </div>
      <Collapse in={!isCollapsed}>
        <p className="font-brand-b3 mt-2 text-text-400">{answer}</p>
      </Collapse>
    </div>
  );
};
