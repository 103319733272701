import MenuItem from '@material-ui/core/MenuItem';
import { useMemo } from 'react';
import { useModalContext } from '~/common/components';
import { Any, cx } from '~/common/utils';
import OrdersSelect from '~/pages/My-profile/components/OrdersPage/components/OrdersSelect';
import { CardPaymentMethod, PaymentMethod as PaymentMethodType } from '~/root/domain';
import { AddCreditCardModal } from '../../Billing';
import { useConfirmationContext } from '../utils';
import SelectItem from './SelectItem';

// Amount of card a user is able to add
export const CARDS_LIMIT = 3;

interface PaymentMethodProps {
  methods: PaymentMethodType[];
}

export const PaymentMethod = ({ methods }: PaymentMethodProps) => {
  const { paymentMethodId, setPaymentMethod, setPaymentMethods } = useConfirmationContext();

  const { modalOpener } = useModalContext();

  const { cardPaymentMethods, canAddNewCard } = useMemo(
    () => ({
      cardPaymentMethods: methods.filter((method) => method.type === 'card') as CardPaymentMethod[],
      canAddNewCard: methods.length < CARDS_LIMIT,
    }),
    [methods],
  );

  return (
    <div>
      {!!cardPaymentMethods.length && (
        <OrdersSelect
          label="Select Payment Method"
          value={paymentMethodId}
          onChange={setPaymentMethod}
        >
          {cardPaymentMethods.map(({ id, default: isDefault, type, last4, brand }) => (
            <MenuItem key={id} value={id}>
              <SelectItem last4={last4} isDefault={isDefault} brand={brand} method={type} />
            </MenuItem>
          ))}
        </OrdersSelect>
      )}
      {canAddNewCard && (
        <div className="new-payment-method">
          <button
            type="button"
            className="link-style"
            onClick={modalOpener(AddCreditCardModal, {
              onAdd: (cards) => {
                setPaymentMethods(cards);
                // TODO haha this is soo stupid
                setPaymentMethod({
                  target: {
                    value: cards.find((card) => card.default)?.id || '',
                  },
                } as Any);
              },
            })}
          >
            {cx('+ Add', !!cardPaymentMethods.length && 'another', 'card')}
          </button>
        </div>
      )}
    </div>
  );
};
