/* eslint-disable no-console */
import { ENV } from '~/env';

export const dreamdata = {
  ready: ENV.PRODUCTION && window.analytics,

  identify(email: string | null) {
    if (this.ready) {
      window.analytics?.identify(null, { email });
    }

    if (ENV.DEVELOPMENT) {
      console.log('dreamdata identify:', email);
    }
  },

  track(event: string) {
    if (this.ready) {
      window.analytics?.track(event);
    }

    if (ENV.DEVELOPMENT) {
      console.log('dreamdata tracking:', event);
    }
  },
};
