import { v4, v5 } from 'uuid';
import { z } from 'zod';
import { unsafeRefine } from './common';

const NAMESPACE = '00000000-0000-0000-0000-000000000000';
const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

interface UUIDBrand {
  readonly UUID: unique symbol;
}

export type UUID = string & UUIDBrand;

export const isUUID = (s: string): s is UUID => UUID_REGEX.test(s);

export const uuid = unsafeRefine(isUUID);

export const randomUUID = () => v4() as UUID;

export const seededUUID = (seed: string) => v5(seed, NAMESPACE) as UUID;

export const UUID = z.string().refine(isUUID);
