import { FieldProps } from 'formoid';
import { InputHTMLAttributes, useRef } from 'react';
import { Errors } from '~/common/components';
import { useFileDropzone } from '~/common/hooks';
import { cx } from '~/common/utils';
import { UploadedFile } from '~/root/domain';

export type FileUploadProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'onChange' | 'size' | 'value' | 'accept' | 'multiple'
> &
  Omit<FieldProps<File | null>, 'value'> &
  Partial<{
    file: UploadedFile | null;
    value: string | null;
    loading: boolean;
    size: 'small' | 'regular';
    hintLeft: string;
    hintRight: string;
    acceptedMimeTypes: string[];
    wrapperClassName: string;
  }> & {
    placeholderRenderer: (props: EmptyItemRendererProps) => JSX.Element;
    itemRenderer: ({ ...props }: NonEmptyItemRendererProps) => JSX.Element;
    progress: number;
  };

export interface EmptyItemRendererProps {
  draggedOver: boolean;
  error: boolean;
}

export interface NonEmptyItemRendererProps {
  isLoading?: boolean;
  isError: boolean;
  file: UploadedFile;
  clear: () => void;
  disabled: boolean;
  progress: number;
}

export const FileUpload = ({
  disabled,
  errors,
  touched,
  onChange,
  onBlur,
  title,
  loading,
  hintLeft,
  hintRight,
  size = 'regular',
  className,
  wrapperClassName,
  children,
  value: filename,
  file,
  placeholderRenderer,
  itemRenderer,
  acceptedMimeTypes,
  progress,
  ...props
}: FileUploadProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [{ onClick, ...dropzoneProps }, draggedOver] = useFileDropzone(
    {
      onChange,
      disabled: disabled || loading,
      acceptedMimeTypes,
      ...props,
    },
    containerRef,
  );

  const clear = () => {
    onChange(null);
  };

  return (
    <label
      className={cx(
        'flex flex-col w-full',
        wrapperClassName,
        disabled ? 'cursor-default' : 'cursor-pointer',
      )}
    >
      {title && (
        <span
          className={cx('block mb-[4px]', size === 'small' ? 'font-brand-b2r' : 'font-brand-b1')}
        >
          {title}
        </span>
      )}
      <div
        ref={containerRef}
        className={cx(
          'relative flex flex-col items-center justify-center def:w-full def:h-full group rounded-md overflow-hidden',
          className,
        )}
        {...dropzoneProps}
      >
        {file
          ? itemRenderer({
              clear,
              isLoading: loading,
              file,
              isError: !!errors?.length,
              disabled,
              progress,
            })
          : placeholderRenderer({
              draggedOver,
              error: Boolean(errors),
            })}
      </div>
      {(hintLeft || hintRight) && (
        <div className="flex mt-2 font-brand-b3 text-text-400">
          <span>{hintLeft}</span>
          <span className="ml-auto">{hintRight}</span>
        </div>
      )}
      {errors && <Errors errors={errors} />}
    </label>
  );
};
