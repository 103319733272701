import { createSelector } from 'reselect';
import { calculateOrderPrice } from '~/pages/Order-now/utils';
import { createLoadingSelector } from '~/redux/modules/api/request.selectors';

export const topbarDataSelector = (state) => {
  const {
    coupon = {},
    details: {
      currency,
      price: formPrice,
      treatments = {},
      slidesNumber = {},
      deliveryDates = {},
      addons = {},
      paymentDetails = {},
      brief,
      styles,
    },
  } = state.data;

  const addon = addons.price;
  const { turnaround, deadline } = deliveryDates;
  const slides = coupon.slides || slidesNumber.slides || 0;
  const { treatmentName: treatment, price: treatmentPrice, turnaroundPrices = {} } = treatments;

  const treatmentWithTurnaroundPrice = turnaroundPrices?.[turnaround] || treatmentPrice;

  const price =
    formPrice ||
    coupon.price ||
    calculateOrderPrice({
      price: treatmentWithTurnaroundPrice,
      slides,
      addon,
      coupon,
    });

  return {
    price,
    slides,
    treatment,
    deadline,
    currency,
    brief: brief?.brief,
    style: styles?.style,
    cardToken: paymentDetails?.cardToken,
  };
};

export const turnaroundUpdateWithSelector = (state) => ({
  name: state.data.details.name,
  treatment: state.data.details.treatments?.treatment,
  slides: state.data.details.slidesNumber?.slides,
});

export const notificationsStatusSelector = (state) => state.data.notifications;

export const notificationsRequestsSelector = createSelector(
  createLoadingSelector('GET_NOTIFICATIONS'),
  createLoadingSelector('UPDATE_NOTIFICATIONS'),
  (isGetRequestLoading, isUpdateRequestLoading) => isGetRequestLoading || isUpdateRequestLoading,
);

export const qnaHintsSelector = (state) =>
  state.data.properties?.additional?.questions?.hideHints || false;

export const temporaryCardDataSelector = (state) => state.data.card;
