import { ColorSet } from './Avatar';

const SATURATION = 35;
const LIGHTNESS = 65;

const HUE_ANGLES = 360;

const colorSets: ColorSet[] = Array(HUE_ANGLES)
  .fill(0)
  .map((_, angle) => ({
    backgroundColor: `hsl(${angle}deg, ${SATURATION}%, ${LIGHTNESS}%)`,
    color: 'white',
  }));

export const getColorsFromNumber = (n: number) => colorSets[n % colorSets.length];

export const getColorsFromString = (s: string) => {
  let hash = 0;

  for (let i = 0; i < s.length; i += 1) {
    hash = s.charCodeAt(i) + ((hash << 5) - hash);
  }

  return getColorsFromNumber(hash);
};

export const getInitials = (name: string) => {
  const words = name.split(' ');

  if (words.length === 1) {
    return words[0].slice(0, 2).toUpperCase();
  }

  return words
    .map((word) => word.slice(0, 1).toUpperCase())
    .slice(0, 2)
    .join('');
};
