import { z } from 'zod';

export const BackEndValidationErrors = z.object({
  errors: z.record(z.array(z.string()).nonempty()),
  status: z.literal('error'),
});

const errorSchema = z
  .object({
    response: z.object({
      data: BackEndValidationErrors,
    }),
  })
  .transform((error) => error.response.data.errors);

export function decodeBackendErrors(error: unknown) {
  const result = errorSchema.safeParse(error);
  return result.success ? result.data : null;
}
