import { useReducer, useCallback } from 'react';

export type ModalStateType = {
  open: boolean;
};

export type ModalActionsType =
  | {
      type: 'OPEN';
    }
  | {
      type: 'CLOSE';
    };

const modalReducer = (state: ModalStateType, action: ModalActionsType) => {
  switch (action.type) {
    case 'OPEN':
      return { ...state, open: true };

    case 'CLOSE':
      return { ...state, open: false };

    default:
      return state;
  }
};

export const useModal = (initialStatus?: boolean) => {
  const [state, dispatch] = useReducer(modalReducer, {
    open: !!initialStatus,
  });

  const setModalOpen = useCallback(() => {
    dispatch({
      type: 'OPEN',
    });
  }, []);

  const setModalClose = useCallback(() => {
    dispatch({
      type: 'CLOSE',
    });
  }, []);

  return [
    state,
    {
      setModalClose,
      setModalOpen,
    },
  ];
};
