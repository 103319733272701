import { Any } from '~/common/utils';

type ActionType = {
  type: string;
  payload: string;
};

const RESET_FETCH = 'RESET_LAST_DATE_FETCH';

export const lastFetchReducer = (state: Any = {}, action: ActionType) => {
  const { type } = action;

  if (type === RESET_FETCH) {
    return { ...state, [action.payload]: '' };
  }

  const matches = /(.*)_(SUCCESS|FAILURE|RESET_FETCH)/.exec(type);

  // ignore not *_SUCCESS / *_FAILURE actions
  if (!matches) {
    return state;
  }

  const [, requestName] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: new Date().toISOString(),
  };
};
