import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Button } from '~/common/components';
import { useDevice } from '~/common/kits/device';
import { axios, redirectUserOnAxiosResponse } from '~/root';
import { useCurrentUser } from '~/root/Auth';
import * as images from './images';

import './_upsell-popup.scss';

interface UpsellPopupProps {
  newPrice: number;
  regularPrice: number;
  extraPerSlide: number;
  coupon: string;
  onClose: () => void;
  onAccept: () => void;
}

export const UpsellPopup = ({
  newPrice,
  regularPrice,
  extraPerSlide,
  coupon,
  onClose,
  onAccept,
}: UpsellPopupProps) => {
  const device = useDevice();
  const isMobile = device === 'MOBILE';

  const user = useCurrentUser();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'upsell',
      eventAction: '24hr-post-order',
      eventLabel: 'shown',
    });
  }, []);

  const handleAccept = () => {
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'upsell',
      eventAction: '24hr-post-order',
      eventLabel: 'accepted',
      eventValue: newPrice,
    });

    setSubmitting(true);
    // eslint-disable-next-line no-console
    axios
      .post(`/v1/orders/${user?.orderId}/coupons/${coupon}`)
      .then((response) => {
        if (response?.data.redirectUrl) {
          redirectUserOnAxiosResponse(response.data.redirectUrl);
        }

        onClose();

        return response;
      })
      .catch(console.warn);

    onAccept();
  };

  return (
    <div className="upsell__container">
      <div className="upsell__content">
        <img src={images.rocketIcon} alt="rocket icon" className="upsell__rocket" />
        <h2 className="upsell__title">
          Order sent! <br className="mobile" /> Want to SUPERCHARGE your project?
        </h2>

        <div className="upsell__discount-container">
          <img src={isMobile ? images.frameMobile : images.frameDesktop} alt="frame" />
          <p>Get your slides back in 24 hours</p>
          <p>
            for just + <span> ${extraPerSlide} per slide</span>
          </p>

          {!isMobile && <img src={images.illustrationDesginer} alt="designer" />}
        </div>

        <p className="upsell__description">
          You&apos;ll be charged ${newPrice} <br />
          instead of ${regularPrice} on completion of your order
        </p>

        <div>
          <Button
            className="upsell__button"
            size="m"
            color="secondary"
            onClick={handleAccept}
            loading={submitting}
          >
            Yes, Upgrade to 24 Hours
          </Button>

          <button
            className="upsell__cancel upsell__regular"
            type="button"
            onClick={onClose}
            disabled={submitting}
          >
            No, thanks
          </button>
        </div>
      </div>
    </div>
  );
};
