export const colors = {
  /**
   * Greysacele
   */
  storm: '#697082',
  manatee: '#8C93A3',
  rock: '#929BAD',
  spindle: '#B0BACA',
  loblolly: '#BEC5D0',
  hawkes: '#CDD7E5',
  solitude: '#E2E9F3',
  athens: '#EAF0F7',
  alice: '#F6F8FC',
  white: '#ffffff',

  /**
   * Green
   */
  observatory: '#009266',
  shamrock: '#3EBF8A',
  riptide: '#A0E2C6',
  spring: '#EEFFF4',

  /**
   * Blue
   */
  mirage: '#131831',
  biscay: '#1E2860',
  luckypoint: '#2B3151',
  cobalt: '#004AA0',
  denim: '#0A66BB',
  cornflower: '#9FC3EE',
  zumthor: '#F0F6FC',

  /**
   * Purple
   */
  violet: '#594BB5',
  perfume: '#CFC7F0',
  magnolia: '#F2EFFF',

  /**
   * Red
   */
  mandy: '#E1485B',
  azalea: '#F8BCBF',
  chablis: '#FFF4F5',

  /**
   * Orange
   */
  gamboge: '#D88E00',
  visvis: '#F8DA8E',
  fizz: '#FFFBE4',

  /**
   * Outdated colors
   */
  /** Greysacele */
  jaguar: '#2A2A2A',
  charcoal: '#454545',
  graysuit: '#9398a2',
  athensgray: '#ebedf8',
  alabaster: '#fafbfc',

  /** Green */
  salem: '#1f7753',
  mint: '#46b688',

  /** Blue */
  pearl: '#1b2532',
  blumine: '#095c88',
  lochmara: '#007ac5',

  /** Red  */
  darkpastelred: '#bf3e29',
  terracotta: '#ed705c',
};

export const palette = {
  primary: {
    400: colors.cobalt,
    300: colors.denim,
    200: colors.cornflower,
    100: colors.zumthor,
  },

  secondary: {
    400: colors.observatory,
    300: colors.shamrock,
    200: colors.riptide,
    100: colors.spring,
  },

  success: {
    300: colors.observatory,
    200: colors.riptide,
    100: colors.spring,
  },

  warning: {
    300: colors.gamboge,
    200: colors.visvis,
    100: colors.fizz,
  },

  error: {
    300: colors.mandy,
    200: colors.azalea,
    100: colors.chablis,
  },

  info: {
    300: colors.violet,
    200: colors.perfume,
    100: colors.magnolia,
  },

  text: {
    700: colors.mirage,
    600: colors.biscay,
    500: colors.luckypoint,
    400: colors.storm,
    300: colors.manatee,
    200: colors.loblolly,
    100: colors.white,
  },

  other: {
    600: colors.rock,
    500: colors.spindle,
    400: colors.hawkes,
    300: colors.solitude,
    200: colors.athens,
    100: colors.alice,
  },

  /**
   * todo: add color getter
   *
   * color(color: string): string {
   *   return '';
   * };
   */
};

export default palette;
