import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React from 'react';
import { Overwrite } from '~/common/utils';

type Sizes = 16 | 24 | 32 | 40 | 48;

type Props = Overwrite<
  React.ComponentPropsWithoutRef<typeof FontAwesomeIcon>,
  {
    icon: FontAwesomeIconProps['icon'];
    className?: string;
    size?: Sizes;
  }
>;
//TODO Change to similar component from Inner Platform and refactor all usages
export const IconBox = ({ className = '', size = 24, icon, ...props }: Props) => {
  if (icon) {
    return (
      <div className={cx(`icon-box f-center box-${size}`, className)}>
        <FontAwesomeIcon {...props} icon={icon} />
      </div>
    );
  }

  return null;
};
