import { Button } from '~/common/components';

import * as images from '~/pages/Security/components/Needed/images';

export const SecurityModal = ({ onClose }: { onClose: () => void }) => (
  <div className="security-modal__container">
    <span className="security-modal__title">How we secure your information</span>
    <div className="security-modal__content">
      <div className="secure-block">
        <div className="secure-block__image">
          <img src={images.one} alt="" />
        </div>
        <span className="secure-block__head">Secure datacenters</span>
        <span className="secure-block__text">
          These datacenters are individually audited and certified by various
          internationally-recognized compliance standards including SOC 1, SOC 2, PCI-DSS and ISO
          27001.
        </span>
      </div>
      <div className="secure-block">
        <div className="secure-block__image">
          <img src={images.two} alt="" />
        </div>
        <span className="secure-block__head">PCI-DSS compliant</span>
        <span className="secure-block__text">
          Our payments are PCI-DSS compliant. This is the most stringent level of certification
          available in the payments industry.
        </span>
      </div>
      <div className="secure-block">
        <div className="secure-block__image">
          <img src={images.three} alt="" />
        </div>
        <span className="secure-block__head">Full on-site security</span>
        <span className="secure-block__text">
          All employees must sign in and out of the facility and adhere by our comprehensive policy
          regarding use of work and personal devices.
        </span>
      </div>
    </div>
    <div className="security-modal__content__button-section">
      <Button onClick={onClose} size="m" color="secondary">
        Continue
      </Button>
    </div>
  </div>
);
