import './please-wait.scss';
type Props = {
  error?: string;
};
const defaultProps = {
  error: null,
};

const PleaseWait = ({ error }: Props) => {
  if (error) {
    console.error(error);
  }

  return (
    <div className="please-wait-container">
      <h1 className="wait-message">Please wait...</h1>
    </div>
  );
};

PleaseWait.defaultProps = defaultProps;
export default PleaseWait;
