import { validator } from 'formoid';
import { z } from 'zod';

// TODO add 'select_many' once we support select multiple
const customFieldTypes = ['bool', 'select_one', 'text'] as const;

export type CustomFieldType = (typeof customFieldTypes)[number];

export const isCustomFieldType = (value: unknown): value is CustomFieldType => {
  return customFieldTypes.includes(value as CustomFieldType);
};

export const friendlyNamesMap: Record<(typeof customFieldTypes)[number], string> = {
  text: 'Text',
  bool: 'Checkbox',
  select_one: 'Single select',
};

export const customFieldOptions = customFieldTypes.map((value) => ({
  value,
  name: friendlyNamesMap[value],
}));

const customFieldTypeSchema = z.enum(customFieldTypes);

const customFieldPayloadSchema = z.object({
  title: z.string(),
  description: z.string().nullable(),
  type: customFieldTypeSchema,
  options: z.array(z.string()),
  is_required: z.boolean(),
});

export type CustomFieldPayload = z.infer<typeof customFieldPayloadSchema>;

export const customFieldSchema = customFieldPayloadSchema.extend({ id: z.number() });

export type CustomField = z.infer<typeof customFieldSchema>;

export const customFieldWithValueSchema = customFieldSchema.extend({
  // TODO add z.array(z.string()) once we support select multiple
  value: z.union([z.boolean(), z.string()]).nullable(),
});

export type CustomFieldWithValue = z.infer<typeof customFieldWithValueSchema>;

export type CustomFieldValue = z.infer<typeof customFieldWithValueSchema.shape.value>;

export const customFieldsSchema = z
  .object({
    data: z.array(customFieldSchema),
  })
  .transform(({ data }) => data.map((item) => ({ ...item, actions: null })));

export type CustomFieldsList = z.infer<typeof customFieldsSchema>;

export const customFieldValidator = (title: string) => {
  return validator.fromPredicate(
    (value): value is NonNullable<CustomFieldValue> => !!value,
    `${title} is required`,
  );
};
