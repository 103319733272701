import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ZodError } from 'zod';
import { useIdParam } from '~/common/hooks';
import { axios } from '~/root';
import { CommentsEvents, commentSchema, commentsEventsSchema } from '../domain';
import { ordersQueryKey, setSubscribedTo, useUpdateOrderCache } from './useOrder';

export const normalizeEvents = ({ comments, events }: CommentsEvents) => {
  const commentList = comments.map((comment) => ({
    type: 'comment' as const,
    ...comment,
  }));
  const eventList = events.map((event) => ({
    type: 'event' as const,
    ...event,
  }));
  return [...commentList, ...eventList].sort((a, b) => a.timestamp - b.timestamp);
};

export type NormalizedEvents = ReturnType<typeof normalizeEvents>;

const eventsKey = (id: number) => [ordersQueryKey, id, 'events'];

export const useEvents = () => {
  const id = useIdParam();

  return useQuery({
    queryKey: eventsKey(id),
    queryFn: async ({ signal }) => {
      return axios
        .get(`/v1/orders/${id}/comments`, { signal })
        .then((res) => {
          const response = commentsEventsSchema.parse(res.data);
          return normalizeEvents(response);
        })
        .catch((error) => {
          if (error instanceof ZodError) {
            throw error;
          }
          return [] as ReturnType<typeof normalizeEvents>;
        });
    },
    refetchOnWindowFocus: true,
  });
};

export type CommentPayload = {
  comment: string;
  files: string[];
};

export const usePostComment = () => {
  const id = useIdParam();
  const client = useQueryClient();
  const { setQuery } = useUpdateOrderCache();

  return useMutation({
    mutationFn: (payload: CommentPayload) => {
      return axios.post(`/v1/orders/${id}/comments`, payload).then((res) => {
        const comment = commentSchema.parse(res.data.comment);
        client.setQueryData<NormalizedEvents>(eventsKey(id), (prev) => {
          return prev && [...prev, { ...comment, type: 'comment' as const }];
        });
        setQuery((prev) => setSubscribedTo(true, prev));
        return comment;
      });
    },
  });
};
