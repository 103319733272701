import { z } from 'zod';
import { compensateDateOffset } from '~/common/utils';
import { countrySchema } from './app';

export const billingSharedSchema = z.object({
  state: z.string().nullable(),
  city: z.string().nullable(),
  street: z.string().nullable(),
  postcode: z.string().nullable(),
  phoneCountryId: z.number().nullable(),
  phoneNumber: z.string().nullable(),
  country: countrySchema.nullable(),
});

const basePaymentMethodSchema = z.object({
  id: z.number(),
  default: z.boolean(),
  vat: z.number().nullable(),
});

export const cardBrandSchema = z.string();

export type CardBrand = z.infer<typeof cardBrandSchema>;

export const cardPaymentMethodSchema = basePaymentMethodSchema.extend({
  type: z.literal('card'),
  brand: cardBrandSchema,
  last4: z.string(),
  expirationMonth: z.number(),
  expirationYear: z.number(),
});

export type CardPaymentMethod = z.infer<typeof cardPaymentMethodSchema>;

export const invoicePaymentMethodSchema = basePaymentMethodSchema
  .merge(billingSharedSchema)
  .extend({
    type: z.literal('invoice'),
    poNumber: z.string().nullable(),
    poExpiresAt: z.coerce.date().transform(compensateDateOffset).nullable(),
  });

export type InvoicePaymentMethod = z.infer<typeof invoicePaymentMethodSchema>;

export const paymentMethodSchema = z.union([invoicePaymentMethodSchema, cardPaymentMethodSchema]);

export type PaymentMethod = z.infer<typeof paymentMethodSchema>;
