import { Any } from '~/common/utils';
import { GenericItem, TableConfig, TableData, TableView } from '../types';
import { getSort } from '../utils';
import { useParsedQuery } from './useParsedQuery';

type BackendTableParams<T extends GenericItem> = {
  data?: TableData<T>;
  tableConfig: TableConfig<T>;
  isFetching: boolean;
};

export const useBackendTable = <T extends GenericItem>({
  data,
  tableConfig,
  isFetching,
}: BackendTableParams<T>): TableView<T> | null => {
  const { queryParams, setQueryParams } = useParsedQuery();

  // TODO fix type, or whatever
  const onSort = (option: Any) => {
    setQueryParams((params) => ({
      ...params,
      sort: getSort(params.sort?.option === option ? params.sort : option),
    }));
  };

  const onPageChange = (page: number) => {
    setQueryParams((params) => ({ ...params, page }));
  };

  if (!data) {
    return null;
  }

  return {
    tableConfig,
    items: data.items,
    totalPages: data.metadata.pages,
    total: data.metadata.total,
    results: data.metadata.results,
    page: queryParams.page ?? 1,
    onPageChange,
    sort: queryParams.sort ?? null,
    onSort,
    isFetching,
  };
};
