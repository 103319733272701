import { useState, useEffect } from 'react';
import { Component, Props } from '../utils';

export const MOBILE = 'MOBILE';
export const MOBILE_WIDTH = 480;

export const TABLET = 'TABLET';
export const TABLET_WIDTH = 768;

export const DESKTOP = 'DESKTOP';
export const DESKTOP_WIDTH = 1200;

const getDevice = (width: number) => {
  if (width < TABLET_WIDTH) {
    return 'MOBILE' as const;
  }

  if (width >= DESKTOP_WIDTH) {
    return 'DESKTOP' as const;
  }

  return 'TABLET' as const;
};

export const useDevice = () => {
  const [device, setDevice] = useState(getDevice(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      const newDevice = getDevice(window.innerWidth);
      if (newDevice !== device) {
        setDevice(newDevice);
      }
    };

    // TODO: think about `resize` event amount
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [device]);

  return device;
};

export const withDevice = <P,>(Component: Component<P>) => {
  return (props: Props<P>) => {
    const device = useDevice();

    return <Component device={device} {...props} />;
  };
};
