import { faPen, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { useModalContext, Button } from '~/common/components';
import { FrontendTableConfig } from '~/common/kits/table';
import { CustomFieldModal } from './CustomFieldsModal';
import { DeleteCustomFieldModal } from './DeleteCustomFieldModal';
import { CustomField, CustomFieldsList, friendlyNamesMap } from './domain';

const Actions = (field: CustomField) => {
  const { modalOpener } = useModalContext();

  return (
    <>
      <Button.Icon icon={faPen} onClick={modalOpener(CustomFieldModal, { field })} />
      <Button.Icon icon={faTrashAlt} onClick={modalOpener(DeleteCustomFieldModal, { field })} />
    </>
  );
};

export const tableConfig: FrontendTableConfig<CustomFieldsList[number]> = {
  columns: [
    {
      key: 'title',
      name: 'Title',
      width: 'minmax(240px, 1fr)',
    },
    {
      key: 'type',
      name: 'Type',
      cellRenderer: ({ cellData }) => friendlyNamesMap[cellData],
      width: 'minmax(128px, 1fr)',
    },
    {
      key: 'options',
      name: 'Value',
      cellRenderer: ({ cellData }) => (cellData.length > 0 ? cellData.join(', ') : undefined),
      width: 'minmax(200px, 1fr)',
    },
    {
      key: 'is_required',
      name: 'Mandatory',
      cellRenderer: ({ cellData }) => (cellData ? 'Yes' : 'No'),
      width: 'minmax(100px, 1fr)',
      getCellValue: (cellData) => (cellData ? 'Yes' : 'No'),
    },
    {
      key: 'actions',
      name: 'Actions',
      cellRenderer: ({ rowData }) => <Actions {...rowData} />,
      getClassName: () => 'gap-3',
      width: 'minmax(100px, 1fr)',
    },
  ],
  sortable: ['title', 'type', 'is_required'],
  searchable: [],
};
