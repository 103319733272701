import { useMutation } from '@tanstack/react-query';
import { Email } from '~/common/utils';
import { axios } from '~/root';
import { ContactFormNames } from './types';

type BookMeetingPayload = {
  name: string;
  email: Email | null;
  dialCode: number | null;
  phone: string | null;
  country?: string;
  company?: string;
};

export const useBookMeeting = () => {
  return useMutation({
    mutationFn: ({
      contactForm,
      payload,
    }: {
      contactForm: ContactFormNames;
      payload: BookMeetingPayload;
    }) => axios.post(`/v1/contact/closeio/${contactForm}`, payload),
  });
};
