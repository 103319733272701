import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { ENV } from '~/env';
import { rootReducer } from './modules';

const initialState = {};
const middleware = [thunk];

export const store = createStore(
  rootReducer,
  initialState,
  ENV.PRODUCTION
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware)),
);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./modules', () => {
    const nextRootReducer = require('./modules').default;
    store.replaceReducer(nextRootReducer);
  });
}
