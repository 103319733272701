import { ModalContentProps, ModalHeader, ModalShell, Toast } from '~/common/components';
import { PaymentMethod } from '~/root/domain';
import { useBillingData, useDeletePaymentMethod } from '../hooks';
import { CreditCardPaymentMethod } from './CreditCardPaymentMethod';
import { InvoicePaymentMethod } from './InvoicePaymentMethod';

type DeletePaymentMethodProps = ModalContentProps & PaymentMethod;

export const DeletePaymentMethodModal = ({
  onClose,
  ...paymentMethod
}: DeletePaymentMethodProps) => {
  const deletePaymentMethodMutation = useDeletePaymentMethod();
  const { company } = useBillingData();

  const submit = () => {
    deletePaymentMethodMutation
      .mutateAsync(paymentMethod.id)
      .then(() => Toast.success({ message: 'Your payment method has been successfully removed.' }))
      .then(onClose)
      // TODO errors like these should be handled at the interceptors level
      .catch(() => {
        Toast.error({
          message:
            'Something went wrong. Please try again or contact our support team for assistance.',
        });
      });
  };

  return (
    <>
      <ModalHeader title="Remove this payment method?" onClose={onClose} />
      <ModalShell
        submitText="Remove"
        onClose={onClose}
        onSubmit={submit}
        loading={deletePaymentMethodMutation.isLoading}
        className="md:w-[632px]"
        negativeAction
      >
        {paymentMethod.type === 'card' ? (
          <CreditCardPaymentMethod {...paymentMethod} />
        ) : (
          <InvoicePaymentMethod {...paymentMethod} {...company} />
        )}
      </ModalShell>
    </>
  );
};
