export const GET_CAPACITY_OVERVIEW_DATA_REQUEST = 'GET_CAPACITY_OVERVIEW_DATA_REQUEST';
export const GET_CAPACITY_OVERVIEW_DATA_SUCCESS = 'GET_CAPACITY_OVERVIEW_DATA_SUCCESS';
export const GET_CAPACITY_OVERVIEW_DATA_FAILURE = 'GET_CAPACITY_OVERVIEW_DATA_FAILURE';

export const GET_CAPACITY_OVERVIEW_SUMMARY_DATA_REQUEST =
  'GET_CAPACITY_OVERVIEW_SUMMARY_DATA_REQUEST';
export const GET_CAPACITY_OVERVIEW_SUMMARY_DATA_SUCCESS =
  'GET_CAPACITY_OVERVIEW_SUMMARY_DATA_SUCCESS';
export const GET_CAPACITY_OVERVIEW_SUMMARY_DATA_FAILURE =
  'GET_CAPACITY_OVERVIEW_SUMMARY_DATA_FAILURE';

export const SET_DATE_PICKER_MONTH = 'SET_DATE_PICKER_MONTH';
export const SET_DATE_PICKER_YEAR = 'SET_DATE_PICKER_YEAR';
