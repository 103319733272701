import { forwardRef, HTMLAttributes } from 'react';
import { cx } from '~/common/utils';
import styles from './Badge.module.scss';

export type BadgeColor =
  | 'primary'
  | 'secondary'
  | 'other'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

type Props = {
  color?: BadgeColor;
  size?: 'small' | 'medium';
} & HTMLAttributes<HTMLDivElement>;

export const Badge = forwardRef<HTMLDivElement, Props>(
  ({ className, color = 'other', children, size = 'medium', ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cx(
        className,
        'flex items-center justify-center gap-[4px] font-brand-l3 rounded-2xl truncate',
        styles[color],
        styles[size],
      )}
    >
      {children}
    </div>
  ),
);
