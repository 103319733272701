import cx from 'classnames';
import { VideoPlayerModal, useModalContext } from '~/common/components';
import { useCurrentUser } from '~/root/Auth';
import styles from './PackagesExplainedModal.module.scss';

type PlayButtonVariant = 'solid' | 'duotone';

interface PlayButtonClassNames {
  bottomLayerClassName?: string;
  topLayerClassName?: string;
  className?: string;
}

type Props = {
  playCircle?: PlayButtonVariant;
  color?: 'blue' | 'grey';
  className?: string;
  iconClassNames?: PlayButtonClassNames;
};

export const PackagesExplainedModal = ({
  playCircle = 'duotone',
  color = 'blue',
  className,
  iconClassNames,
}: Props) => {
  const user = useCurrentUser();
  const { modalOpener } = useModalContext();

  if (user?.purchasedPackages) {
    return null;
  }

  return (
    <span
      className={cx(styles.button, styles[color], className)}
      onClick={modalOpener(VideoPlayerModal, {
        url: 'https://24slides.com/videos/our_credit_packages_explained_470488100.mp4',
      })}
    >
      <div className={cx(styles.playCircle, styles[color])}>
        <PlayButton variant={playCircle} {...iconClassNames} />
      </div>
      <span>Learn more about credit packs</span>
    </span>
  );
};

type PlayButtonProps = PlayButtonClassNames;

const PlayButton = ({
  variant,
  className,
  bottomLayerClassName,
  topLayerClassName,
}: { variant: PlayButtonVariant } & PlayButtonProps) => {
  return variant === 'solid' ? (
    <SolidPlayCircleIcon topLayerClassName={topLayerClassName} className={className} />
  ) : (
    <DuotonePlayCircleIcon
      className={className}
      topLayerClassName={topLayerClassName}
      bottomLayerClassName={bottomLayerClassName}
    />
  );
};

const DuotonePlayCircleIcon = ({
  topLayerClassName,
  bottomLayerClassName,
  className,
}: PlayButtonProps) => (
  <svg width="16" height="16" viewBox="0 0 512 512" className={className}>
    <path
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
      className={cx(styles.duotoneBottomLayer, bottomLayerClassName)}
      fill="currentColor"
    />
    <path
      d="M371.7 280l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
      className={topLayerClassName}
      fill="currentColor"
    />
  </svg>
);

const SolidPlayCircleIcon = ({
  topLayerClassName,
  className,
}: Omit<PlayButtonProps, 'bottomLayerClassName'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className={className}>
    <path
      d="M8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 3.71875 12.2812 0.25 8 0.25ZM11.6156 8.75L6.11562 11.9062C5.62187 12.1812 5 11.8281 5 11.25V4.75C5 4.175 5.61875 3.81875 6.11562 4.09375L11.6156 7.4375C12.1281 7.725 12.1281 8.46562 11.6156 8.75Z"
      className={topLayerClassName}
      fill="currentColor"
    />
  </svg>
);
