import { Link } from '~/common/components';
import { FrontendTableConfig } from '~/common/kits/table';
import { formatMoney } from '~/common/utils';
import { routes } from '~/constants';
import { UnpaidOrder } from '../../../../domain';

export const tableConfig: FrontendTableConfig<UnpaidOrder> = {
  columns: [
    {
      key: 'email',
      name: 'User email',
    },
    {
      key: 'role',
      name: 'Role',
    },
    {
      key: 'price',
      name: 'Total cost',
      cellRenderer: ({ cellData }) => formatMoney(cellData),
      getClassName: () => 'text-primary-300',
    },
    {
      key: 'id',
      name: '',
      cellRenderer: ({ cellData }) => (
        <Link to={`${routes.profile.orders}/${cellData}`}>See order</Link>
      ),
    },
  ],
  getCellClassName: () => 'cursor-default',
  sortable: ['email', 'price'],
  searchable: [],
};
