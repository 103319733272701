import axiosLib, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Toast, ToastType } from '~/common/components';
import { Any, record } from '~/common/utils';
import { routes } from '~/constants';
import { SERVER_URL, CLIENT_VERSION } from '~/env';
import { logoutRequest } from './Auth/utils';

/**
 * Create unique token per browser (TFL-222)
 */
export const getUuid = (): string => {
  let uuid = window.localStorage.getItem('uuid');

  if (uuid) {
    return uuid;
  }

  uuid = uuidv4();
  window.localStorage.setItem('uuid', uuid);
  return uuid;
};

// TODO: Feels like this one can should be removed but needs more investigation
// Currently I just left it as a legacy from second api dublicate as I'm not sure how much time it's to test wheather it's needed or no)
// If it's really useless shit than remove all usages)
export const redirectUserOnAxiosResponse = (redirectUrl: string) => {
  const redirectionExcludeMap = {
    '/order-now': ['/my-profile'],
    '/password/reset': ['/'],
  };

  record.keys(redirectionExcludeMap).forEach((url) => {
    const rg = new RegExp(url, 'i');
    const isCurrentUrlExcluded = rg.test(window.location.pathname);

    if (isCurrentUrlExcluded) {
      redirectionExcludeMap[url].forEach((excludeUrl) => {
        const excludeRg = new RegExp(excludeUrl, 'i');

        if (!excludeRg.test(redirectUrl)) {
          window.location.replace(redirectUrl);
        }
      });
    }

    return;
  });
};

export const axios = axiosLib.create({
  baseURL: SERVER_URL,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use(
  (config) => {
    // TODO we're still mutating everything except top level fields here :\
    const state: InternalAxiosRequestConfig<Any> & { isJSON?: boolean } = { ...config };

    state.headers.Device = getUuid();
    state.headers['X-Client-Version'] = CLIENT_VERSION;

    if (state.isJSON === false) {
      state.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    return state;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    // TODO axios removed this field in the new version, but we might depend on
    // it in some code
    (response as AxiosResponse<Any, Any> & { response: AxiosResponse<Any, Any>['data'] }).response =
      response.data;

    const { status, flashMessage, message } = response.data;
    const isFlashMessage = status === 'error' && !!message;

    if (flashMessage?.message || isFlashMessage) {
      const toastMessage = flashMessage?.message ?? message;
      const toastStatus = flashMessage?.type ?? status;

      Toast[toastStatus as ToastType]({ message: toastMessage });
    }

    return response;
  },
  (error) => {
    if (error.name === 'CanceledError') {
      return Promise.reject(error);
    }
    // TODO axios removed this field in the new version, but we might depend on
    // it in some code
    error.data = error.response;
    let errorMessage = 'Something went wrong';

    const data = error?.response?.data;
    const status = error.response?.status || data?.status_code;

    if (data) {
      errorMessage = data?.flashMessage?.message;
    }

    // This is for /join error handling
    if (status === 400 && error.response.data.status === 'error') {
      errorMessage = error.response.data.message;
    }

    if (status === 401) {
      logoutRequest();

      return Promise.reject(error);
    }

    if (status === 429) {
      errorMessage =
        data?.message ||
        'Server issues occurred. We have been informed about the problem, please try again later.';
    }

    // TODO it's sad, but I was too lazy to figure out how could I extend axios
    // config type so I could pass param like noToast in the request code
    // I'm catching 403 and returning default payload for confidential order case here
    // src/pages/My-profile/components/InnerOrderPage/hooks/useEvents.ts
    // src/pages/My-profile/components/InnerOrderPage/hooks/useOrder.tsx
    // Since I don't need a toast in that 403 case I'm going to use this the
    // most stupid workaround ever
    if (
      status === 403 &&
      window.location.pathname.startsWith(routes.profile.orders) &&
      !error.config.url.match(/\d+$/)
    ) {
      return Promise.reject(error);
    }

    if (status === 403) {
      errorMessage = 'Access denied. Make sure you have corresponding rights for this action.';
    }

    if (status === 404 || status >= 500) {
      errorMessage =
        status === 504
          ? 'Connection problems. Please make sure your internet connection is stable.'
          : 'Server issues occurred. We have been informed about the problem, please try again later.';
    }

    if (status !== 422 && status !== 412) {
      Toast.error({ message: errorMessage });
    }

    return Promise.reject(error);
  },
);
