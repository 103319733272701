import { z } from 'zod';

export const feedbackSchema = z.object({
  scores: z.object({
    design: z.number().nullable(),
    understandingOfRequirement: z.number().nullable(),
    adherenceToDeadline: z.number().nullable(),
    attentionToDetail: z.number().nullable(),
  }),
  feedbacks: z.object({
    good: z.string().nullable(),
    bad: z.string().nullable(),
    publishable: z.boolean(),
    savedHours: z.number().nullable(),
  }),
  timestamp: z.number(),
});

export const nullableFeedbackSchema = feedbackSchema.nullable();

export type FeedbackType = z.infer<typeof feedbackSchema>;
