import WindowCloseIcon from 'mdi-react/WindowCloseIcon';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '~/common/components';
import { topbarDataSelector } from '~/redux/modules/order-now/data/data.selectors';
import { axios, redirectUserOnAxiosResponse } from '~/root';

import backgroundImage from './bg.png';

interface UpsellCreditPackagesProps {
  id: number;
  credits: number;
  price: number;
  save: number;
  onClose: () => void;
}

export const UpsellCreditPackages = ({
  id,
  credits,
  price,
  save,
  onClose,
}: UpsellCreditPackagesProps) => {
  const [viewType, setViewType] = useState('initial');
  const [submitting, setSubmitting] = useState(false);
  const { price: orderPrice } = useSelector(topbarDataSelector);

  const handleClose = () => {
    if (!submitting) {
      onClose();
    }
  };

  const handlePackCharging = () => {
    setSubmitting(true);
    axios
      .post(`/v1/customers/charge/package/${id}`)
      .then((response) => {
        if (response?.data.redirectUrl) {
          redirectUserOnAxiosResponse(response.data.redirectUrl);
        }

        setViewType('thank');

        return response;
      })
      .catch(() => {
        onClose();
      });
  };

  const initialMarkup = (
    <>
      <h2 className="upsell__title upsell-credit__title">
        You can <span className="bold">{`save ${save}% `}</span>on this order by purchasing a credit
        pack
      </h2>
      <p className="upsell__regular upsell-credit__regular">
        Your order value is high enough that buying a credit pack would actually{' '}
        <span className="bold">save you money</span> on the cost per slide.
      </p>
      <p className="upsell__regular upsell-credit__regular">
        Your order has already been sent to our team, but if you’d like to save on the price of each
        slide, click below.
      </p>

      <div>
        <Button
          className="upsell__button bold"
          size="m"
          color="secondary"
          onClick={() => setViewType('details')}
        >
          View details
        </Button>

        <button
          className="upsell-credit__cancel upsell__regular"
          type="button"
          onClick={handleClose}
        >
          Skip
        </button>
      </div>
    </>
  );

  const detailsMarkup = (
    <>
      <h2 className="upsell__title upsell-credit__title">Here’s how it works</h2>
      <p className="upsell__regular upsell-credit__regular">
        {`When you purchase a credit pack, you will get a ${save}% discount. Meaning you will pay`}
        <span className="bold">{` $${price} `}</span>
        {`but receive $${credits} of credit.`}
      </p>
      <p className="upsell__regular upsell-credit__regular">
        Once you approve the final design, we’ll deduct the cost of the order from your credits.
        Your remaining credits will be available for future orders, and they will never expire.
        These credits can also be shared by any users you invite to your team.
      </p>

      <div>
        <Button
          className="upsell__button bold"
          size="m"
          color="secondary"
          loading={submitting}
          onClick={handlePackCharging}
        >
          Confirm purchase
        </Button>

        <button
          className="upsell-credit__cancel upsell__regular"
          type="button"
          onClick={handleClose}
        >
          I don’t want a credit pack
        </button>
      </div>
    </>
  );

  const thankMarkup = (
    <>
      <h2 className="upsell__title upsell-credit__title">Thank you</h2>
      <p className="upsell__regular upsell-credit__regular">
        {`Your account has been credited with $${credits}. Once you have approved the final designs for your order, we will deduct $${Math.floor(
          orderPrice,
        )} from your credits.`}
      </p>
      <p className="upsell__regular upsell-credit__regular">
        You will receive an email with your receipt shortly.
      </p>

      <div>
        <Button className="upsell__button bold" size="m" color="secondary" onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  );

  const getContent = () => {
    switch (viewType) {
      case 'thank':
        return thankMarkup;
      case 'details':
        return detailsMarkup;
      default:
        return initialMarkup;
    }
  };

  return (
    <div className="upsell__container upsell-credit__container">
      <img
        src={backgroundImage}
        alt=""
        className="upsell__background-image upsell-credit__background-image"
      />

      {viewType !== 'thank' && (
        <WindowCloseIcon onClick={handleClose} color="#fff" size={22} className="upsell__close" />
      )}

      <div className="upsell__content">{getContent()}</div>
    </div>
  );
};
