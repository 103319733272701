import { FieldProps } from 'formoid';
import { NonEmptyArray } from 'formoid/lib/Array';
import { ComponentProps, forwardRef } from 'react';
import { Overwrite, cx } from '~/common/utils';
import { Errors } from '../Input';
import { Button } from '../Interactives';
import css from './Switcher.module.scss';

// TODO we need to add tooltip icons here

type Props = Overwrite<
  ComponentProps<'button'>,
  {
    enabled: boolean;
  }
>;

export const SwitcherButton = forwardRef<HTMLButtonElement, Props>(
  ({ enabled, className, ...props }, ref) => (
    <Button.Base
      {...props}
      ref={ref}
      className={cx(css.switcher, enabled && css.on, props.disabled && css.disabled, className)}
    >
      <span className={enabled ? css.on : css.off} />
    </Button.Base>
  ),
);

type SwitcherProps = Overwrite<
  ComponentProps<'input'>,
  {
    title: string;
    value: boolean;
    onChange: (value: boolean) => void;
    error?: boolean;
    errors?: NonEmptyArray<string> | null;
  }
>;

export const Switcher = forwardRef<HTMLLabelElement, SwitcherProps>(
  ({ className, title, value: checked, onChange, error, errors, ...props }, ref) => (
    <label
      ref={ref}
      className={cx(css.wrapper, className, checked && css.checked, props.disabled && css.disabled)}
    >
      <input
        {...props}
        className="absolute -z-1 opacity-0 cursor-pointer"
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      <SwitcherButton
        enabled={checked}
        disabled={props.disabled}
        onClick={() => onChange(!checked)}
      />
      <span className={css.text}>{title}</span>
      {errors && <Errors errors={errors} />}
    </label>
  ),
);

export const SwitcherField = forwardRef<HTMLLabelElement, SwitcherProps & FieldProps<boolean>>(
  ({ touched, ...props }, ref) => <Switcher {...props} ref={ref} />,
);
