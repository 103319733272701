import { ComponentClass, FunctionComponent, LazyExoticComponent, ReactNode } from 'react';
import { LoadableComponent } from 'react-loadable';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Any } from '~/common/utils';

export interface LayoutProps {
  children: ReactNode;
  isHeaderShown?: boolean;
}

interface AppRouteProps {
  component:
    | LazyExoticComponent<(props: RouteComponentProps) => JSX.Element>
    | (() => JSX.Element)
    | (ComponentClass<Any, Any> & LoadableComponent)
    | (FunctionComponent<Any> & LoadableComponent);
  layout: (props: LayoutProps) => JSX.Element;
  layoutProps: {
    isHeaderShown?: boolean;
  };
  exact: boolean;
  path: string | string[];
}

export const AppRoute = ({
  component: Component,
  layout: Layout,
  layoutProps,
  exact,
  path,
}: AppRouteProps) => (
  <Route
    exact={exact}
    path={path}
    render={(props) => (
      <Layout {...layoutProps}>
        <Component {...props} />
      </Layout>
    )}
  />
);
