import { routes } from '~/constants';
import { NavigationConfig } from '../types';

export const config = {
  links: {
    examples: { title: 'Our work', href: routes.public.examples, newTab: true },
    pricing: {
      title: 'Pricing',
      href: routes.public.pricing.slides,
      newTab: true,
    },
    about: {
      title: 'About us',
      href: routes.public.about.index,
      newTab: true,
    },
  },
  subMenus: {
    services: {
      title: 'Services',
    },
    resources: { title: 'Resources' },
  },
} satisfies NavigationConfig;
