import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { Button, Collapse, IconBox } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { cx } from '~/common/utils';
import { useNavigation } from './context';
import { menuItemClassnames } from './NavigationLink';
import { CommonProps } from './types';

interface SubNavigationProps extends CommonProps {
  children: ReactNode;
}

export const SubNavigation = ({ children, title, icon }: SubNavigationProps) => {
  const { isMenuCollapsed } = useNavigation();
  const [isCollapsed, toggleCollapsed] = useToggle(true);

  return (
    <>
      {!isMenuCollapsed && (
        <Button.Base
          key={title}
          className={cx(menuItemClassnames, 'justify-between w-full')}
          onClick={toggleCollapsed}
        >
          <div className="flex items-center gap-1">
            {icon && <IconBox size="s" icon={icon} />}
            <span className="font-brand-b3">{title}</span>
          </div>

          <IconBox icon={faChevronUp} className={cx(isCollapsed && 'rotate-180')} />
        </Button.Base>
      )}
      {isMenuCollapsed ? (
        children
      ) : (
        <Collapse in={!isCollapsed} className="w-full pl-3">
          {children}
        </Collapse>
      )}
    </>
  );
};
