import {
  SET_DATE_PICKER_MONTH,
  SET_DATE_PICKER_YEAR,
  GET_CAPACITY_OVERVIEW_DATA_REQUEST,
  GET_CAPACITY_OVERVIEW_DATA_SUCCESS,
  GET_CAPACITY_OVERVIEW_DATA_FAILURE,
  GET_CAPACITY_OVERVIEW_SUMMARY_DATA_REQUEST,
  GET_CAPACITY_OVERVIEW_SUMMARY_DATA_SUCCESS,
  GET_CAPACITY_OVERVIEW_SUMMARY_DATA_FAILURE,
} from './capacity-overview.types';

const date = new Date();
const year = date.getFullYear();
const month = date.getMonth() >= 9 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`;

const initialState = {
  dailyReportsData: {},
  orderBreakDownData: [],
  monthlyCapacityOverView: {},
  hasError: false,
  datePickerYear: year,
  datePickerMonth: month,
  errorMessage: '',
  capacityOverviewIsDataLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CAPACITY_OVERVIEW_DATA_REQUEST:
      return {
        ...state,
        hasError: false,
      };
    case GET_CAPACITY_OVERVIEW_DATA_SUCCESS:
      return {
        ...state,
        dailyReportsData: action.payload.dailyReportsData,
        orderBreakDownData: action.payload.orderBreakDownData,
        hasError: false,
        errorMessage: '',
      };
    case GET_CAPACITY_OVERVIEW_DATA_FAILURE:
      return {
        ...state,
        dailyReportsData: {},
        orderBreakDownData: [],
        hasError: true,
        errorMessage: action.error,
      };
    case GET_CAPACITY_OVERVIEW_SUMMARY_DATA_REQUEST:
      return {
        ...state,
        capacityOverviewIsDataLoaded: false,
        hasError: false,
      };
    case GET_CAPACITY_OVERVIEW_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        capacityOverviewIsDataLoaded: true,
        hasError: false,
        monthlyCapacityOverView: action.payload,
      };
    case GET_CAPACITY_OVERVIEW_SUMMARY_DATA_FAILURE:
      return {
        ...state,
        capacityOverviewIsDataLoaded: true,
        hasError: true,
        errorMessage: action.error,
      };
    case SET_DATE_PICKER_MONTH:
      return {
        ...state,
        datePickerMonth: action.payload,
      };
    case SET_DATE_PICKER_YEAR:
      return {
        ...state,
        datePickerYear: action.payload,
      };
    default:
      return state;
  }
};
