import { z } from 'zod';

export const OauthData = z.object({
  redirectUrl: z.string().url(),
  channel: z.string(),
});

export type OauthData = z.infer<typeof OauthData>;

export const LoginResponse = z.object({
  token: z.string(),
  redirectUrl: z.string().url(),
});

export type LoginResponse = z.infer<typeof LoginResponse>;
