import {
  FloatingTree,
  MiddlewareData,
  Placement,
  useFloatingParentNodeId,
} from '@floating-ui/react';
import { ComponentPropsWithoutRef, forwardRef, ReactElement, ReactNode, RefObject } from 'react';
import { useTabIndex } from '~/common/hooks';
import { Any } from '~/common/utils';

const FloatingTreeHoc = ({ children }: { children: ReactNode }) => {
  const parentId = useFloatingParentNodeId();

  // This is a root, so we wrap it with the tree
  if (parentId === null) {
    return <FloatingTree>{children}</FloatingTree>;
  }

  return <>{children}</>;
};

export function withFloatingTree<P>(
  Component: (props: P & JSX.IntrinsicAttributes) => ReactElement<Any, Any> | null,
) {
  return (props: P & JSX.IntrinsicAttributes) => {
    return <FloatingTreeHoc>{Component && <Component {...props} />}</FloatingTreeHoc>;
  };
}

export const FloatingDiv = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  (props, ref) => {
    const tabIndex = useTabIndex();
    return <div {...props} ref={ref} tabIndex={tabIndex} />;
  },
);

export const useArrow = <T extends HTMLElement>(arrowRef: RefObject<T>, placement: Placement) => {
  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]] as 'top' | 'right' | 'bottom' | 'left';

  const arrowProps = (middlewareData: MiddlewareData) => ({
    ref: arrowRef,
    style: {
      left: middlewareData.arrow?.x != null ? `${middlewareData.arrow?.x}px` : '',
      top: middlewareData.arrow?.y != null ? `${middlewareData.arrow?.y}px` : '',
      [staticSide]: '-4px',
    },
  });

  return { arrowProps, staticSide };
};

// TODO extend this once we know what other extensions our users want to use
export const outsidePress = (event: MouseEvent) => {
  const target = event.target as Element;
  return !target.shadowRoot && !target.closest('.Toastify');
};
