import { ENV } from '~/env';

export const linkedin = {
  ready: ENV.PRODUCTION && window.lintrk,

  track(conversionId: string) {
    if (this.ready) {
      window.lintrk?.('track', {
        conversion_id: conversionId,
      });
    }
  },
};
