import * as types from './unsubscribe.types';

const initialState = {
  buttonAction: 'subscribe',
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
        },
      };

    case types.UNSUBSCRIBE_CHANGE_BUTTON_ACTION:
      return {
        ...state,
        buttonAction: action.payload,
      };

    default:
      return state;
  }
};
